angular.module('hrmnext.recruitment.profile')
	.controller('AddressCtrl', ['$scope', 'Restangular', '$uibModalInstance', 'formValidationService', 'addresses', 'countries', 'addressTypes', 'address', 'user_id',
		function ($scope, Restangular, $uibModalInstance, formValidationService, addresses, countries, addressTypes, address, user_id) {

			$scope.address = JSON.parse(JSON.stringify(address));
			$scope.addressTypes = addressTypes;
			$scope.countries = countries;

			if (typeof address.country_id == 'undefined') {
				$scope.address.country_id = countries[0].id;
			}
			if (typeof address.address_type_id == 'undefined') {
				$scope.address.address_type_id = addressTypes[0].id;
			}


			$scope.ok = function () {
				$scope.form.$setSubmitted();

				if ($scope.form.$valid) {
					// Determine if the record is a new record or an edited record
					if (!angular.isDefined($scope.address.id)) {
						Restangular.one('users', user_id).post('addresses', $scope.address).then(function (address) {
							addresses.push(address);
							$uibModalInstance.close(address);
						}, function (response) {
							$scope.displayFormError = true;
							$scope.response = response.data;
							if (response.data.status_code != 422) {
								Notification.error('Failed to update phone number');
							}
						});
					}
					else {
						Restangular.one('users', user_id).one('addresses', $scope.address.id).customPUT($scope.address).then(function (response) {
							address = response;
							$uibModalInstance.close(response);
						}, function (response) {
							$scope.displayFormError = true;
							$scope.response = response.data;
							if (response.data.status_code != 422) {
								Notification.error('Failed to update phone number');
							}
						});
					}
				}
			}

			$scope.cancel = function () {
				$uibModalInstance.dismiss();
			}
		}]);