angular.module('hrmnext.recruitment.email-validator', [])
.directive('emailValidator', [ '$q','Restangular', function($q, Restangular){
	// Runs during compile
	return {
		// name: '',
		// priority: 1,
		// terminal: true,
		// scope: {}, // {} = isolate, true = child, false/undefined = no change
		// controller: function($scope, $element, $attrs, $transclude) {},
		require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
		// template: '',
		// templateUrl: '',
		// replace: true,
		// transclude: true,
		// compile: function(tElement, tAttrs, function transclude(function(scope, cloneLinkingFn){ return function linking(scope, elm, attrs){}})),
		link: function($scope, iElm, iAttrs, ctrl) {
			ctrl.$asyncValidators.emailTaken = function(modelValue, viewValue){
				if(ctrl.$isEmpty(modelValue)){
					return $q.when();
				}
				var deferred = $q.defer();
				Restangular.all('auth/email-validation').post({'email': viewValue}).then(function(response){
					if(response.email_taken){
						deferred.reject();
					} else{
						deferred.resolve();
					}
				},function(response){
					deferred.reject();
				});

				return deferred.promise;
			}
		}
	};
}]);