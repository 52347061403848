angular.module('hrmnext.recruitment', [
	'hrmnext.recruitment.controllers',
	'hrmnext.recruitment.services',
	'hrmnext.recruitment.app.services',
	'hrmnext.recruitment.directives',
	'hrmnext.recruitment.home',
	'hrmnext.recruitment.login',
	'hrmnext.recruitment.forgotPassword',
	'hrmnext.recruitment.resetPassword',
	'hrmnext.recruitment.signup',
	'hrmnext.recruitment.activate',
	'hrmnext.recruitment.profile',
	'hrmnext.recruitment.landing',
	'hrmnext.recruitment.positions',
	'hrmnext.recruitment.manage',
	'hrmnext.recruitment.invitation',
	'hrmnext.recruitment.errors',
	'hrmnext.recruitment.component.ngDotDotDot',
	'hrmnext.recruitment.ui-select.required',
	'hrmnext.recruitment.email-validator',
	'hrmnext.recruitment.directives.birthday-validator',
	'hrmnext.recruitment.services.authorization',
	'hrmnext.recruitment.constants',
	'hrmenxt.recruitment.directives.access',
	'hrmnext.recruitment.services.helpers',
	'hrmnext.recruitment.serverError',
	'hrmnext.recruitment.formValidationService',
	'hrmnext.recruitment.feedback',
	'hrmnext.recruitment.directives.serverError',
	'hrmnext.recruitment.components.slimscroll',
	'hrmnext.recruitment.bytes.filter',
	'angular-jwt',
	'angular-storage',
	'ui.bootstrap',
	// 'ngResource',
	'ngAnimate',
	'restangular',
	'angular-loading-bar',
	'ui.mask',
	'ui-notification',
	'ui.select',
	'ngSanitize',
	'angularMoment',
	'hrmnext.ui.slider',
	'toaster',
	'ui.calendar',
	'ngMessages',
	'ui.tinymce',
	'ngImgCrop',
	'amChartsDirective',
	'ngCookies',
	'pusher-angular',
	'file-model',
])
	.config(['$urlRouterProvider', 'jwtInterceptorProvider', '$httpProvider', 'RestangularProvider', '$provide', '$locationProvider', 'NotificationProvider',
		function myAppConfig($urlRouterProvider, jwtInterceptorProvider, $httpProvider, RestangularProvider, $provide, $locationProvider, NotificationProvider) {
			// enabling html 5 type url
			$locationProvider.html5Mode(true);

			/**
			 * Notification provider settings
			 */
			NotificationProvider.setOptions({
				delay: 10000,
				startTop: 20,
				startRight: 10,
				verticalSpacing: 20,
				horizontalSpacing: 20,
				positionX: 'right',
				positionY: 'bottom'
			});
			/**
			* Http Interceptor to handle request errors
			**/
			$provide.factory('HttpInterceptor', ['$q', '$location', 'flash', 'store', 'jwtHelper', '$injector', '$timeout', '$location', '$rootScope',
				function ($q, $location, flash, store, jwtHelper, $injector, $timeout, $location, $rootScope) {
					return {
						response: function (response) {
							return response;
						},
						responseError: function (rejection) {
							return $q.reject(rejection);
						},
						request: function (request) {
							return request;
						}
					}
				}]);
			jwtInterceptorProvider.tokenGetter = function (jwtHelper, $http, store, $timeout, config) {
				var jwt = store.get('jwt');
				// Skip authentication for any requests ending in .html
				if (config.url.substr(config.url.length - 5) == '.html') {
					return null;
				}
				if (jwt) {
					if (jwtHelper.isTokenExpired(jwt)) {
						return $http({
							url: 'api/auth/token',
							skipAuthorization: true,
							method: 'GET',
							headers: { Authorization: 'Bearer ' + jwt },
						}).then(function (response) {
							store.set('jwt', response.data.token);
							return store.get('jwt');
						}, function (response) {
							return store.get('jwt');
						});
					} else {
						return jwt;
					}
				}
			}

			// Configure Restangular
			RestangularProvider.setBaseUrl('api/');
			RestangularProvider.setResponseExtractor(function (response, operation) {
				var newResponse = response;

				if (angular.isDefined(response.data)) {
					newResponse = response.data;
				}
				if (angular.isDefined(response.meta)) {
					newResponse.meta = response.meta
				}
				return newResponse;
			});

			$httpProvider.interceptors.push('jwtInterceptor');
			$httpProvider.interceptors.push('HttpInterceptor');

			//initialize third party javascript applications
			new WOW().init();

			$urlRouterProvider.otherwise('/404');
		}])
	.run(['$rootScope', '$state', 'store', 'jwtHelper', 'flash', 'constants', 'page', 'auth', '$uibModalStack', 'authorization', 'Restangular', '$transitions', '$trace', '$q',
		function ($rootScope, $state, store, jwtHelper, flash, constants, page, auth, $uibModalStack, authorization, Restangular, $transitions, $trace, $q) {
			$trace.enable(1);
			/**
			 * Service injected in  all the applications
			 */
			/** Injecting contants in root scope */
			$rootScope.constants = constants;
			/** injecting auth service in root scope */
			$rootScope.auth = auth;
			/** injecting page service in all pages*/
			$rootScope.page = page;
			$rootScope.state = $state;

			$transitions.onError({}, function (trans) {
				var $state = trans.injector().get('$state');
				var store = trans.injector().get('store');
				var flash = trans.injector().get('flash');

				var error = trans.error();

				if (error.detail) {
					switch (error.detail.status) {
						case 404:
							$state.go('404', {}, { location: false });
							break;
						case 403:
							$state.go('403', {}, { location: false });
							break;
						case 401:
							store.remove('jwt');
							flash.addMessage('Please login to continue.', 'danger');
							$state.go('login');
							break;
					}
				}

			});

			$transitions.onEnter({}, function ($q, $rootScope) {
				return true;
			});

			//for all state
			$transitions.onBefore({}, function (trans) {
				var $rootScope = trans.injector().get('$rootScope');
				var Restangular = trans.injector().get('Restangular');
				var $uibModalStack = trans.injector().get('$uibModalStack');

				$uibModalStack.dismissAll();
				$rootScope.authUser = auth.getUser();
				$rootScope.userRoles = [];

				if ($rootScope.authUser != undefined) {
					return Restangular.one('users', $rootScope.authUser.id).all('roles').getList().then(function (roles) {
						$rootScope.userRoles = Restangular.stripRestangular(roles);
					});
				}
				return true;
			});
			//for states that requires a completed user profile
			$transitions.onBefore({
				to: function (state) {
					return state.access != undefined
						&& state.access.completedProfile != undefined
						&& state.access.completedProfile == true;
				}
			}, function (trans) {
				var $rootScope = trans.injector().get('$rootScope');
				var Restangular = trans.injector().get('Restangular');

				if ($rootScope.authUser) {
					return Restangular.one('profile_status', $rootScope.authUser.id).get().then(function (status) {
						$rootScope.userProfileStatus = status;
					});
				}
				return true;
			});

			//for any state that requires access
			$transitions.onStart({
				to: function (state) {
					return state.access != undefined;
				}
			}, function (trans) {
				var $rootScope = trans.injector().get('$rootScope');
				var auth = trans.injector().get('auth');
				var flash = trans.injector().get('flash');
				var authorization = trans.injector().get('authorization');
				var $transition$ = trans.injector().get('$transition$');
				var Notification = trans.injector().get('Notification');

				var authorised = authorization.authorize($transition$.to().access);

				if (authorised === constants.LOGIN_REQUIRED) {
					flash.addMessage('Please login to continue.', 'danger');
					$rootScope.targetState = $transition$._targetState;
					return $state.target('login');

				}
				else if (authorised === constants.LOGOUT_REQUIRED) {
					Notification.error('Log out to perform that action')
					return $state.target('home');
				}
				else if (authorised === constants.NOT_AUTHORIZED) {
					console.log('reach');
					return $state.target('403', {}, { location: false });
				}
				return true;
			});

			//redirect the user to profile page if the profile is not completed
			$transitions.onStart({
				to: function (state) {
					return state.access != undefined
						&& state.access.completedProfile != undefined
						&& state.access.completedProfile == true;
				}
			}, function (trans) {

				var $rootScope = trans.injector().get('$rootScope');
				var $state = trans.injector().get('$state');

				if (($rootScope.userProfileStatus != undefined && $rootScope.userProfileStatus.completed == false && $rootScope.authUser && $rootScope.userRoles.length == 0)) {
					return $state.go('profile.details');
				}
				return true;
			});
		}])

/**
 * Change all date conversion to moment date conversion
 * @param  {[type]} ){ console.log('new converter',this,moment(this).format() );return moment(this).format( [description]
 * @return {[type]}     [description]
 */
Date.prototype.toJSON = function () { return moment(this).format(); };
