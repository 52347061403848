angular.module('hrmnext.recruitment.profile')
	.config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('profile.application', {
			url: '/applications',
			controller: 'ProfileApplicationCtrl',
			templateUrl: 'app/profile/application/application.html',
			access: {
				requiresLogin: true,
			},
			resolve: {
				applications: ['Restangular', '$rootScope', function (Restangular, $rootScope) {
					return Restangular.one('users', $rootScope.authUser.id).all('applications').getList({
						include: 'position.company'
					});
				}],
			}
		});
	}])
	.controller('ProfileApplicationCtrl', [
		'$scope',
		'Restangular',
		'DialogService',
		'$uibModal',
		'Notification',
		'applications',
		function ($scope, Restangular, DialogService, $uibModal, Notification, applications) {
			$scope.applications = applications;
			$scope.pageChanged = function () {
				Restangular.one('users', $scope.user.id).all('applications').getList({
						page: $scope.applications.meta.pagination.current_page
					})
					.then(function (response) {
						$scope.applications = response;
					}, function (response) {
						Notification.error('Failed to update job listings');
					});
			}
			$scope.removeApplication = function (application) {
				var modalInstance = DialogService.confirm("Are you sure you want to remove this application?", "Remove Job Application");

				modalInstance.then(function () {
					// if the user clicks yes then remove the application
					Restangular.one('users', $scope.user.id).one('applications', application.id).remove()
						.then(function () {
							// Get updated user phone number list
							Restangular.one('users', $scope.user.id).all('applications').getList({
									include: 'position.company'
								})
								.then(function (applications) {
									Notification.success('Your application was removed successfully');
									$scope.applications = applications;
								});

							//if an error occured while trying to remove a number then flash message
						}, function (response) {
							Notification.error('Failed to update your application');
						});
				});
			}
			$scope.applicationDetails = function (application) {
				$uibModal.open({
					templateUrl: 'app/profile/application/details/details.html',
					controller: 'ProfileApplicationDetailCtrl',
					size: 'lg',
					resolve: {
						application: function () {
							return application
						}
					}
				});
			}

		}
	]);