angular.module('hrmnext.recruitment.manage.favorite-users.addToCompany', [])
	.controller('AddFavoriteUserToCompanyCtrl', function ($scope, Notification, Restangular, $uibModalInstance, companies, user) {
		$scope.companies = companies;
		$scope.favoriteUser = {
			user_id: user.id,
			company_id: companies[0].id
		};

		$scope.ok = function () {
			Restangular.all('favorite_users').customPOST($scope.favoriteUser).then(function (favoriteUser) {
				Notification.success('Successfully added user to company favourites');
				$uibModalInstance.close(favoriteUser);
			}, function (response) {
				Notification.error('An error has occurred while trying to add user to company favourites');
			});
		}

		$scope.cancel = function () {
			$uibModalInstance.dismiss();
		}
	})