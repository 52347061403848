angular.module('hrmnext.recruitment.manage.hrmnext.create', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.hrmnext.create', {
			url: '/create',
			templateUrl: 'app/manage/hrmnext/create/create.html',
			controller: 'CreateHRMNextCtrl',
			access: {
				requiresLogin: true,
				requirePermissions: ['import_from_hrmnext', 'export_to_hrmnext'],
			},
			resolve: {

			}
		})
	}])

	.controller('CreateHRMNextCtrl', ['$scope', 'Restangular', 'Notification', function ($scope, Restangular, Notification) {

	}])