/**
* hrmnext.recruitment.component.ngDotDotDot Module
*
* Description
*/
angular.module('hrmnext.recruitment.component.ngDotDotDot', [])
.directive('ngDotDotDot', ['$compile','$timeout', function($compile,$timeout){
	// Runs during compile
	return {
		// name: '',
		// priority: 1,
		// terminal: true,
		// scope: {
		// 	ngDotDotDot : '='
		// }, // {} = isolate, true = child, false/undefined = no change
		// controller: function($scope, $element, $attrs, $transclude) {},
		// require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
		// template: '',
		// templateUrl: '',
		// replace: true,
		// transclude: true,
		// compile: function(tElement, tAttrs, function transclude(function(scope, cloneLinkingFn){ return function linking(scope, elm, attrs){}})),
		link: function($scope, iElm, iAttrs, controller) {
			$compile(iElm.contents())($scope);
			var  option = $scope.$eval(iAttrs.ngDotDotDot);
			$timeout(function(){
				$(iElm).dotdotdot(option);
			});
		}
	};
}]);