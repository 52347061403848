/**
* hrmnext.directives.clearServerError Module
*
* Description
*/
angular.module('hrmnext.recruitment.directives.serverError', [])
.directive('serverError', [function(){
	// Runs during compile
	return {
		// name: '',
		// priority: 1,
		// terminal: true, 
		scope: {
			serverError: '=',
			ngModel: '='
		}, // {} = isolate, true = child, false/undefined = no change
		// controller: function($scope, $element, $attrs, $transclude) {},
		require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
		// template: '',
		// templateUrl: '',
		replace: false,
		// transclude: true,
		// compile: function(tElement, tAttrs, function transclude(function(scope, cloneLinkingFn){ return function linking(scope, elm, attrs){}})),
		link: function($scope, iElm, attr, controller) {
			$scope.$watch('ngModel', function(){
				$scope.serverError = null;
				// attr.$attr.clearServerError = null; 
			},true);
		}
	};
}]);