angular.module('hrmnext.recruitment').filter('timestampToISO', function() {
    return function(input) {
    	try{
    		input = new Date(input).toISOString();
    	}catch(e){
    		return "";
    	}
        
        return input;
    };
});