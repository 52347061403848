angular.module('hrmnext.recruitment.constants', [])
	.constant('constants', {
		DATE_FORMAT: 'MMMM d, yyyy',
		SHORT_DATE_FORMAT: "dd/MM/yyyy",
		UNAUTHORIZED: 401,
		FORBIDDEN: 403,
		AUTHORIZED: 'authorized',
		LOGIN_REQUIRED: 'loginRequired',
		LOGOUT_REQUIRED: 'logoutRequired',
		NOT_AUTHORIZED: 'notAuthorized',
		AT_LEAST_ONE: 'atLeastOne',
		COMBINATION_REQUIRED: 'combinationRequired',
	});