angular.module('hrmnext.recruitment.manage.overview', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.overview',{
		url : '',
		templateUrl: 'app/manage/overview/overview.html',
		controller: 'ManageOverviewCtrl',
		access : {
			requiresLogin: true,
			requirePermissions : ['view_dashboard'],
			permissionType : 'atLeastOne'
		},
	});
}])
.controller('ManageOverviewCtrl', ['$scope', function($scope){
	
}]);