angular.module('hrmnext.recruitment.controllers')
.controller('FlashCtrl', ['$scope', 'FlashService','$timeout', function($scope,FlashService,$timeout){
	$scope.displayFlash = false;

	$scope.$on('flash', function(){
		$timeout(function(){
			$scope.displayFlash = false;
			$scope.flash = {};
		},10000)

		$scope.displayFlash = true;
		$scope.flash = FlashService.getMessage();
		// console.log($scope.flash);
		// document.body.scrollTop = document.documentElement.scrollTop = 0;
	});

}]);