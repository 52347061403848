angular.module('hrmnext.recruitment.services')


.factory('companyResource', ['$resource', function($resource){
	var baseUrl =  'api/companies/';


	return $resource(baseUrl+ ':id', { country_id:'@id' },{
		defaultTerms : {
			method : 'GET',
			url : baseUrl+'default/terms/:type',
			responseType: 'json',
			isArray:true,
		},
		defaultCountries : {
			method : 'GET',
			url : baseUrl+'default/countries',
			responseType: 'json',
			isArray:true,
		}
	});
}]);