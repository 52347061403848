angular.module('hrmnext.recruitment.manage.invitations',[
    'hrmnext.recruitment.manage.invitations.index.controller',
    'hrmnext.recruitment.manage.invitations.userInvitation.service'
])
.config(function($stateProvider){
    $stateProvider.state('manage.invitations',{
        abstract :true,
		url : '/invitations',
		template : '<ui-view></ui-view>',
    });
})