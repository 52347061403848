angular.module('hrmnext.recruitment.profile')
.controller('HobbyCtrl', ['$scope','Restangular','$uibModalInstance','hobby','hobbies','user_id','Notification', function($scope,Restangular,$uibModalInstance,hobby,hobbies,user_id,Notification){
	$scope.hobby = Object.assign({}, hobby);
	
	$scope.search = function(resourceName, searchQuery){
		return Restangular.all(resourceName).getList({'name':searchQuery})
		.then(function(response){
			return response.map(function(response){
				return response;
			});
		});
	}
	
	$scope.ok = function(){
		var promise;
		if(angular.isUndefined(hobby.id)){
			promise = Restangular
				.one('users',user_id)
				.post('hobbies',$scope.hobby)
				.then(function(response){
					hobbies.push(response);
					Notification.success('Your hobby was successfully created.');
				});
		}else{
			promise = Restangular
				.one('users',user_id)
				.one('hobbies',$scope.hobby.id)
				.put($scope.hobby)
				.then(function(response){
					index = hobbies.findIndex(function(hob){
						return hob.id == $scope.hobby.id;
					});

					hobbies[index] = response;
					Notification.success('Your hobby was successfully updated.');
				});
		}	
		promise.then(function(response){
			$uibModalInstance.close(response);
		},function(response){
			$scope.response = response.data;
		});
	}
	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}
}]);