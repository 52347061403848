angular.module('hrmnext.recruitment.manage.position.applications.status', [])
.controller('ApplicationStatusCtrl',function($scope, Restangular,application,$uibModalInstance,status,Notification){
    $scope.application = {};
    angular.copy(application, $scope.application)
    $scope.application.status = status;


    $scope.cancel = function(){
        $uibModalInstance.dismiss();
    }
    //TODO: Update the route to update the application status
    $scope.ok = function(){
        Restangular.one('applications',$scope.application.id).customPUT($scope.application,'',{include: "user.addresses,user.phoneNumbers,user.applications.position" }).then(function(application){
            $scope.application = application;
            $uibModalInstance.close(application);
            Notification.success('The application status was successfully updated.');  
        },function(response){
            Notification.error('Failed to update application status');
        });    
    }
});