angular.module('hrmnext.recruitment.manage.hrmnext', [
	'hrmnext.recruitment.manage.hrmnext.index',
	'hrmnext.recruitment.manage.hrmnext.create',
	'hrmnext.recruitment.manage.hrmnext.show',
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.hrmnext', {
			abstract: true,
			url: '/hrmnext',
			template: '<ui-view/>',
			access: {
				requiresLogin: true,
				requirePermissions: ['import_from_hrmnext'],
				permissionType: 'atLeastOne'
			},
		});
	}])