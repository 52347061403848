angular.module('hrmnext.recruitment.manage.favorite-users',[
	'hrmnext.recruitment.manage.favorite-users.index',
	'hrmnext.recruitment.manage.favorite-users.details'
])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.favorite_users',{
		abstract: true,
		url : '/favorite_users',
		template: '<ui-view></ui-view>',
	})
}])