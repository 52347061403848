angular.module('hrmnext.recruitment.home.interview', [
])
.controller('InterviewPreviewCtrl', ['$scope','$uibModalInstance', 'interview','Notification', function($scope,$uibModalInstance,interview,Notification){
	$scope.interview = interview;
	$scope.resendInvitation = function(interview){
		interview.all('resendInvitation').customPOST({}).then(function(response){
			Notification.success('Successfully resent invitation');
		},function(response){
			Notification.error('Failed when trying to resend invitation');	
		});
	}
	$scope.close = function(){
		$uibModalInstance.dismiss();
	}
}])