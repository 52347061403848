angular.module('hrmnext.recruitment.services.authorization', [
	'hrmnext.recruitment.services',
	'hrmnext.recruitment.constants'
])
	.service('authorization', ['auth', 'constants', 'Restangular', '$rootScope', function (auth, constants, Restangular, $rootScope) {
		this.authorize = function (access) {
			var user = auth.getUser();
			var hasPermission = true;
			var permissionType = access.permissionType || constants.AT_LEAST_ONE;
			var hasRole = true;

			//if access requires login and user is not logged 
			//or if the user is not logged in and route requires a a role either a role or a permission
			//then return user is required to login error
			if ((access.requiresLogin == true && user == undefined) ||
				(user == undefined && (access.requirePermissions !== undefined && access.requirePermissions.length > 0)
					&& (access.requireRoles !== undefined && access.requireRoles.length > 0)
				)) {
				return constants.LOGIN_REQUIRED;
			}
			//if access requires user to be logged out and user is logged in then return requires logout error contstant
			else if (access.requiresLogout == true && user !== undefined) {
				return constants.LOGOUT_REQUIRED;
			}
			//if access requirs login and user is not logged
			//and there are no permissions or roles required then return authroization error
			else if ((access.requiresLogin == true && user !== undefined) &&
				(access.requirePermissions == undefined || access.requirePermissions.length == 0) &&
				(access.requireRoles == undefined || access.requireRoles.length == 0)) {
				//Login is requreid but no specific permissions are required
				return constants.AUTHORIZED;
			}
			//if access requires a role then check to see if the user has the role
			else if (access.requireRoles && access.requireRoles.length > 0) {
				for (var i = 0; i < access.requireRoles.length; i++) {
					var role = access.requireRoles[i];
					var roleWithPermission = $rootScope.userRoles.find(function (userRole) {
						return userRole.slug == role || userRole.slug == 'admin';
					});
					if (permissionType == constants.COMBINATION_REQUIRED) {
						hasRole = hasRole && roleWithPermission != undefined;
						if (hasRole == false) {
							break;
						}
					} else if (permissionType == constants.AT_LEAST_ONE) {
						hasRole = roleWithPermission != undefined;
						if (hasRole) {
							break;
						}
					}
				}
				return hasRole ? constants.AUTHORIZED : constants.NOT_AUTHORIZED;
			}
			else if (access.requirePermissions && access.requirePermissions.length > 0) {
				for (var i = 0; i < access.requirePermissions.length; i++) {
					var permission = access.requirePermissions[i];
					var roleWithPermission = $rootScope.userRoles.find(function (userRole) {

						return userRole.permissions.find(function (userPermission) {
							return userPermission.slug == permission;
						}) != undefined || userRole.slug == 'admin';
					});
					if (permissionType == constants.COMBINATION_REQUIRED) {
						hasPermission = hasPermission && roleWithPermission != undefined;

						if (hasPermission == false) {
							break;
						}
					} else if (permissionType == constants.AT_LEAST_ONE) {
						hasPermission = roleWithPermission != undefined;
						if (hasPermission) {
							break;
						}
					}
				}
				return hasPermission ? constants.AUTHORIZED : constants.NOT_AUTHORIZED;
			}

			return constants.AUTHORIZED;
		}
	}])