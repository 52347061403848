angular.module('hrmnext.recruitment.landing', [])
.config(['$stateProvider',function($stateProvider){

	$stateProvider.state('landing',{
		url : '/',
		templateUrl: 'app/landing/landing.html',
		controller: 'LandingCtrl',
		access : {
			completedProfile: true,
		}
	});
}])
.controller('LandingCtrl',['$scope',function($scope){
	$scope.slides = [
		{
			image : 'http://placehold.it/1900x1080',
			active: true,
			text : 'Slide One'
		},
		{
			image : 'http://placehold.it/1900x1080',
			active: false,
			text : 'Slide Two'
		},
		{
			image : 'http://placehold.it/1900x1080',
			active: false,
			text : 'Slide Three'
		},
	];
}]);