angular.module('hrmnext.recruitment.feedback', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('feedback', {
			url: '/feedback',
			templateUrl: 'app/feedback/feedback.html',
			controller: 'FeedbackCtrl',
			resolve: {
				feedbackTypes: function (Restangular) {
					return Restangular.all('feedbackTypes').getList();
				}
			}
		});
	}])

	.controller('FeedbackCtrl', ['$scope', 'Restangular', 'Notification', 'feedbackTypes', '$rootScope', '$state', function ($scope, Restangular, Notification, feedbackTypes, $rootScope, $state) {
		$scope.feedbackTypes = feedbackTypes;

		$scope.feedback = {
			feedback_type_id: feedbackTypes[0].id,
			name: $rootScope.authUser ? $rootScope.authUser.first_name + ' ' + $rootScope.authUser.last_name : '',
			email: $rootScope.authUser ? $rootScope.authUser.email : '',
		};

		$scope.sendFeedback = function () {
			$scope.feedbackForm.$setSubmitted();
			if ($scope.feedbackForm.$valid) {
				Restangular.all('feedbacks').customPOST($scope.feedback).then(function (response) {
					if ($rootScope.authUser) {
						$state.go('home');
					} else {
						$state.go('landing');
					}
					Notification.success('Your feedback was successfully submitted');
				}, function (response) {
					Notification.error('An error occurred while trying submit your feedback');
				});
			}
		}
	}])