angular.module('hrmnext.recruitment.manage.notifications.show', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.notifications.show',{
		url: '/:id',
		templateUrl: 'app/manage/notifications/show.notifications.html',
		controller : 'ShowNotificationCtrl',
		resolve : {
			notification : function(Restangular,$stateParams){
				return Restangular.one('notifications',$stateParams.id).get();
			}
		}
	})
}])
.controller('ShowNotificationCtrl', ['$scope','Notification','Restangular','notification', function($scope,Notification,Restangular,notification){
	$scope.notification = notification;
}])