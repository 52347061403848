angular.module('hrmnext.recruitment.profile')
.controller('EducationCtrl', [
'$scope',
'constants',
'Restangular',
'$uibModalInstance',
'education',
'user_id',
'educations',
function($scope,constants,Restangular,$uibModalInstance,education,user_id,educations){
	$scope.education = Object.assign({},education);
	$scope.constants = constants;

	//when the form is loaded then parse the dates
	$scope.education.start_date = moment($scope.education.start_date).toDate();
	$scope.education.end_date = moment($scope.education.end_date).toDate();

	$scope.search = function(resourceName, searchQuery){
		return Restangular.all(resourceName).getList({'name':searchQuery})
		.then(function(response){
			return response.map(function(response){
				return response;
			});
		});
	}

	$scope.saveUserEducation  = function(){	
		$scope.form.$setSubmitted();
		if($scope.form.$valid){
			if(angular.isUndefined($scope.education.id)){
				promise = Restangular.one('users',user_id)
					.all('educations')
					.customPOST($scope.education)
					.then(function(response){
						educations.push(response);
					});
			}else{
				promise = Restangular
					.one('users',user_id)
					.one('educations',$scope.education.id)
					.customPUT($scope.education)
					.then(function(response){
						var index = educations.findIndex(function(edu){ return edu.id == $scope.education.id });
						educations[index] = response;
					});
			}
			promise.then(function(response){
				$uibModalInstance.close(response)
			},function(response){
				$scope.response = response.data;
			});			
		}
	}
	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}

}]);