angular.module('hrmnext.recruitment.profile.interviews', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('profile.interviews',{
		url : '/interviews',
		controller : 'ProfileInterviewCtrl',
		templateUrl : 'app/profile/interviews/interview.html',
		access: {
			requiresLogin : true,
		},
		resolve: {
			interviews : function(Restangular,user){
				return user.all('interviews').getList();
			}
		}
	});
}])
.controller('ProfileInterviewCtrl',function($scope,Restangular,Notification,interviews){
	$scope.interviews = interviews;

	$scope.resendInvitation = function(interview){
		interview.all('resendInvitation').customPOST({}).then(function(response){
			Notification.success('Successfully resent invitation');
		},function(response){
			Notification.error('Failed when trying to resend invitation');	
		});
	}

});