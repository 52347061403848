angular.module('hrmnext.recruitment.manage.interviews.show', [
	'hrmnext.recruitment.manage.interview.service'
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.interviews.show', {
			url: '/:id',
			templateUrl: 'app/manage/interviews/show/show.html',
			controller: 'ShowInterviewCtrl',
			resolve: {
				interview: function (Restangular, $stateParams) {
					return Restangular.one('interviews', $stateParams.id).get({
						'include': 'participants.user.phoneNumbers,participants.invitation,participants.questions.response.comments.user,participants.notes,position.company,candidate.user.phoneNumbers'
					});
				},
				pusherConnection: function (Restangular) {
					return Restangular.one('pusher').get();
				}
			},
			access: {

			}
		})
	}])
	.controller('ShowInterviewCtrl', function ($scope, Notification, Restangular, interview, auth, DialogService, $anchorScroll, $location, pusherConnection, $pusher, InterviewManagerService) {
		$scope.interview = interview;
		$scope.users = {};
		$scope.syncMode = true;
		$scope.interviewService = InterviewManagerService;
		//whenever someone joins the interview session send join event

		Pusher.log = function (msg) {
			console.log(msg);
		}

		// Enable pusher logging - don't include this in production
		var pusher = new Pusher(pusherConnection.PUSHER_KEY, {
			encrypted: true,
			authEndpoint: 'api/pusher/auth',
			auth: {
				headers: {
					'Authorization': "Bearer " + localStorage.getItem('jwt')
				}
			}
		});

		$scope.titleOption = {
			inline: true,
			toolbar: 'undo redo',
			menubar: false,
			theme: 'modern',
			plugins: ['placeholder']
		}
		$scope.descriptionOption = {
			inline: true,
			theme: 'modern',
			plugins: [
				'advlist autolink lists link image charmap print preview anchor',
				'searchreplace visualblocks code fullscreen',
				'insertdatetime media table contextmenu paste', 'placeholder'
			],
			toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image'
		};

		$scope.init = function () {
			//make the selected participant be the current auth user
			$scope.selectedParticipant = $scope.interview.participants.data.find(function (participant) {
				return auth.getUser().id == participant.user_id;
			});
			if (!$scope.selectedParticipant) {
				$scope.selectedParticipant = $scope.interview.participants.data[0];
			}

			if ($scope.selectedParticipant) {
				$scope.questions = $scope.selectedParticipant.questions.data;
				if ($scope.questions.length) {
					$scope.questions[0].isOpen = true;
				}
				$scope.notes = $scope.selectedParticipant.notes.data;
			}
		};
		$scope.init();

		$scope.removeQuestion = function (question) {
			DialogService.confirm('Are you sure you want to remove this question?', 'Delete Comment').then(function () {
				Restangular.one('interviewer-questions', question.id).customDELETE().then(function (response) {
					question.deleted = true;
					Notification.success('Successfully removed question');
				}, function (response) {
					Notification.error('Failed to remove question');
				});
			})
		}

		$scope.saveChanges = function () {
			$scope.interview.save().then(function (interview) {
				$scope.interview = interview;
				$scope.init();
				Notification.success('Successfully updated interview');
			}, function (response) {
				Notification.error('Failed to updated interview');
			});
		}
		$scope.addComment = function (response) {

			Restangular.all('comments').customPOST({
				user_id: auth.getUser().id,
				message: '',
				commentable_id: response.id,
				commentable_type: 'interivew_response',
			}, null, {
					include: 'user'
				}).then(function (comment) {
					Notification.success('Successfully created comment');
					comment.edit = true;
					response.comments.data.push(comment);
				}, function (response) {
					Notification.error('Failed to create comment');
				});
		}
		$scope.selectedParticipantChanged = function (selectedParticipant) {
			Restangular.all('interviewer-questions').getList({
				'participant_id': selectedParticipant.id,
				include: 'response.comments.user'
			}).then(function (response) {
				$scope.questions = response;
				$scope.questions[0].isOpen;
			});

			Restangular.all('notes').getList({
				'noteable_id': selectedParticipant.id,
				'noteable_type': 'App\\InterviewParticipant'
			}).then(function (response) {
				$scope.notes = response;
			});
		}
		$scope.deleteComment = function (comment) {
			DialogService.confirm('Are you sure you want to remove this comment?', 'Delete Comment').then(function () {

				Restangular.one('comments', comment.id).customDELETE().then(function (response) {
					comment.deleted = true;
					Notification.success('Comment was removed successfully');
				}, function (response) {
					Notification.error('Failed to remove comment');
				});
			});

		}
		$scope.removeNote = function (note) {
			DialogService.confirm('Are you sure you want to remove this note?', 'Delete Comment').then(function () {
				Restangular.one('notes', note.id).customDELETE().then(function (response) {
					note.deleted = true;
					Notification.success('Successfully removed note');
				}, function (response) {
					Notification.error('Failed to remove note');
				})
			});
		}

		$scope.addNote = function (participant) {
			Restangular.all('notes').customPOST({
				title: '',
				notes: '',
				noteable_type: 'App\\InterviewParticipant',
				noteable_id: participant.id,
			}).then(function (response) {
				$scope.notes.push(response);
				Notification.success('Successfully created note');
			}, function (response) {
				Notification.errors('Failed to save note')
			});
		}


		$scope.syncQuestionChanges = function (question) {
			Restangular.one('interviewer-questions', question.id).customPUT(question).then(function (response) {
				Notification.success('Question updated successfully');
			}, function (response) {
				Notification.error('Failed to save question.')
			});
		}
		$scope.syncNote = function (note) {
			Restangular.one('notes', note.id).customPUT(note).then(function (response) {
				Notification.success('Successfully updated note');
			}, function (response) {
				Notification.error('Failed to update note');
			});
		}

		$scope.syncCommentChanges = function (comment) {
			Restangular.one('comments', comment.id).customPUT(comment).then(function (response) {
				response.edit = comment.edit;
				comment = response;

				Notification.success('Comment updated successfully');
			}, function (response) {
				Notification.error('Failed to update comment');
			});
		}
		var fristRun = false;
		$scope.$watch('questions', function (cq, pq) {
			for (var i = 0; i < cq.length; i++) {
				if (cq[i].isOpen === true && (typeof pq[i] == 'undefined' || pq[i].id !== cq[i].id || (pq[i].id == cq[i].id && cq[i].isOpen !== pq[i].isOpen))) {
					Restangular.one('interviews', $scope.interview.id).customPOST({
						type: 'viewing-interview',
						question_id: cq[i].id,
						template_question_id: cq.question_id,
					}, 'events').then(function (data) { });
					break;
				}
			}
			var fristRun = false;
		}, true);

		/**
		 * Pusher
		 */
		channel = pusher.subscribe('presence-interviews_' + $scope.interview.id);
		channel.bind('pusher:subscription_succeeded', function (members) {
			console.log('subscription_succeeded', 'members:' + members.count);

			Restangular.one('interviews', $scope.interview.id).customPOST({
				type: 'join-interview',
			}, 'events').then(function (data) { }, function (response) {
				Notifciation.error('Failed to join interview session');
			});
			$scope.$apply();
		});
		channel.bind('pusher:member_added', function (member) {
			Notification.success(member.info.first_name + " " + member.info.last_name + " join the interview");
			$scope.$apply();
		});

		channel.bind('pusher:member_removed', function (member) {
			Notification.warning(member.info.first_name + " " + member.info.last_name + " left the interview");
			$scope.questions.forEach(function (question) {
				if (question.usersViewing == undefined) {
					question.usersViewing = [];
				}
				//whate ever question the user was looking at then remove them
				index = question.usersViewing.findIndex(function (userViewing) {
					return userViewing.user_id == member.info.user_id
				});
				if (index >= 0) {
					//remove the user from the viewing list
					question.usersViewing.splice(index, 1);
				}
			});
			$scope.$apply();
		});
		channel.bind('App\\Events\\InterviewEvent', function (event) {
			switch (event.data.type) {
				case 'viewing-interview':
					$scope.questions.forEach(function (question) {
						if (question.usersViewing == undefined) {
							question.usersViewing = [];
						}
						//if the the user opened the same question
						if (question.id == event.data.question_id ||
							(question.question_id == event.data.template_question_id && question.question_id != null)) {
							//check to see if the user was already viewing the question
							index = question.usersViewing.findIndex(function (userViewing) {
								return userViewing.user_id == event.data.user_id
							});

							if (index < 0) {
								question.usersViewing.push(event.data);
							} else {
								question.usersViewing[index] = event.data;
							}
						}
						//if the question is not the same quesiton
						else {
							//check to see if the user was previously viewing this question
							index = question.usersViewing.findIndex(function (userViewing) {
								return userViewing.user_id == event.data.user_id
							});

							//if the the index of user was viewing the question was found the remove it
							if (index >= 0) {
								//remove the user from the viewing list
								question.usersViewing.splice(index, 1); //splice on -1  remove the last item.
							}
						}
					});
					break;
				case 'join-interview':
					//whenever someone joins send what question you are currently viewing
					var openedQuestion = $scope.questions.find(function (question) {
						return question.isOpen === true;
					});
					if (openedQuestion) {
						Restangular.one('interviews', $scope.interview.id).customPOST({
							type: 'viewing-interview',
							question_id: openedQuestion.id,
							template_question_id: openedQuestion.question_id,
						}, 'events').then(function (data) { }, function (response) {
							Notification.error('Failed to send question viewing');
						});
					}
					break;
			}
			$scope.$apply();
		});
		$scope.$on("$destroy", function () {
			//destory the connection when the user leaves
			pusher.disconnect();
		});
	})