angular.module('hrmnext.recruitment.services')
.factory('page', ['$rootScope','$state','$timeout', function($rootScope,$state,$timeout){
	return {
		isActive : function(states){
			for (var i = 0; i < states.length; i++) {
				if($state.includes(states[i])){
					return true;
				}
			}
			
			return false;
		}	
	};
}]);