angular.module('hrmnext.recruitment.profile')
.controller('SkillCtrl',[
	'$scope',
	'Restangular',
	'user_id',
	'$uibModalInstance','skill', 'skills', 'Notification',
	function($scope,Restangular,user_id,$uibModalInstance,skill, skills,Notification){
		$scope.skill = skill;


		$scope.search = function(resourceName, searchQuery){
			return Restangular.all(resourceName).getList({'name':searchQuery})
			.then(function(response){
				return response.map(function(response){
					return response;
				});
			});
		}
		$scope.saveUserSkills = function(){
			var promise;
			if(angular.isUndefined($scope.skill.id)){
				promise = Restangular
					.one('users',user_id)
					.post('skills',$scope.skill)
					.then(function(response){
						skills.push(response);
						Notification.success('Your skill was successfully created.');
					});
			}
			else {
				promise = Restangular
					.one('users',user_id)
					.one('skills',$scope.skill.id)
					.put($scope.skill)
					.then(function(response){
						index = skills.findIndex(function(sk){
							return sk.id == $scope.skill.id;
						});

						skills[index] = response;
						Notification.success('Your skill was successfully updated.');
					});
			}

			promise.then(function(response){
				$uibModalInstance.close(response)
			},function(response){
				$scope.response = response.data;
			});	
		}
		$scope.cancel = function(){
			$uibModalInstance.dismiss();
		}
	}
])