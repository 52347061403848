/**
*
* Login Module Definition
*
**/
angular.module('hrmnext.recruitment.activate', [])

	/**
	*
	* Route Provider Settings
	*
	**/
	.config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('activate', {
			url: '/activate?code',
			controller: 'ActivateCtrl',
			templateUrl: 'app/activate/activate.html',
			resolve: {
				activation: function (Restangular, $stateParams) {
					return Restangular.one('auth/activation', $stateParams.code).get();
				}
			},
			access: {
				requiresLogout: true
			}
		});
	}])

	/**
	*
	* Login Controller Definition
	*
	**/
	.controller('ActivateCtrl', ['$scope', '$state', 'Restangular', '$stateParams', 'Notification', 'activation', '$timeout', function ($scope, $state, Restangular, $stateParams, Notification, activation, $timeout) {
		$scope.response = {};
		$scope.activation = activation;
		$scope.activationCode = $stateParams.code;
		$scope.activating = false;

		if ($scope.activation.is_verified) {
			$state.go('login');
			Notification.error('The account was already activated');
		}

		// if the activation requires creating a new password
		if ($scope.activation.new_password_on_activation == false && !$scope.activation.is_verified) {
			$scope.activating = true
			Restangular.one('auth/activation', $scope.activationCode).customPUT($scope.activation).then(function (response) {
				Notification.success('Your account was successfully activated');

				$state.go('login');
			}, function (response) {
				Notification.error(response.data.message);
				$scope.activating = false
				$state.go('login');
			});
		}


		$scope.activateAccount = function (passwordForm) {
			passwordForm.$setSubmitted();
			if (passwordForm.$valid) {

				Restangular.one('auth/activation', $scope.activationCode).customPUT($scope.activation).then(function (response) {
					$state.go('login');
					Notification.success('Your account was successfully activated');
				}, function (response) {
					Notification.error(response.data.message);
					console.log(response);
					$scope.response = response.data;
				})
			}
		}



	}]);