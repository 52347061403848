angular.module('hrmnext.recruitment.profile.personal-details', [])
	.controller('PersonalDetailsCtrl', [
		'$scope',
		'$uibModalInstance',
		'user',
		'countries',
		'Restangular',
		'Notification',
		'formValidationService',
		function ($scope, $uibModalInstance, user, countries, Restangular, Notification, formValidationService) {
			$scope.user = {};
			angular.copy(user, $scope.user);
			$scope.countries = countries;

			if (typeof $scope.user.date_of_birth == 'string') {
				$scope.user.date_of_birth = moment(user.date_of_birth).toDate();
			}
			$scope.save = function () {
				if ($scope.user.country_id == 0) {
					$scope.userForm.country.$error.required = true;
					angular.element('#country').addClass('has-error');
				} else {

					$scope.userForm.$setSubmitted();

					if ($scope.userForm.$valid) {
						Restangular.one('users', $scope.user.id).customPUT($scope.user).then(function (response) {
							Notification.success('Your personal details was successfully updated');
							$uibModalInstance.close(response);
						}, function (response) {
							$scope.formError = formValidationService.serverError($scope.phoneNumberForm, response);
						});
					}
				}
			}

			$scope.cancel = function () {
				$uibModalInstance.dismiss();
			}
		}
	]);