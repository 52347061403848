angular.module('hrmnext.recruitment.components.slimscroll', [])
.directive('slimScroll', [ function(){
	// Runs during compile
	return {
		// name: '',
		// priority: 1,
		// terminal: true,
		scope: {
			slimScroll : '=',
		}, // {} = isolate, true = child, false/undefined = no change
		// controller: function($scope, $element, $attrs, $transclude) {},
		// require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		// restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
		// template: '',
		// templateUrl: '',
		// replace: true,
		// transclude: true,
		// compile: function(tElement, tAttrs, function transclude(function(scope, cloneLinkingFn){ return function linking(scope, elm, attrs){}})),
		link: function($scope, iElm, iAttrs, controller) {
			var init = false;
			var elem = iElm;
			$scope.$watch('slimScroll',function(){
				if($scope.slimScroll && !init){
					init = true;
					$(elem).slimScroll($scope.slimScroll);					
				}
				
			});
		}
	};
}]);