angular.module('hrmnext.recruitment.manage.position.stage.interviewerFilter', [])
.filter('interviewerFilter', function(){
	return function(interviewers,ids){
		return interviewers.filter(function(interviewer){
			if(Array.isArray(ids)){
				return ids.find(function(id){
					return interviewer.user.id == id;
				})
			}else{
				return interviewer.user.id == ids
			}
		});
	}
});