angular.module('hrmnext.recruitment.services')
.factory('AuthService', ['$q','$http','store', function($q,$resource,store){
	return {
		refreshToken : function(){
			// This will not send the JWT for this call
			return $http({
		      	url: 'api/token',		        
		        method: 'POST',
		        headers : {Authorization : 'Bearer '+jwt },
		        skipAuthorization: true,
		    });
		},
		storeToken : function(token){
			store.set('jwt', token);
		},
		logout : function(){
			store.remove('jwt');
		}
	};
}])