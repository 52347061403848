angular.module('hrmnext.recruitment.positions.application', [])
	.controller('JobApplicationCtrl', function ($scope, position, $uibModalInstance, Restangular, auth) {
		$scope.position = position;

		$scope.tinymceOptions = {
			onChange: function (e) {
				// put logic here for keypress and cut/paste changes
			},
			theme: 'modern',
			menubar: false,
			branding: false,
			plugins: [
				'advlist autolink lists link image charmap print preview anchor textcolor',
				'searchreplace visualblocks code fullscreen',
				'insertdatetime media table contextmenu paste code help'
			],
			toolbar: 'insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
			content_css: [
				'//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
				'//www.tinymce.com/css/codepen.min.css']
		}

		$scope.application = {
			reason: '',
			user_id: auth.getUser().id,
			position_id: position.id,
		};

		$scope.ok = function () {
			$scope.displayFormError = false;
			Restangular.one('applications').customPOST($scope.application).then(function (application) {
				$uibModalInstance.close();
			}, function (response) {
				$scope.response = response;
				$scope.displayFormError = true;
			});
		}
		$scope.cancel = function () {
			$uibModalInstance.dismiss();
		}

	})
