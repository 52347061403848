angular.module('hrmnext.recruitment.manage.position', [
	'hrmnext.recruitment.manage.position.show',
	'hrmnext.recruitment.manage.position.create'
])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.position',{
		url : '/position',
		templateUrl: 'app/manage/position/position.html',
		controller: 'ManagePositionCtrl',
		access : {
			requirePermissions : ['update_position'],
			permissionType : 'combinationRequired',
		},
		resolve : {
			positions : ['Restangular','$q','$rootScope',function(Restangular,$q,$rootScope){
				return Restangular.all('positions').getList({include:'company','manage':true});
			}],
			companies : ['Restangular','$rootScope',function(Restangular,$rootScope){
				return Restangular.all('companies').getList({ manage : true });
			}],
			positionTypes : ['Restangular',function(Restangular){
				return Restangular.all('position_types').getList();
			}],
			countries : ['Restangular',function(Restangular){
				return Restangular.all('countries').getList();
			}],
			edcuationPriorities : function(Restangular){
				return Restangular.all('education_priorities').getList();
			}
		}
	});
}])
.controller('ManagePositionCtrl', ['$scope','positions','$uibModal','Notification','companies', 'positionTypes','countries','edcuationPriorities','Restangular',
function($scope,positions,$uibModal,Notification,companies,positionTypes,countries,edcuationPriorities,Restangular){
	$scope.positions = positions;
	$scope.positionPageChanged = function(){

	}

	$scope.createPosition = function(){
		var modalInstance = $uibModal.open({
			animation:true,
			templateUrl:'app/manage/position/create/create.html',
			controller: 'CreatePositionCtrl',
			backdrop: 'static',
			size : 'lg',
			resolve : {
				companies : [function(){
					return companies;
				}],				
				positionTypes : [function(){
					return positionTypes;
				}],
				countries : [function(){
					return countries;
				}],
				edcuationPriorities : function(){
					return edcuationPriorities;
				}
			},
		});
		modalInstance.result.then(function(position){
			//push the position to the existing list
			$scope.positions.push(position);
			$scope.positions.meta.total +=1;

			Notification.success('You position was successfully created');
		},function(response){
			//report an error occured while trying to create the position
			//Notification.error('Failed when trying to create position');
		})

	}
	$scope.updateSearchQuery = function(searchQuery){
		Restangular.all('positions')
			.getList({ query : searchQuery, manage:true })
			.then(function(data){
				$scope.positions = data;
			});

	}
}]);