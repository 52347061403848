angular.module('hrmnext.recruitment.profile')
.controller('ChangePasswordCtrl', ['$scope','$uibModalInstance', function($scope,$uibModalInstance){

	$scope.password =  { 
		password : "",
		password_confirmation : ""
	}

	$scope.ok = function(){

		$uibModalInstance.close($scope.password);
	}

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}

	$scope.isValid = function(){
		return $scope.password.password === $scope.password.password_confirmation;
	}
}]);