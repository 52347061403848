angular.module('hrmnext.recruitment.manage.company.edit', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.company.edit',{
		'url': '/:id/edit',
		'controller' : 'EditCompanyCtrl',
		'templateUrl' : 'app/manage/company/edit/edit.html',
		'resolve' : {
			company : ['Restangular', function(Restangular,$stateParams){
				return Restangular.one('companies',$stateParams.id).get();
			}]
		}
	})
}])
.controller('EditCompanyCtrl', ['$scope', 'Restangular', function($scope, Restangular,company){
	$scope.company = company;
}]);