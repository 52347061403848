angular.module('hrmnext.recruitment.manage.position.stages.candidate-interviews', [])
.controller('CandidateInterviewsCtrl', function($scope, candidate, interviews,interviewers, $uibModalInstance){
	$scope.candidate = candidate;
	$scope.interviews = interviews;


	$scope.getInterviewer = function(user_id){
		return interviewers.find(function(interviewer){
			return user_id == interviewer.user.id
		});
	}

	$scope.close = function(){		
		$uibModalInstance.dismiss();
	}
	$scope.ok = function(){
		$uibModalInstance.close($scope.interview);
	}
	
});