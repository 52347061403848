angular.module('hrmnext.recruitment.manage.user-list', [
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.users.list', {
			url: '',
			controller: 'ManageUserCtrl',
			templateUrl: 'app/manage/user/user-list.html',
			resolve: {
				users: ['Restangular', function (Restangular) {
					return Restangular.all('users').getList({ sortBy: 'name', sort: 'asc' });
				}],
			}
		})
	}])
	.controller('ManageUserCtrl', ['Restangular', 'Notification', '$scope', 'users', 'userService',
		function (Restangular, Notification, $scope, users, userService) {
			$scope.users = users;
			$scope.filters = {
				sortBy: 'name',
				sort: 'asc',
				query: '',
			};


			$scope.preview = function (user) {
				userService.preview(user.id);
			}
			$scope.updateUserLists = function () {
				$scope.users.getList($scope.filters).then(function (response) {
					$scope.users = response;
				}, function (response) {
					Notification.error('An error occurred while trying to get user list');
				});
			}
			$scope.userPaginationUpdated = function () {
				$scope.filters.page = $scope.users.meta.pagination.current_page;
				$scope.updateUserLists();
			}
			$scope.getSortByClass = function (fieldName) {
				if ($scope.filters.sortBy == fieldName) {
					return 'fa-sort-' + $scope.filters.sort;
				}
				return 'fa-sort text-muted';
			}
			$scope.sortBy = function (fieldName) {
				$scope.filters.sort = $scope.filters.sortBy != fieldName || $scope.filters.sort == 'desc' ? 'asc' : 'desc';
				$scope.filters.sortBy = fieldName;
				$scope.updateUserLists();
			}
			$scope.resendVerification = function (user) {
				Restangular.one('users', user.id).customPOST({}, 'verification').then(function (response) {
					Notification.success('The verification email was sent successfully.');
				}, function (response) {
					Notification.error('An error occurred while trying to send verification email');
				});
			}
		}])
