angular.module('hrmnext.recruitment.user.services.user', [
	'hrmnext.recruitment.manage.user.preview',
])
.service('userService',['$uibModal','Restangular',function($uibModal,Restangular){
	this.preview  = function(user_id){
		var modalInstance = $uibModal.open({
			templateUrl : 'app/manage/user/preview/preview.html',
			controller 	: 'UserPreviewCtrl',
			size: 'lg',
			windowClass : 'user-preview',
			resolve		: {
				user : function(Restangular){
					return Restangular.one('users',user_id).get({include: 'addresses,educations,employmentHistories,phoneNumbers,skills,hobbies,resumes'});
				}
			}
		});
		modalInstance.result.then(function(response){

		});
	}
}]);