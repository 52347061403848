/**
*
* The FlashService stores a message that is displayed to the user once the message is seen by the user
* once
*
**/
angular.module('hrmnext.recruitment.services')
.factory('FlashService', ['$rootScope',function($rootScope){
	var _message = '';
	return {
		getMessage : function(){
			var message = _message;
			
			_message = "";
			return message;		
		},

		setMessage : function(message){
			_message = message;
			$rootScope.$broadcast('flash');
		}
	}
}]);	