/**
 * hrmnext.recruitment.manage.interview.service Module
 *
 * Description
 */
angular.module('hrmnext.recruitment.manage.interview.service', [
	'hrmnext.recruitment.manage.interview.components.question'
])
	.service('InterviewManagerService', function (Restangular, Notification, DialogService, $uibModal) {
		this.addQuestion = function (participant, questions, sync) {
			var question = {
				template: false,
				question: '',
				question_id: null,
				order: questions.length,
				description: '',
				participant_id: participant.id,
				weight: 0.0,
				can: {
					delete: true,
					edit: true,
				},
				response: {
					data: {
						can: {
							edit: true,
							delete: true,
						},
						response: '',
						score: 0,
						comments: {
							data: []
						}
					}
				}
			};
			$uibModal.open({
				templateUrl: 'app/manage/interviews/components/question/question.modal.html',
				controller: 'AddQuestionModalCtrl',
				size: 'lg',
				backdrop: 'static',
				resolve: {
					sync: function () {
						return sync;
					},
					question: function () {
						return question;
					}
				}
			}).result.then(function (response) {
				questions.forEach(function (question) {
					question.isOpen = false;
				});
				response.isOpen = true;
				questions.push(response);
			});

		}

		this.addComment = function (response, sync, user) {
			var comment = {
				user: {
					data: user
				},
				user_id: user.id,
				message: '',
				commentable_id: response.id,
				edit: true,
				created_at: new Date,
				updated_at: new Date,
				commentable_type: 'interview_response',
				can: {
					edit: true,
					delete: true,
				}
			};

			if (sync) {
				Restangular.all('comments').customPOST(comment, null, {
					include: 'user'
				}).then(function (comment) {
					comment.edit = true;
					response.comments.data.push(comment);
					Notification.success('Successfully created comment');
				}, function (response) {
					Notification.error('Failed to create comment');
				});
			} else {
				response.comments.data.push(comment);
			}
		}

		this.removeQuestion = function (question, sync) {
			DialogService.confirm('Are you sure you want to remove this question?', 'Delete Comment').then(function () {
				if (sync) {
					Restangular.one('interviewer-questions', question.id).customDELETE().then(function (response) {
						question.deleted = true;
						Notification.success('Successfully removed question');
					}, function (response) {
						Notification.error('Failed to remove question');
					});
				} else {
					question.deleted = true;
				}
			})
		}

		this.deleteComment = function (comment, sync) {
			DialogService.confirm('Are you sure you want to remove this comment?', 'Delete Comment').then(function () {
				if (sync) {
					Restangular.one('comments', comment.id).customDELETE().then(function (response) {
						comment.deleted = true;
						Notification.success('Comment was removed successfully');
					}, function (response) {
						Notification.error('Failed to remove comment');
					});
				} else {
					comment.deleted = true;
				}
			});
		}

		this.commentUpdated = function (comment, sync) {
			if (sync) {
				Restangular.one('comments', comment.id).customPUT(comment).then(function (response) {
					response.edit = comment.edit;
					comment = response;


					Notification.success('Comment updated successfully');
				}, function (response) {
					Notification.error('Failed to update comment');
				});
			}
		}
		this.updateQuestion = function (questions, question, sync) {
			$uibModal.open({
				templateUrl: 'app/manage/interviews/components/question/question.modal.html',
				controller: 'EditQuestionModalCtrl',
				size: 'lg',
				backdrop: 'static',
				resolve: {
					sync: function () {
						return sync;
					},
					question: function () {
						return question;
					}
				}
			}).result.then(function (response) {
				questions.forEach(function (question) {
					question.isOpen = false;
				});
				response.isOpen = true;

				questions[questions.indexOf(question)] = response;
			});
		}

		this.questionUpdated = function (question, sync) {
			if (sync) {
				Restangular.one('interviewer-questions', question.id).customPUT(question).then(function (response) {
					response.isOpen = true;
					question = response;

					Notification.success('Question updated successfully');
				}, function (response) {
					Notification.error('Failed to save question.')
				});
			}
		}
	})