angular.module('hrmnext.recruitment.manage.stages.create', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.createStage', {
			url: '/stages/create',
			controller: 'CreateStageCtrl',
			templateUrl: 'app/manage/stages/create/create.html',
			access: {
				requirePermissions: ['create_stage_definition'],
			},
			resolve: {
				companies: function (Restangular, $rootScope) {
					return Restangular.all('companies').getList({
						manage: true
					});
				}
			}
		})
	}])
	.controller('CreateStageCtrl', ['$scope', 'Restangular', 'companies', 'stageTemplateService', '$state', 'Notification', function ($scope, Restangular, companies, stageTemplateService, $state, Notification) {
		$scope.stageTemplateService = stageTemplateService;
		$scope.stageTemplate = stageTemplateService.newStageTemplate(companies[0]);
		$scope.companies = companies;

		$scope.save = function () {

			$scope.stageTemplate.save({
				include: 'stages.questions'
			}).then(function (response) {
				Notification.success('Successfully created stage template.');
				$state.go('manage.showStage', {
					id: response.id
				});
			}, function (response) {
				Notification.error('Failed to create stage template');
			});
		}

	}])