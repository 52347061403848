angular.module('hrmnext.recruitment.manage.feedbacks.detail', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.feedbacks.detail',{
		url : '/:id',
		templateUrl: 'app/manage/feedbacks/detail/detail.html',
		controller : 'ManageFeedbackDetailsCtrl',
		access : {
			requiresRoles : ['admin']
		},
		resolve : {
			feedback : function(Restangular,$stateParams){
				return Restangular.all('feedbacks').one($stateParams.id).get();
			}
		}
	})
}])
.controller('ManageFeedbackDetailsCtrl', function($scope, Restangular,feedback,Notification){
	$scope.feedback = feedback;

	// $scope.feedback.customPOST('seen',{});
})
