angular.module('hrmnext.recruitment.manage.hrmnext.index', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.hrmnext.index', {
			url: '',
			templateUrl: 'app/manage/hrmnext/index/index.html',
			controller: 'HrmnextCtrl',
			access: {
				requiresLogin: true,
				requirePermissions: ['import_from_hrmnext', 'export_to_hrmnext']
			},
			resolve: {
				companies: function (Restangular) {
					return Restangular.all('companies').getList({ manage: true });
				},
				pusherConnection: function (Restangular) {
					return Restangular.one('pusher').get();
				}
			}
		});
	}])
	.controller('HrmnextCtrl', function ($scope, Restangular, Notification, companies, pusherConnection, $timeout) {
		var pusher = new Pusher(pusherConnection.PUSHER_KEY, { encrypted: true });
		var importChannel = pusher.subscribe('import-channel');
		var exportChannel = pusher.subscribe('export-channel');

		$scope.importRunning = false;
		$scope.exportRunning = false;
		$scope.exportStages = 4;

		$scope.import = {
			company_id: companies.length > 0 ? companies[0].id : null,
			employess: true,
			positions: false,
		}
		$scope.export = {
			company_id: companies.length > 0 ? companies[0].id : null,
			candidate: true,
		}
		$scope.companies = companies;

		$scope.exportToHRMNext = function () {
			$scope.exportRunning = true;
			$scope.exportProgress = {
				message: 'Sending export request',
				progress: 0,
			}
			Restangular.one('companies', $scope.export.company_id).all('exports').customPOST().then(function (data) {
				Notification.success('Successfully exported records');
			}, function (response) {
				Notification.error('Failed when exporting records');
				console.log(response);
			}).finally(function () {
				$timeout(function () {
					$scope.exportRunning = false;
				}, 5000);
			});
		}

		$scope.importFromHRMNext = function () {
			$scope.importRunning = true;
			$scope.importProgress = {
				message: 'Sending import request',
				stage: 'sending_request',
				progress: 0,
			}
			Restangular.one('companies', $scope.export.company_id).all('imports').customPOST().then(function (data) {
				Notification.success('Successfully imported records');
			}, function (response) {
				Notification.error('Failed when importing records');
			}).finally(function () {
				$timeout(function () {
					$scope.importRunning = false;
				}, 5000);
			});
		}

		$scope.updateCompanyPositions = function (company_id) {

			Restangular.all('positions').getList({ 'company_id': company_id }).then(function (data) {
				$scope.positions = data;
				if ($scope.import.position_id == null) {
					$scope.import.position_id = $scope.positions[0].id;
				}
			});
		}

		$scope.$watch('import.company_id', function (company_id) {
			$scope.updateCompanyPositions(company_id);
		});

		importChannel.bind('App\\Events\\ImportEvent', function (data) {
			$scope.importProgress = data.importProgress;
		});
		exportChannel.bind('App\\Events\\ExportEvent', function (data) {
			$scope.exportProgress = data.exportProgress;
		});
	})