angular.module('hrmnext.recruitment.manage.position.stages.templates', [])
.controller('ImportStageTemplateCtrl', ['$scope','Restangular','stageTemplates','$uibModalInstance','Notification','$q',
function($scope, Restangular,stageTemplates,$uibModalInstance,Notification,$q){
	$scope.stageTemplates = stageTemplates;
	$scope.selectedTemplate = stageTemplates.length>0 ? stageTemplates[0] : null;

	$scope.close = function(){
		$uibModalInstance.dismiss();		
	}
	$scope.ok = function(){
		var stages = [];
		$scope.selectedTemplate.stages.data.forEach(function(data){
			var stage = Restangular.stripRestangular(data);
			stage.template_stage_id = stage.id;

			delete stage.id;
			delete stage.updated_at;

			stage.questions.data.forEach(function(question){
				delete question.id;
			});
			stage.candidates = {data :[]};
			stages.push(stage);
		});
		$uibModalInstance.close(stages);
	}
}])