angular.module('hrmnext.recruitment.signup', [])
.config(['$stateProvider',function($stateProvider){
	$stateProvider.state('signup',{
		url : '/signup',
		templateUrl: 'app/signup/signup.html',
		controller: 'SignupCtrl',
		access : {
			requiresLogout : true
		}
	});
}])
.controller('SignupCtrl', [
'$scope',
'Restangular',
'$state',
'flash',
function($scope,Restangular,$state,flash)
{	
	$scope.page = {}; 
	$scope.user = {};

	$scope.$watch('user.password_confirmation', function() {
		$scope.signupForm.password_confirmation.$setValidity('passwordConfirmed',$scope.user.password == $scope.user.password_confirmation)
	});

	$scope.createUser = function(){		
		$scope.signupForm.$setSubmitted();
		if($scope.signupForm.$valid){
			Restangular.all('auth').customPOST($scope.user, 'singup').then(function(response){
				// Set the message that should display after success
				flash.addMessage('Activation email successfully sent. Activate your account first before you attempt to login.', 'success');
				$state.go('login');
			},function(response){
	            $scope.response = response            
	            document.body.scrollTop = document.documentElement.scrollTop = 0;
			});
		}		
	}
}]);