angular.module('hrmnext.recruitment.resetPassword', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('resetPassword',{
		url : '/reset-password/:token',
		templateUrl : 'app/resetPassword/resetPassword.html',
		controller : 'AuthResetPasswordCtrl',
		access : {
			requiresLogout: true
		}
	})
}])
.controller('AuthResetPasswordCtrl', ['$scope','Restangular', '$state','Notification', function($scope,Restangular,$state, Notification){
	$scope.credentials = { 
		email: '',
		password : '',
		password_confirmation : '',
		token : $state.params.token,
	}
	$scope.resetPassword = function(){
		$scope.passwordResetForm.$setSubmitted();

		if($scope.passwordResetForm.$valid){
			Restangular.one('auth/password/reset').customPOST($scope.credentials).then(function(response){
				Notification.success('Your password was successfully updated.');
				$state.go('login');
			},function(response){
				Notification.error('Failed to update your password');
				$scope.response = response;
			});			
		}
	}
	$scope.$watch('credentials.password_confirmation', function() {
		$scope.passwordResetForm.password_confirmation.$setValidity('passwordConfirmed',$scope.credentials.password == $scope.credentials.password_confirmation)
	});

}])