angular.module('hrmnext.recruitment.mamange.company.member', [])
	.controller('CompanyMemberCtrl', ['$scope', 'Restangular', '$uibModalInstance', 'company', 'Notification', 'roles',
		function ($scope, Restangular, $uibModalInstance, company, Notification, roles) {
			$scope.userFound = false;
			$scope.roles = roles;

			$scope.save = function () {

				company.all('members').customPOST($scope.member).then(function (member) {
					Notification.success('Successfully added company member.');
					$uibModalInstance.close(member);
				}, function (response) {
					console.log("response", response, response.status, $scope.member);
					if (response.status == 403) {
						Notification.error('You do not have permission to add a member with a rank higher than yours.');
					} else if (response.status == 422) {
						Notification.error('Enter all required fields.');
					} else {
						Notification.error('Failed to create new member.');
					}
				});
			}

			$scope.member = {
				email: '',
				new_user: false,
				first_name: '',
				last_name: '',
				roles: [],
			}

			$scope.cancel = function () {
				$uibModalInstance.dismiss();
			}
			$scope.getUserByEmail = function (email) {
				if (email != '') {
					return Restangular.all('users').getList({
						'email': email
					});
				}
				return [];
			}

			$scope.i = 0;
			$scope.$watchGroup(['member.email', 'member.is_new'], function () {
				$scope.user = null;
				if ($scope.companyMembersForm.email.$valid) {
					Restangular.all('users').getList({
						'email': $scope.member.email
					}).then(function (users) {
						$scope.userFound = false;
						users.forEach(function (user, index) {
							if (user.email == $scope.member.email) {
								$scope.user = user;
								$scope.userFound = true;
							}
						});
						$scope.companyMembersForm.email.$setValidity('userDoNotExistExist', ($scope.userFound == false && $scope.member.isNew == false));
					});
				}
			}, true);
		}
	])