angular.module('hrmnext.recruitment.test', [])
.config(['$stateProvider',function($stateProvider){

	$stateProvider.state('test',{
		url : '/test',
		templateUrl: 'app/test/test.html',
		controller: 'TestCtrl',
		resolve : {
			position : function(Restangular, $rootScope){
				return Restangular.one('positions', 1).get();
			},
			access:{
				requiresLogin: true,
				requirePermissions : []
			}
		},
	});

}])
.controller('TestCtrl', function($scope,position,Notification){
	$scope.uiConfig = {
		calendar : {
			editable: true,
			header:{
				left: 'month agendaWeek agendaDay',
				center: 'title',
				right: 'today prev,next'
			},
			height : 515,
			defaultView : 'agendaWeek',
			ignoreTimezone: false,
			eventClick: $scope.alertOnEventClick,
			// eventDrop: $scope.alertOnDrop,
	        // eventResize: $scope.alertOnResize,
	        // eventRender: $scope.eventRender
		},
	}
	$scope.interview = {
		name : '',
		start : new Date,
		end : new Date,
		allDay : false
	}
	var events = [	$scope.interview ];
		
	$scope.eventSources = [
		{ events: events, color:'blue', textColor:'white' },
	];

})