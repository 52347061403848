angular.module('hrmnext.recruitment.manage.stages.show', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.showStage',{
		url : '/stages/:id',
		templateUrl:'app/manage/stages/show/show.html',
		controller : 'ShowStageCtrl',
		access : {
			requirePermissions : ['view_stage_definitions'],
		},
		resolve : {
			stageTemplate : function(Restangular,$stateParams){
				return Restangular.one('stage-templates',$stateParams.id).get({include:'stages.questions'});
			},
			companies : function(Restangular,$rootScope){
				return Restangular.one('users',$rootScope.authUser.id).all('companies').getList();
			}
		}
	})
}])
.controller('ShowStageCtrl', ['$scope','Restangular','stageTemplate','companies','stageTemplateService','$q','DialogService','Notification',
function($scope,Restangular,stageTemplate,companies,stageTemplateService,$q,DialogService,Notification){
	$scope.stageTemplate = stageTemplate;
	$scope.companies = companies;
	$scope.stageTemplateService = stageTemplateService;

	$scope.save = function(){
		$scope.stageTemplate.save({include:'stages.questions'}).then(function(response){
			$scope.stageTemplate = response;
			Notification.success('Successfully updated stage template');
		},function(response){
			Notification.error('Failed to save stage template');
		});
	}
}])
