angular.module('hrmnext.recruitment.manage.position.stages.interviewer', [])
	.controller('InterviewerCtrl', function ($scope, Restangular, $uibModalInstance, company, Notification) {
		$scope.company = company;
		$scope.interviewer = {
			first_name: '',
			last_name: '',
			email: '',
			new_user: true,
		}
		$scope.close = function () {
			$uibModalInstance.dismiss();
		}
		$scope.ok = function () {
			$scope.interviewerForm.$setSubmitted();
			if ($scope.interviewerForm.$valid) {
				Restangular.one('companies', $scope.company.id).customPOST($scope.interviewer, 'interviewers', { include: 'invitation' }).then(function (response) {
					Notification.success('Successfully added interviewer and sent invitation');
					$uibModalInstance.close(response);
				}, function (response) {
					$scope.response = response;
				});
			}
		}
	});