angular.module('hrmnext.recruitment.forgotPassword', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('forgotPassword',{
		url : '/forgot-password',
		templateUrl: 'app/forgotPassword/forgotPassword.html',
		controller : 'ForgotPasswordCtrl',
		resolve : {

		},
		access : {
			requiresLogout : true
		}
 	})
}])
.controller('ForgotPasswordCtrl',['$scope', 'Restangular','Notification','flash','$state', function($scope, Restangular,Notification,flash,$state){
	$scope.email = '';
	$scope.requestReset = function(){
		Restangular.one('auth/password').customPOST({ 'email' : $scope.email }).then(function(response){
			Notification.success('Successfully submitted password reset');
			flash.addMessage('Password reset information was sent to your email','success');
			$state.go('login');
		},function(response){
			Notification.error('Failed to submit password reset');
		});
	}
}])