angular.module('hrmnext.recruitment.profile.settings',[])
.controller('ProfileSettingsCtrl', ['$scope','Restangular','Notification',function($scope,Restangular,Notification){
	$scope.password = {
		new_password : '',
		new_password_confirmation : '',
		current_password : '',
	}
	$scope.updatePassword = function(){
		$scope.passwordForm.$setSubmitted();

		if($scope.passwordForm.$valid){
			$scope.user.all('password').post($scope.password).then(function(response){
				Notification.success('Your password was updated');
				$scope.password = {
					new_password : '',
					new_password_confirmation : '',
					current_password : '',
				}
				$scope.response = null;
				$scope.passwordForm.$setPristine();
				$scope.passwordForm.$setUntouched();
			},function(response){
				Notification.error('Failed to update your password.');
				$scope.response = response;
			});
			
		}
	}
	$scope.$watch('password.new_password_confirmation', function(newValue, oldValue, scope) {
		$scope.passwordForm.new_password_confirmation.$setValidity('confirmPassword', $scope.password.new_password == $scope.password.new_password_confirmation);
	});

	$scope.$watch('password.new_password', function(newValue, oldValue, scope) {
		$scope.passwordForm.new_password.$setValidity('differentPassword',$scope.password.current_password != $scope.password.new_password);
	});
}]);