/* Login Module Definition */
angular.module('hrmnext.recruitment.login', [
	'ui.router',
	'ui.bootstrap',
	'angular-storage',
	'hrmnext.recruitment.services',
])
	.config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('login', {
			url: '/login',
			templateUrl: 'app/login/login.html',
			controller: 'LoginCtrl',
			access: {
				requiresLogout: true
			},
			params: {
				'toState': 'home',
				'toParams': {}
			}
		});
	}])
	.controller('LoginCtrl', ['$scope', '$state', 'flash', 'store', 'Restangular', 'Notification', 'jwtHelper', '$rootScope',
		function ($scope, $state, flash, store, Restangular, Notification, jwtHelper, $rootScope) {
			$scope.user = { credentials: {} };
			$scope.flash = flash;

			$scope.login = function () {
				$scope.showResendVerification = false;
				Restangular.all('auth').customPOST($scope.user.credentials, 'login')
					.then(function (response) {
						store.set('jwt', response.token);
						var user = jwtHelper.decodeToken(response.token);

						if ($rootScope.targetState) {
							$state.go($rootScope.targetState._identifier, $rootScope.targetState._params);
							delete $rootScope.targetState;
						}
						else {
							if (user.last_login) {
								$state.go('profile.details');
							}
							if (user.canManage) {
								$state.go('manage.dashboard');
							}
							else {
								$state.go($state.params.toState, $state.params.toParams);
							}
						}
					}, function (response) {
						flash.setMessage(response.data.message, 'danger');
						if (!angular.isUndefined(response.data.errors) && !angular.isUndefined(response.data.errors.not_verified)) {
							$scope.showResendVerification = true;
						}
					});
			}

			$scope.resendVerification = function (email) {
				Restangular.all('auth/resend_verification').post({ email: email }).then(function (response) {
					Notification.success('Verification email was successfully resent');
				}, function (response) {
					Notification.error('Failed to send verification email');
				});
			}
		}]);