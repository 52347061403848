angular.module('hrmnext.recruitment.manage.stages.template', [])
	.service('stageTemplateService', ['Restangular', 'Notification', '$state', 'DialogService', function (Restangular, Notification, $state, DialogService) {
		this.addStage = function (stageTemplate) {
			stageTemplate.stages.data.push({
				name: '',
				description: '',
				order: stageTemplate.stages.data.length,
				template_stage_id: null,
				questions: {
					data: []
				},
				isOpen: true,
			});
		}
		this.addQuestion = function (stage) {
			stage.questions.data.push({
				question: '',
				order: stage.questions.data.length,
				comment: '',
				score: 0,
				weight: 0,
				isOpen: true,
			});
		}
		this.removeQuestion = function (question) {
			var modalInstance = DialogService.confirm('Are you sure you want to remove this question?', 'Delete Question');

			modalInstance.then(function () {
				question.deleted = true;
			});

		}
		this.removeStage = function (stage) {
			var modalInstance = DialogService.confirm('Are you sure you want to remove this stage?', 'Delete Stage');

			modalInstance.then(function () {
				stage.deleted = true;
			});
		}

		this.getTemplateDefinition = function (companies) {
			var stageTemplate = {
				name: '',
				company_id: companies[0].id,
				description: '',
				stages: []
			};
			return stageTemplate;
		}

		this.saveTemplate = function (stageTemplate) {
			Restangular.save('stage-templates').post(stageTemplate).then(function (response) {
				Notification.success('Successfully save template');
			}, function (response) {
				Notification.error('Failed to save stage template');
			});
		}
		this.restangularizeQuestions = function (stages) {
			//restangularize questions collection
			stages.forEach(function (stage, index) {
				stage.questions = Restangular.restangularizeCollection(stage, stage.questions, 'questions');
				stage.questions.fromServer = true;
				stage.questions.data.forEach(function (question, index) {
					question.fromServer = true;
				});
			});
		}
		this.newStageTemplate = function (company) {
			var stageTemplate = {
				name: '',
				description: '',
				company_id: company.id,
				stages: {
					data: []
				},
			}
			stageTemplate = Restangular.restangularizeElement(null, stageTemplate, 'stage-templates');
			return stageTemplate;
		}
		this.deleteStageTemplate = function (stageTemplate) {
			var modalInstance = DialogService.confirm('Are you sure you want to delete this stage template?', 'Delete Stage Template');
			modalInstance.then(function () {
				stageTemplate.remove().then(function (response) {
					Notification.success('Successfully remove stage template');
					$state.go('manage.stages');
				}, function (response) {
					Notification.error('Failed to remove stage template');
				})
			})
		}
	}])