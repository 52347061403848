angular.module('hrmnext.recruitment.manage.position.show', [
	'hrmnext.recruitment.manage.position.applications',
	'hrmnext.recruitment.manage.position.edit',
	'hrmnext.recruitment.manage.position.stage',
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.showPosition', {
			url: '/positions/:id',
			templateUrl: 'app/manage/position/show/show.html',
			controller: 'ShowPositionCtrl',
			resolve: {
				position: function (Restangular, $stateParams) {
					return Restangular.one('positions', $stateParams.id).get({ include: 'company,address,skills,address,educations,experiences' });
				},
				position_id: function ($stateParams) {
					return $stateParams.id;
				}
			}
		});
	}])
	.controller('ShowPositionCtrl', ['$scope', 'position', '$state', '$rootScope', function ($scope, position, $state, $rootScope) {
		$scope.position = position;
		$scope.init = function () {
			$scope.go('manage.showPosition.applications');
		}
		$scope.tabs = [{
			heading: 'Applications',
			state: 'manage.showPosition.applications',
			active: $state.is('manage.showPosition.applications')
		}, {
			heading: 'Edit',
			state: 'manage.showPosition.edit',
			active: $state.is('manage.showPosition.edit')
		}, {
			heading: 'Stages',
			state: 'manage.showPosition.stage',
			active: $state.is('manage.showPosition.stage')
		}];

		$scope.active = function (state) {
			return $state.is(state);
		};

		$scope.$on('$stateChangeSuccess', function () {
			$scope.tabs.forEach(function (tab) {
				tab.active = $scope.active(tab.state);
			});
		});

		$scope.go = function (state) {
			$state.go(state);
		};

		$scope.positionPageChanged = function () {

		}
		$scope.parseDate = function (dateString) {
			return new Date(dateString);
		}
	}]);