angular.module('hrmnext.manage.users.components.reset-password', ['ui.bootstrap'])
.controller('ResetPasswordCtrl', ['$scope','$uibModalInstance', function($scope,$uibModalInstance){

	$scope.password = {
		password : '',
		password_confirmation: ''
	}
	$scope.ok = function(){
		if($scope.password.password == $scope.password.password_confirmation && $scope.password.password != ""){
			$uibModalInstance.close($scope.password);
		}
	}

	$scope.cancel= function(){
		$uibModalInstance.dismiss();
	}
}])