angular.module('hrmnext.recruitment.services').factory('flash', [
'$rootScope', 
'$timeout', 
function($rootScope,$timeout){
	var _queue = [];
	var _currentMessage = {};
	$rootScope.$on('$viewContentLoaded', function(){
		_currentMessage = _queue.shift() || {};
		if(angular.isDefined(_currentMessage.duration)){
			$timeout(function(){
				_currentMessage = {};
			},_currentMessage.duration);
		}
	});
	return {
		addMessage : function(message,type,duration){
			_queue.push({
				message : message,
				type : type,
				duration : duration
			});
		},
		setMessage : function(message,type,duration){
			_currentMessage = {
				message : message,
				type : type,
				duration : duration
			};
			if(angular.isDefined(_currentMessage.duration)){
				$timeout(function(){
					_currentMessage = {};	
				},_currentMessage.duration);
			}		
		},
		clearMessage : function(){
			_currentMessage = {};
		},
		getMessage : function(){
			return _currentMessage;
		},
		haveMessage : function(){
			return !_.isEmpty(_currentMessage);
		}
	};

}]);