angular.module('hrmnext.recruitment.managae.stages.interview', [])
.controller('StageInterviewCtrl', ['$scope','candidate','$uibModalInstance','interview','interviewers',function($scope,candidate,$uibModalInstance,interview,interviewers){
	$scope.candidate = candidate;
	$scope.interview = interview;
	$scope.interviewers = JSON.parse(JSON.stringify(interviewers))
	$scope.selectedInterviewers = [];

	$scope.interview.participants.data.forEach( function(participant, index) {
		interviewers.find(function(interviewer){
			if(participant.user_id == interviewer.user.id){
				interviewer.lead = participant.lead;
				$scope.selectedInterviewers.push(interviewer);
				return true;
			}
		});
	});

	$scope.onInterviewerSelected = function(interviewer, model){
		$scope.selectedInterviewers.push(interviewer);
		$scope.interview.participants.data.push({
			user_id : interviewer.user.id,
			interview_id : interview.id,
			participation_confirmed : false,
			type : 'Interviewer',
			lead: false,
		});
	}

	$scope.onInterviewerRemoved = function(interviewer, model){
		$scope.selectedInterviewers.splice($scope.selectedInterviewers.indexOf(interviewer))
		$scope.interview.participants.data.find(function(participant,index){

			if(participant.user_id==interviewer.user.id){
				if(participant.id){
					participant.deleted = true;
				}else{
					$scope.interview.participants.data.splice(index);
				}
			}
		});
	}

	if(typeof $scope.interview.start_at  == "string"){
		$scope.interview.start_at = moment($scope.interview.start_at).toDate();
		$scope.interview.end_at = moment($scope.interview.end_at).toDate();
	}

	$scope.close = function(){

		$uibModalInstance.dismiss();
	}
	$scope.ok = function(){
		$scope.interviewForm.$setSubmitted();
		if($scope.interviewForm.$valid){
			$uibModalInstance.close($scope.interview );
		}
	}
	$scope.updateSelectedInterviewers = function(){

	}

	var validateDates = function(){
		if(!$scope.interview) {return false};
		if(angular.isUndefined($scope.interviewForm.start_date.$error.date)){
			$scope.interviewForm.start_date.$setValidity('endBeforeStart',$scope.interview.end_at >  $scope.interview.start_at )
		}
	}
	$scope.$watch('interview.start_at', validateDates);
	$scope.$watch('interview.end_at', validateDates);

	$scope.startDateUpdate = function(){
		//set the end_at date component to be the same as the start date
		var hours = $scope.interview.end_at.getHours();
		var minutes = $scope.interview.end_at.getMinutes();
		var seconds = $scope.interview.end_at.getSeconds();
		var year = $scope.interview.start_at.getFullYear();
		var month = $scope.interview.start_at.getMonth();
		var date = $scope.interview.start_at.getDate();

		$scope.interview.end_at = new Date(year,month,date,hours,minutes,seconds);
	};

	$scope.updateLead = function(interviewer){
	 	$scope.interview.participants.data.forEach(function(participant){
			if(participant.user_id == interviewer.user_id){
				participant.lead = interviewer.lead==true ? true : false
			}
			return participant.user_id == interviewer.user_id;
		});
	}
}])
