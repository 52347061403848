angular.module('hrmnext.recruitment.app.services.dialogService', [])
.factory('DialogService', ['$q','$uibModal', function($q,$uibModal){
	return {
		confirm : function(message, title){
			var deferred = $q.defer();
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/services/dialogService/partials/confirmDialog.html',
				controller: 'ConfirmDialogCtrl',
				size: 'md',
				resolve :{
					message : function(){
						return message;
					},
					title :function(){
						return title;
					}
				}	
		    });
		    return modalInstance.result;
		},
		notify : function(message, title){
			var deferred = $q.defer();
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/services/dialogService/partials/notifyDialog.html',
				controller: 'NotifyDialogCtrl',
				size: 'md',
				resolve :{
					message : function(){
						return message;
					},
					title :function(){
						return title;
					}
				}	
		    });
		    return modalInstance.result;
		}
	};
}])