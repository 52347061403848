angular.module('hrmnext.recruitment.manage.invitations.index.controller', ['restangular'])
    .config(function ($stateProvider) {
        $stateProvider.state('manage.invitations.index', {
            url: '',
            controller: 'IndexInterviewManagerCtrl',
            templateUrl: 'app/manage/invitations/index/index-invitation.html',
            resolve: {
                invitations: function (UserInvitation, $rootScope) {
                    return UserInvitation($rootScope.authUser.id).getList();
                },
                invitationPurposes: function (Restangular) {
                    return Restangular.all('invitation_purposes').getList();
                },
                invitationStatus: function (Restangular) {
                    return Restangular.all('invitation_status').getList();
                }
            },
            access: {
                requiresLogin: true,
                requirePermissions: ['view_invitations']
            }
        });
    })

    .controller('IndexInterviewManagerCtrl', function ($scope, invitations, DialogService, invitationStatus, invitationPurposes, Notification) {
        $scope.invitations = invitations;
        $scope.invitationStatus = Object.assign([], invitationStatus);
        $scope.invitationPurposes = invitationPurposes;

        $scope.invitationStatus.unshift({
            name: 'All',
            slug: 'all'
        });

        $scope.invitationPurposes.unshift({
            name: 'All',
            slug: 'all'
        });

        $scope.filter = {
            status: 'all',
            purpose: 'all'
        }
        $scope.invitationPageChanged = function () {
            $scope.filter.page = $scope.invitations.meta.pagination.current_page;
            $scope.filterUpdated();
        }

        $scope.acceptInvitation = function (invitation) {
            DialogService.confirm('Are you sure you want to accept this invitation?', 'Accept Invitation')
                .then(function () {
                    invitation.invitation_status_id = invitationStatus.find(function (status) {
                        return status.slug == 'accepted';
                    }).id;
                    invitation.save().then(function (response) {
                        Notification.success("Successfully accepted invitation");
                        $scope.invitations[$scope.invitations.indexOf(invitation)] = response;
                    }, function (response) {

                        Notification.error("Failed to accepted invitation");
                    });;
                })
        }
        $scope.rejectInvitation = function (invitation) {
            var message = 'Are you sure you want to reject this invitation?';
            if (invitation.status != 'Waiting') {
                message += '\nRejecting an invitation that has already been accepted will revert all the changes';
            }

            DialogService.confirm(message, 'Reject Invitation')
                .then(function () {
                    console.log(invitationStatus);
                    invitation.invitation_status_id = invitationStatus.find(function (status) {
                        return status.slug == 'rejected';
                    }).id;
                    invitation.save().then(function (response) {
                        Notification.success("Successfully rejected invitation");
                        $scope.invitations[$scope.invitations.indexOf(invitation)] = response;
                    }, function (response) {

                        Notification.error("Failed to rejected invitation");
                    });
                })
        }

        $scope.filterUpdated = function () {
            $scope.invitations.getList($scope.filter)
                .then(function (data) {
                    $scope.invitations = data;
                });
        }


    })