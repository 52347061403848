angular.module('hrmnext.recruitment.profile.picture',[])
.controller('ProfilePictureCtrl',[
'Restangular','$uibModalInstance','$scope', 'user','$timeout','helpers',
function(Restangular, $uibModalInstance,$scope, user,$timeout,helpers){
	$scope.user = {};
	angular.copy(user, $scope.user);
	$scope.picture = {
		image : $scope.user.profile_picture,
		croppedImage : '',
	}

	$scope.ok = function(){
		$scope.profilePictureForm.$setSubmitted();

		if($scope.profilePictureForm.$valid){
			///convert the croped image data url to blob
			var blob = helpers.dataURItoBlob($scope.picture.croppedImage);
			var fd = new FormData();
			fd.append('picture',blob);

			//upload the file
			$scope.user.one('picture')
			.withHttpConfig({ transformRequest: angular.identity })
			.customPOST(fd, '',undefined, {'Content-Type': undefined })
			.then(function(response){
				$uibModalInstance.close(response);
			}, function(response){
				$scope.response = response;
			});
		}
	}

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}

	var handleFileSelect=function(evt) {
		if(evt.currentTarget.files.length>0){
			var file=evt.currentTarget.files[0];
			var reader = new FileReader();
			reader.onload = function (evt) {
				$scope.$apply(function($scope){
					$scope.picture.image=evt.target.result;
				});
			};
			reader.readAsDataURL(file);
		}		
	};
	$timeout(function(){
		angular.element(document.querySelector('#picture_input')).on('change',handleFileSelect);
	});
}])