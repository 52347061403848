angular.module('hrmenxt.recruitment.directives.access', [
	'hrmnext.recruitment.services.authorization',
	'hrmnext.recruitment.constants'
])
	.directive('access', ['authorization', 'constants', function (authorization, constants) {
		// Runs during compile
		return {
			// name: '',
			// priority: 1,
			// terminal: true,
			// scope: {}, // {} = isolate, true = child, false/undefined = no change
			// controller: function($scope, $element, $attrs, $transclude) {},
			// require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
			restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
			// template: '',
			// templateUrl: '',
			// replace: true,
			// transclude: true,
			// compile: function(tElement, tAttrs, function transclude(function(scope, cloneLinkingFn){ return function linking(scope, elm, attrs){}})),
			link: function ($scope, iElm, iAttrs, controller) {
				var makeVisible = function () {
					iElm.removeClass('hidden');
				}
				var makeHidden = function () {
					iElm.addClass('hidden');
				}
				var determineVisibility = function (resetFirst) {
					var authorised;

					if (resetFirst) {
						makeVisible();
					}
					authorised = authorization.authorize({
						requiresLogin: true,
						requirePermissions: requirePermissions,
						permissionType: iAttrs.accessPermissionType,
						requiresLogout: iAttrs.accessRequiresLogout,
						requireRoles: requireRoles,
					});

					if (authorised === constants.AUTHORIZED) {
						makeVisible();
					} else {
						makeHidden();
					}
				}
				var requirePermissions = iAttrs.access.split(',').filter(function (string) { return string != "" });
				var requireRoles = iAttrs.accessRequireRoles !== undefined ? iAttrs.accessRequireRoles.split(',').filter(function (string) { return string != "" }) : [];
				if (requirePermissions.length > 0 || requireRoles.length > 0) {
					determineVisibility(true);
				}
			}
		};
	}]);