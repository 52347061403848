angular.module('hrmnext.recruitment.profile')
.controller('EmploymentHistoryCtrl',['$scope','Restangular','$uibModalInstance','currencies','employmentHistory','employmentHistories','user_id','Notification',
	function($scope,Restangular,$uibModalInstance, currencies,employmentHistory,employmentHistories,user_id,Notification){
		$scope.currencies = currencies;
		$scope.employmentHistory = Object.assign({},employmentHistory);

		$scope.employmentHistory.start_date = moment($scope.employmentHistory.start_date).toDate();
		$scope.employmentHistory.end_date = moment($scope.employmentHistory.end_date).toDate();

		if($scope.employmentHistory.currency_id == undefined ){
			$scope.employmentHistory.currency_id = currencies.find(function(currency){
				return currency.code=="JMD";
			}).id;
		}
		$scope.saveUserEducation = function(){
			var promise;
			if(angular.isUndefined($scope.employmentHistory.id)){
				promise = Restangular.one('users',user_id)
					.all('employment_histories')
					.customPOST($scope.employmentHistory)
					.then(function(response){
						employmentHistories.push(response);
						Notification.success('Your employment history successfully created');
					});
			}else{
				promise = Restangular.one('users',user_id)
					.one('employment_histories',$scope.employmentHistory.id)
					.customPUT($scope.employmentHistory)
					.then(function(response){
						employmentHistories[employmentHistories.indexOf(employmentHistory)] = response;
						Notification.success('Your employment history successfully updated');
					});
			}
			promise.then(function(response){
				$uibModalInstance.close(response);
			},function(response){
				$scope.response = response;
			});
		}
		$scope.cancel = function(){
			$uibModalInstance.dismiss();
		}

		$scope.getSelectedCurrency = function(currency_id){
			return currencies.find(function(currency){
				return currency.id==currency_id;
			});
		}
	}
]);