angular.module('hrmnext.recruitment.manage.feedbacks.list', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.feedbacks.list',{
		url: '',
		templateUrl : 'app/manage/feedbacks/list/list.html',
		controller : 'FeedbackController',
		access : {
			requiresRoles : ['admin']
		},
		resolve : {
			feedbacks : function(Restangular){
				return Restangular.all('feedbacks').getList();
			}
		},
	})
}])
.controller('FeedbackController', ['$scope','Restangular','Notification','feedbacks', function($scope,Restangular,Notification, feedbacks){
	$scope.feedbacks = feedbacks;
}])