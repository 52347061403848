/**
* hrmnext.recruitment.manage.notifications.index Module
*
* Description
*/
angular.module('hrmnext.recruitment.manage.notifications.index', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.notifications.index', {
			url: '',
			templateUrl: 'app/manage/notifications/index.notifications.html',
			controller: 'IndexNotificationCtrl',
			resolve: {
				notifications: function (Restangular) {
					return Restangular.all('notifications').getList({ include: 'user,notifiable' });
				}
			}
		})
	}])
	.controller('IndexNotificationCtrl', ['$scope', 'Notification', 'Restangular', 'notifications', function ($scope, Notification, Restangular, notifications) {
		$scope.notifications = notifications;


		$scope.updateNotifications = function () {

		}
	}])