angular.module('hrmnext.recruitment.home', [
    'ui.router',
    'ui.bootstrap',
    'angular-storage',
    'angular-jwt',
    'hrmnext.recruitment.services',
    'hrmnext.recruitment.home.interview',
    'hrmnext.recruitment.home.interviewer-interview.modal',
])
    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider.state('home', {
            url: '/home',
            templateUrl: 'app/home/home.html',
            controller: 'HomeCtrl',
            access: {
                requiresLogin: true,
                completedProfile: true,
            },
            resolve: {
                positions: ['Restangular', function (Restangular) {
                    return Restangular.all('positions').getList();
                }],
                jobTypes: ['Restangular', function (Restangular) {
                    return Restangular.all('job_types').getList();
                }],
                user: ['Restangular', '$rootScope', function (Restangular, $rootScope) {
                    return Restangular.one('users', $rootScope.authUser.id).get();
                }],
                interviews: function (Restangular, auth) {
                    return Restangular.one('users', auth.getUser().id).all('interviews').getList({ participating: true });
                },
                interviewerInterviews: function (Restangular, authorization, auth, constants) {
                    hasPermission = authorization.authorize({
                        requirePermissions: ['participate_in_interviews']
                    });

                    if (hasPermission == constants.AUTHORIZED) {
                        return Restangular.all('interviews').getList({ include: 'candidate.user,position,invitation', participant_user_id: auth.getUser().id, acceptedInvitation: true });
                    }
                    return [];
                }
            }
        });

    }])

    /**
    *
    * Home Controller Definition
    *
    **/
    .controller('HomeCtrl', function ($scope, $state, Restangular, positions, Notification, jobTypes, user, interviews, $compile, uiCalendarConfig, $uibModal, interviewerInterviews) {
        var initializing = true
        $scope.filters = {};

        $scope.positions = positions;
        $scope.jobTypes = jobTypes;
        $scope.user = user;
        $scope.interviews = interviews;

        $scope.filterPositions = function () {
            Restangular.all('positions').getList($scope.filters).then(function (response) {
                $scope.positions = response;
            }, function (response) {
                Notification.error('Failed to update job listings');
            });
        }
        $scope.$watch('filters', _.debounce(function (newValue, oldValue, scope) {
            if (initializing != true) {
                $scope.filterPositions();
            }
            initializing = false;
        }, 500), true);

        $scope.events = [];
        $scope.interviewerEvents = [];

        interviews.forEach(function (interview, index) {
            $scope.interviewerEvents.push({
                title: 'Interview for position of ' + interview.position,
                start: interview.start_at,
                end: interview.end_at,
                allDay: interview.is_all_day,
                interview: interview,
                role: 'candidate',
            })
        });

        interviewerInterviews.forEach(function (interview, index) {
            $scope.events.push({
                title: interview.name,
                start: interview.start_at,
                end: interview.end_at,
                allDay: interview.is_all_day,
                interview: interview,
                color: "#3a836b",
                role: 'participant'
            });
        });
        $scope.eventSources = [$scope.events, $scope.interviewerEvents];

        /* alert on eventClick */
        $scope.alertOnEventClick = function (event, jsEvent, view) {
            var modalInstance = null;

            if (event.role == 'participant') {
                modalInstance = $uibModal.open({
                    templateUrl: 'app/home/interviewer-interview.modal.html',
                    controller: 'InterviewerInterviewModalCtrl',
                    resolve: {
                        interview: function () {
                            return event.interview;
                        }
                    }
                });
            }

            if (event.role == 'candidate') {
                modalInstance = $uibModal.open({
                    templateUrl: 'app/home/interview.html',
                    controller: 'InterviewPreviewCtrl',
                    resolve: {
                        interview: function () {
                            return event.interview;
                        }
                    }
                });
            }
        };
        /* alert on Drop */
        $scope.alertOnDrop = function (event, delta, revertFunc, jsEvent, ui, view) {
            $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
        };
        /* alert on Resize */
        $scope.alertOnResize = function (event, delta, revertFunc, jsEvent, ui, view) {
            $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
        };

        /* remove event */
        $scope.remove = function (index) {
            $scope.events.splice(index, 1);
        };

        /* Change View */
        $scope.changeView = function (view, calendar) {
            uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
        };

        /* Change View */
        $scope.renderCalender = function (calendar) {
            if (uiCalendarConfig.calendars[calendar]) {
                uiCalendarConfig.calendars[calendar].fullCalendar('render');
            }
        };

        /* Render Tooltip */
        $scope.eventRender = function (event, element, view) {
            element.attr({ 'uib-tooltip': event.title, 'tooltip-append-to-body': true });
            $compile(element)($scope);
        };

        $scope.uiConfig = {
            calendar: {
                height: 450,
                editable: true,
                header: {
                    left: 'title',
                    center: '',
                    right: 'today month,agendaWeek,agendaDay prev,next'
                },
                eventClick: $scope.alertOnEventClick,
                eventDrop: $scope.alertOnDrop,
                eventResize: $scope.alertOnResize,
                eventRender: $scope.eventRender
            }
        };
    });