angular.module('hrmnext.recruitment.manage.position.create', [])
	.controller('CreatePositionCtrl', ['$scope', 'Restangular', '$uibModalInstance', 'companies', 'positionTypes', 'countries', '$q', '$state', 'edcuationPriorities',
		function ($scope, Restangular, $uibModalInstance, companies, positionTypes, countries, $q, $state, edcuationPriorities) {
			$scope.positionTypes = positionTypes;
			$scope.companies = companies;
			$scope.useCompanyAddress = false;
			$scope.countries = countries;
			$scope.awards = [];
			$scope.edcuationPriorities = edcuationPriorities;

			$scope.tabs = [
				{
					active: true,
					heading: '1. General',
					disabled: false
				}, {
					active: false,
					heading: '2. Address',
					disabled: false
				}, {
					active: false,
					heading: '3. Skill',
					disabled: false
				}, {
					active: false,
					heading: '4. Education',
					disabled: false
				}, {
					active: false,
					heading: '5. Experience',
					disabled: false
				}, {
					active: false,
					heading: '6. Stages',
					disabled: false
				},
			];
			$scope.position = {
				title: '',
				classification: '',
				company_id: companies[0].id,
				date_open: new Date(),
				date_close: new Date(),
				description: '',
				position_type_id: positionTypes[0].id,
				address: {},
				skills: [],
				educations: [],
				experiences: [],
				stages: [],
				send_acceptance_email: companies[0].send_acceptance_email,
				send_rejection_email: companies[0].send_rejection_email
			}
			$scope.updateBranches = function () {
				Restangular.one('companies', $scope.position.company_id).all('branches').getList().then(function (branches) {
					$scope.branches = [];
					if (branches.length > 0) {
						$scope.branches = branches;
						$scope.position.branch_id = branches[0].id;
					}
				});
			}
			$scope.updateDepartment = function () {
				Restangular.one('companies', $scope.position.company_id).all('departments').getList({ branch_id: $scope.position.branch_id }).then(function (departments) {
					$scope.departments = [];
					if (departments.length > 0) {
						$scope.departments = departments;
						$scope.position.department_id = departments[0].id;
					}
				});
			}


			$scope.search = function (resourceName, searchQuery) {
				return Restangular.all(resourceName).getList({ 'name': searchQuery })
					.then(function (response) {
						return response.map(function (response) {
							return response;
						});
					});
			}

			$scope.cancel = function () {
				$uibModalInstance.dismiss();
			}
			$scope.tabSelected = function (tabNumber) {

			}
			$scope.next = function () {
				for (var i = 0; i < $scope.tabs.length; i++) {
					if ($scope.tabs[i].active) {
						$scope.tabs[i].active = false;
						$scope.tabs[i + 1].active = true;
						break;
					}
				};
			}
			$scope.previous = function () {
				for (var i = 0; i < $scope.tabs.length; i++) {
					if ($scope.tabs[i].active) {
						$scope.tabs[i].active = false;
						$scope.tabs[i - 1].active = true;
						break;
					}
				};
			}
			//Save the created position
			$scope.createPosition = function () {
				Restangular.all('positions').post($scope.position)
					.then(function (position) {
						$uibModalInstance.close(position);
						$state.go('manage.showPosition.edit', { id: position.id })
					}, function (response) {
						$scope.response = response;
						$scope.displayFormError = true;
					});
			}
			$scope.addSkill = function () {
				$scope.position.skills.push({
					name: '',
					proficiency_level: 0,
					years_of_experience: 0,
					description: '',
				});
			}
			$scope.sliderOptions = {
				range: "min"
			}
			$scope.removeSkill = function (skill) {
				$scope.position.skills.splice($scope.position.skills.indexOf(skill), 1);
			}

			$scope.addEducation = function () {
				$scope.position.educations.push({
					name: '',
					awards: [],
					description: '',
				});
			}
			$scope.refreshAwards = function (query) {
				$scope.awards = [];
				return $scope.search('awards', query).then(function (awards) {
					awards.push({ name: query, id: null, description: '' });
					$scope.awards = awards.map(function (award) {
						return { name: award.name, id: award.id, description: award.description };
					});
				}, function () {
					$scope.awards = [{ name: query, id: null, description: '' }];
				});
			}
			$scope.addExperience = function () {
				$scope.position.experiences.push({
					name: '',
					description: '',
					id: null,
					years_of_service: 0,
				});
			}
			$scope.addQuestion = function (stage) {
				var openedQuestion = stage.questions.find(function (question) {
					return question.isOpen == true;
				});
				if (openedQuestion) {
					openedQuestion.isOpen = false;
				}
				stage.questions.push({
					name: '',
					order: stage.questions.length,
					description: '',
					weight: 0,
					score: 0,
					comment: '',
					isOpen: true,
				});
			}

			$scope.addStage = function () {
				var openedStage = $scope.position.stages.find(function (stage) {
					return stage.isOpen == true;
				});
				if (openedStage) {
					openedStage.isOpen = false;
				}
				$scope.position.stages.push({
					name: '',
					description: '',
					order: $scope.position.stages.length,
					template_stage_id: null,
					questions: [],
					candidates: [],
					isOpen: true,
				});
			}
			$scope.isHRMNextCompany = function (companyId) {
				var company = $scope.companies.find(function (company) {
					return company.id == companyId;
				});

				if (company !== undefined) {
					return company.hrmnext == true;
				}
				return false;
			}
			$scope.$watch('position.branch_id', function (branch_id) {
				$scope.updateDepartment();
			});

			$scope.updateSendAcceptanceEmail = function () {
				var selectedCompany = $scope.companies.find(function (company) {
					return company.id == $scope.position.company_id;
				});
				$scope.position.send_acceptance_email = selectedCompany.send_acceptance_email;
			}

			$scope.updateSendRejectionEmail = function () {
				var selectedCompany = $scope.companies.find(function (company) {
					return company.id == $scope.position.company_id;
				});
				$scope.position.send_rejection_email = selectedCompany.send_rejection_email;
			}

			$scope.$watch('position.company_id', function (company_id) {
				$scope.updateBranches();
				$scope.updateDepartment();
				$scope.updateSendAcceptanceEmail();
				$scope.updateSendRejectionEmail();

				Restangular.all('stage-templates').getList({ 'company_id': company_id }).then(function (stageTemplates) {
					$scope.stageTemplates = stageTemplates;
					$scope.position.stages = [];
					if (stageTemplates.length > 0) {
						$scope.position.stages = stageTemplates[0].stages;

						$scope.position.stages.data.forEach(function (stage) {
							stage.template_stage_id = stage.id;

							delete stage.id;
							delete stage.updated_at;
							if (stage.questions != undefined) {
								stage.questions.forEach(function (question) {
									delete question.id;
								});
							}
						});
					}
				});
			});
		}]);