angular.module('hrmnext.recruitment.manage.position.applications.details',[])
.controller('ApplicationDetailCtrl',function($scope, application,$uibModalInstance,applicationStatus,Restangular,Notification){
	$scope.application = {};
	angular.copy(application, $scope.application);
	$scope.applicationStatus = applicationStatus;

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}
	$scope.statusUpdated = function(){
		switch($scope.application.status){
			case 'Rejected':
				$scope.application.rejected_on = new Date;
				$scope.application.accepted_on = null;
				break;
			case 'Accepted':				
				$scope.application.accepted_on = new Date;
				$scope.application.reject_reason = '';
				$scope.application.rejected_on = null;
				break;
			case 'Processing':
				$scope.application.accepted_on = null;
				$scope.application.reject_reason = '';
				$scope.application.rejected_on = null;
				break;
		}
	}

	$scope.save = function(){
		Restangular.one('applications',$scope.application.id).customPUT($scope.application).then(function(response){
			$scope.application = response;
			application = response;
			Notification.success('Application updated successfully');
			$scope.edit = false;
			$uibModalInstance.close(response);
		},function(response){
			Notification.error('Failed to update application');
			$scope.response = response;
		});		
	}
});