angular.module('hrmnext.recruitment.profile', [
		'hrmnext.recruitment.profile.messages',
		'hrmnext.recruitment.profile.personal-details',
		'hrmnext.recruitment.profile.settings',
		'hrmnext.recruitment.profile.picture',
		'hrmnext.recruitment.profile.phoneNumber',
		'hrmnext.recruitment.profile.interviews',
		'hrmnext.recruitment.profile.resume.modal',
		'hrmnext.recruitment.profile.invitations'
	])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('profile', {
				abstract: true,
				url: '/profile',
				templateUrl: 'app/profile/profile.html',
				controller: 'ProfileCtrl',
				data: {
					autoActivateChild: 'profile.details',
				},
				resolve: {
					user: ['Restangular', '$rootScope', function (Restangular, $rootScope) {
						return Restangular.one('users', $rootScope.authUser.id).get({
							include: 'phoneNumbers,addresses,educations,employmentHistories,skills,hobbies,applications,resumes'
						});
					}],
					countries: ['Restangular', function (Restangular) {
						return Restangular.all('countries').getList();
					}],
					phoneNumberTypes: ['Restangular', function (Restangular) {
						return Restangular.all('phone_number_types').getList();
					}],
					addressTypes: ['Restangular', function (Restangular) {
						return Restangular.all('address_types').getList();
					}],
					currencies: function (Restangular) {
						return Restangular.all('currencies').getList();
					},
					profileStatus: function (Restangular, $rootScope) {
						return Restangular.one('profile_status', $rootScope.authUser.id).get();
					}
				}
			})
			.state('profile.details', {
				url: '',
				templateUrl: 'app/profile/details/details.html',
				access: {
					requiresLogin: true,
				},
			})
			.state('profile.settings', {
				url: '/settings',
				controller: 'ProfileSettingsCtrl',
				templateUrl: 'app/profile/settings/settings.html',
				access: {
					requiresLogin: true,
				},
			})
	}])
	/**
	 * Home Controller Definition
	 **/
	.controller('ProfileCtrl', [
		'$scope',
		'flash',
		'$state',
		'store',
		'jwtHelper',
		'$uibModal',
		'Restangular',
		'DialogService',
		'$rootScope',
		'Notification',
		'user',
		'countries',
		'phoneNumberTypes',
		'addressTypes',
		'currencies',
		'profileStatus',
		function ($scope, flash, $state, store, jwtHelper, $uibModal, Restangular, DialogService, $rootScope, Notification, user, countries, phoneNumberTypes, addressTypes, currencies, profileStatus) {
			$scope.flash = flash;
			$scope.userResource = Restangular.one('users', user.id);
			$scope.countries = countries;
			$scope.phoneNumberTypes = phoneNumberTypes;
			$scope.addressTypes = addressTypes;
			$scope.user = user;
			$scope.currencies = currencies;
			$scope.profileStatus = profileStatus;

			$scope.phoneNumbers = user.phoneNumbers.data;
			$scope.addresses = user.addresses.data;
			$scope.educations = user.educations.data;
			$scope.employmentHistories = user.employmentHistories.data;
			$scope.skills = user.skills.data;
			$scope.hobbies = user.hobbies.data;
			$scope.resumes = user.resumes.data;

			//parse dates
			$scope.user.date_of_birth = moment(user.date_of_birth).toDate();

			$scope.uploadImage = function () {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/profile/profilePicture/profilePicture.html',
					controller: 'ProfilePictureCtrl',
					size: 'md',
					backdrop: 'static',
					resolve: {
						user: function () {
							return $scope.user;
						}
					}
				});

				modalInstance.result.then(function (response) {
					$scope.user.profile_picture = response.profile_picture + '?' + new Date().getTime();
					$scope.user.thumbnail = response.thumbnail + '?' + new Date().getTime();
					Notification.success('Profile picture was successfully updated');
					$scope.updateProfileStatus();
				});
			}
			$scope.changePassword = function () {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/profile/changePassword/changePassword.html',
					controller: 'ChangePasswordCtrl',
					size: 'md'
				});

				modalInstance.result.then(function (password) {
					var request = {
						replace: '/password',
						value: password
					}
					Restangular.one('users', $scope.user.id)
						.patch(request)
						.then(function (response) {
							$scope.page.user = response;
							Notification.success('Your password was updated');
							$scope.updateProfileStatus();
						}, function (response) {
							Notification.error('Failed to update your password');
						});
				});
			}
			$scope.deletePhoneNumber = function (phoneNumber) {
				var message = "Are you sure you want to delete this phone number?";
				var title = "Delete Phone Number";

				DialogService.confirm(message, title).then(function () {
					// if the user clicks yes then remove the phone number
					$scope.userResource.one('phone_numbers', phoneNumber.id).remove()
						.then(function () {
							var index = $scope.phoneNumbers.findIndex(function (number) {
								return number.id == phoneNumber.id
							});
							if (index >= 0) {
								$scope.phoneNumbers.splice(index, 1);
							}
							Notification.success('Your phone was number updated');
							$scope.updateProfileStatus();
							//if an error occured while trying to remove a number then flash message
						}, function (response) {
							Notification.error('Failed to remove your phone number');
						});
				});
			}
			$scope.phoneNumberForm = function (phoneNumber) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/profile/phoneNumber/phoneNumber.html',
					controller: 'PhoneNumberCtrl',
					resolve: {
						user: function () {
							return $scope.user
						},
						phoneNumbers: function () {
							return $scope.phoneNumbers;
						},
						phoneNumber: function () {
							return phoneNumber
						},
						phoneNumberTypes: function () {
							return phoneNumberTypes
						},
					}
				});
				modalInstance.result.then(function (phoneNumber) {
					$scope.updateProfileStatus();
				});
			}

			$scope.addressForm = function (address) {

				if (!angular.isDefined(address)) {
					address = {};
				}

				var modalInstance = $uibModal.open({
					templateUrl: 'app/profile/address/address.html',
					controller: 'AddressCtrl',
					resolve: {
						user_id: function () {
							return $scope.user.id;
						},
						address: function () {
							return address;
						},
						addressTypes: function () {
							return addressTypes
						},
						countries: function () {
							return countries;
						},
						addresses: function () {
							return $scope.addresses;
						}
					}
				});

				modalInstance.result.then(function (response) {
					Notification.success('Your address was updated.');
					if (address) {
						$scope.addresses[$scope.addresses.indexOf(address)] = response;
					}
					$scope.updateProfileStatus();
				});
			}
			$scope.deleteAddress = function (address) {
				DialogService.confirm("Are you sure you want to delete this address?", "Delete Address")
					.then(function () {
						$scope.userResource.one('addresses', address.id).remove()
							.then(function () {
								var index = $scope.addresses.findIndex(function (addr) {
									return addr.id == address.id
								});
								if (index >= 0) {
									$scope.addresses.splice(index, 1);
								}
								Notification.success('Your address was removed successfully');
								$scope.updateProfileStatus();

							}, function (response) {
								Notification.error('Failed to update your address');
							});
					});
			}
			$scope.openEducationForm = function (education) {
				if (!angular.isDefined(education)) {
					education = {};
				}
				var modalInstance = $uibModal.open({
					templateUrl: 'app/profile/education/education.html',
					controller: 'EducationCtrl',
					size: 'lg',
					resolve: {
						user_id: function () {
							return $scope.user.id;
						},
						education: function () {
							return education;
						},
						educations: function () {
							return $scope.educations;
						}
					}
				});

				modalInstance.result.then(function (response) {
					// if the response was successful store the updated list of user addresses
					Notification.success('Your education was updated.');
					$scope.updateProfileStatus();
				});

			}
			$scope.deleteEducation = function (education) {
				DialogService.confirm('Are you sure you want to delete this education?', 'Delete Education')
					.then(function () {
						Restangular.one('users', $scope.authUser.id)
							.one('educations', education.id)
							.remove()
							.then(function () {
								//get the updated list
								$scope.educations.splice(education, 1);
								Notification.success('Your education was removed successfully');
								$scope.updateProfileStatus();
							}, function (response) {
								Notification.error('Failed to update your education');
							});
					});
			}
			$scope.openEmploymentHistory = function (employmentHistory) {
				if (angular.isUndefined(employmentHistory)) {
					employmentHistory = {
						start_date: new Date,
						end_date: new Date,
					};
				}
				var modalInstance = $uibModal.open({
					templateUrl: 'app/profile/employmentHistory/employmentHistory.html',
					controller: 'EmploymentHistoryCtrl',
					size: 'lg',
					resolve: {
						user_id: function () {
							return $scope.user.id;
						},
						employmentHistory: function () {
							return employmentHistory;
						},
						employmentHistories: function () {
							return $scope.employmentHistories;
						},
						currencies: function () {
							return $scope.currencies;
						},
					}
				});
				modalInstance.result.then(function (employmentHistories) {
					// if the response was successful store the updated list of user addresses
					$scope.updateProfileStatus();
				});
			}
			$scope.deleteEmploymentHistory = function (employmentHistory) {
				DialogService.confirm('Are you sure you want to delete this employment history?', 'Delete Employment History')
					.then(function () {
						Restangular.one('users', $scope.user.id)
							.one('employment_histories', employmentHistory.id)
							.remove()
							.then(function () {
								$scope.employmentHistories.splice($scope.employmentHistories.indexOf(employmentHistory), 1);
								Notification.success('Successfully removed employment history');
								$scope.updateProfileStatus();
							}, function (response) {
								Notification.error('Failed to remove employment history.');
							});
					});
			}

			$scope.openSkillForm = function (skill) {
				if (angular.isUndefined(skill)) {
					skill = {};
				}
				var modalInstance = $uibModal.open({
					templateUrl: 'app/profile/skill/skill.html',
					controller: 'SkillCtrl',
					size: 'md',
					resolve: {
						skill: function () {
							return skill;
						},
						skills: function () {
							return $scope.skills;
						},
						user_id: function () {
							return $scope.user.id;
						}
					}
				});

				modalInstance.result.then(function (skills) {
					$scope.updateProfileStatus();
				});
			}

			$scope.deleteSkill = function (skill) {
				DialogService.confirm('Are you sure you want to delete this skill?', 'Delete Skill')
					.then(function () {
						Restangular.one('users', $scope.authUser.id)
							.one('skills', skill.id)
							.remove()
							.then(function () {
								$scope.skills.splice($scope.skills.indexOf(skill), 1);
								Notification.success('Your skill was removed successfully.');
								$scope.updateProfileStatus();
							}, function (response) {
								Notification.error('Failed to remove your skill');
							});
					});
			}

			$scope.openHobby = function (hobby) {
				if (angular.isUndefined(hobby)) {
					hobby = {};
				}
				var modalInstance = $uibModal.open({
					templateUrl: 'app/profile/hobby/hobby.html',
					controller: 'HobbyCtrl',
					size: 'md',
					resolve: {
						hobby: function () {
							return hobby;
						},
						hobbies: function () {
							return $scope.hobbies;
						},
						user_id: function () {
							return $scope.user.id;
						}
					}
				});

				modalInstance.result.then(function (hobbies) {
					$scope.updateProfileStatus();
				});
			}

			$scope.deleteHobby = function (hobby) {
				DialogService.confirm('Are you sure you want to delete this hobby?', 'Delete Hobby')
					.then(function () {
						Restangular.one('users', $scope.authUser.id)
							.one('hobbies', hobby.id)
							.remove()
							.then(function () {
								//get the updated list
								$scope.hobbies.splice($scope.hobbies.indexOf(hobby), 1);
								Notification.success('Your hobby was removed successfully');
								$scope.updateProfileStatus();
							}, function (response) {
								Notification.error('Failed to remove your hobby');
							});
					});
			}

			$scope.updateUser = function () {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/profile/personalDetails/personalDetails.html',
					controller: 'PersonalDetailsCtrl',
					size: 'md',
					resolve: {
						user: function () {
							return $scope.user;
						},
						countries: function () {
							return $scope.countries;
						}
					}
				});
				modalInstance.result.then(function (response) {
					$scope.user = response;
					$scope.updateProfileStatus();
				});
			}
			$scope.getSelectedCurrency = function (currency_id) {
				return $scope.currencies.find(function (currency) {
					return currency.id == currency_id;
				});
			}
			$scope.closeProfileStatusAlert = function () {
				$scope.closedProfileStatusAlert = true;
			}
			$scope.updateProfileStatus = function () {
				Restangular.one('profile_status', $rootScope.authUser.id).get().then(function (status) {
					$scope.profileStatus = status;
				});
			}

			/**
			 * Add resume
			 */
			$scope.addResume = function () {
				$uibModal.open({
					templateUrl: 'app/profile/resume/resume.modal.html',
					controller: 'ResumeModalCtrl',
					size: 'md',
					resolve: {
						user: function () {
							return $rootScope.authUser;
						},
						resume: function () {
							return {};
						}
					}
				}).result.then(function (resume) {
					$scope.resumes.push(resume);
					$scope.updateProfileStatus();
				});
			}

			/**
			 * Remove Resume
			 */
			$scope.deleteResume = function (resume) {
				DialogService.confirm('Are you sure you want to delete this resume?', 'Delete Resume')
					.then(function () {
						Restangular.one('users', $scope.user.id)
							.one('resumes', resume.id)
							.remove()
							.then(function () {
								$scope.resumes.splice($scope.resumes.indexOf(resume), 1);
								Notification.success('Successfully removed resume');
								$scope.updateProfileStatus();
							}, function (response) {
								Notification.error('Failed to remove resume');
							});
					});
			}


		}
	]);