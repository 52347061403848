angular.module('hrmnext.recruitment.manage.stages.list', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.stages', {
			url: '/stages',
			templateUrl: 'app/manage/stages/list/list.html',
			controller: 'StageListCtrl',
			access: {
				requirePermissions: ['view_stage_definitions'],
			},
			resolve: {
				stageTemplates: function (Restangular) {
					return Restangular.all('stage-templates').getList({
						include: 'company'
					});
				}
			}
		});
	}])
	.controller('StageListCtrl', function ($scope, stageTemplates, DialogService, Notification) {
		$scope.stageTemplates = stageTemplates;

		$scope.deleteStage = function (stageTemplate) {
			var modalInstance = DialogService.confirm('Are you sure you want to remove this stage template?', 'Delete Stage Template');

			modalInstance.then(function () {
				stageTemplate.remove().then(function () {
					$scope.stageTemplates.getList().then(function (response) {
						$scope.stageTemplates = response;
					});
					Notification.success('Successfully removed stage template');
				}, function (response) {
					Notification.error('Failed to remove stage template')
				})
			})
		}
	});