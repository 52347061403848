angular.module('hrmnext.recruitment.manage.company.index', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.company.index', {
			url: '',
			controller: 'MangeCompanyCtrl',
			templateUrl: 'app/manage/company/index/index.html',
			resolve: {
				companies: ['Restangular', function (Restangular) {
					return Restangular.all('companies').getList({ member: true });
				}]
			},
			access: {
				requiresLogin: true,
				requirePermissions: ['view_company'],
				permissionType: 'atLeastOne'
			},
		})
	}])
	.controller('MangeCompanyCtrl', ['$scope', 'companies', 'Restangular', function ($scope, companies, Restangular) {
		$scope.companies = companies;
	}])