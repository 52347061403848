angular.module('hrmnext.recruitment.manage.interviews',[
	'hrmnext.recruitment.manage.interviews.index',
	'hrmnext.recruitment.manage.interviews.show'
])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.interviews',{
		abstract :true,
		url : '/interviews',
		template : '<ui-view></ui-view>',
	});
}]);