angular.module('hrmnext.recruitment.manage.feedbacks', [
	'hrmnext.recruitment.manage.feedbacks.list',
	'hrmnext.recruitment.manage.feedbacks.detail'
])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.feedbacks',{
		abstract: true,
		url : '/feedbacks',
		template: '<ui-view></ui-view>',
	})
}])