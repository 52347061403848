angular.module('hrmnext.recruitment.directives.birthday-validator', [])
.directive('birthdayValidator', [function(){
	// Runs during compile
	return {
		// name: '',
		// priority: 1,
		// terminal: true,
		// scope: {}, // {} = isolate, true = child, false/undefined = no change
		// controller: function($scope, $element, $attrs, $transclude) {},
		require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
		// template: '',
		// templateUrl: '',
		// replace: true,
		// transclude: true,
		// compile: function(tElement, tAttrs, function transclude(function(scope, cloneLinkingFn){ return function linking(scope, elm, attrs){}})),
		link: function($scope, iElm, iAttrs, controller) {
			controller.$validators.birthday = function(modelValue, viewValue){
				var currentDate= new Date;
				if(controller.$isEmpty(modelValue) || modelValue<currentDate){
					return true;
				}
				return false;
			}
		}
	};
}]);