angular.module('hrmnext.recruitment.formValidationService', [])
	.service('formValidationService', ['Notification', function (Notification) {
		this.serverError = function (form, response) {
			var formErrors = {};
			if (response.status == 422) {
				_.each(response.data.errors, function (errors, field) {
					form[field].$setValidity('server', false);
					formErrors[field] = errors.join(',');
				});
			} else {
				Notification.error('An error has occurred.');
			}
			return formErrors;
		}
	}])