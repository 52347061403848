angular.module('hrmnext.recruitment.manage.audit.index', [])
.config(function($stateProvider){
    $stateProvider.state('manage.audit',{
        url: '/audits',
        templateUrl: 'app/manage/audit/index.audit.html',
        controller: 'AuditController',
        access : {
			requireRoles : ['admin']
		},
        resolve : {
            audits : function(Restangular){
                return Restangular.all('audits').getList({include : 'user'});
            }
        },
    })
})
.controller('AuditController', function($scope, Restangular, audits){
    $scope.audits = audits;

    $scope.filters = {
        include:'user',
    };

    $scope.auditUpdate = function(){
        $scope.filters.page = $scope.audits.meta.pagination.current_page;
        Restangular.all('audits').getList($scope.filters).then(function(audits){
            $scope.audits = audits;
        });
    }
});
