angular.module('hrmnext.recruitment.manage.permission', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.permission',{
		url : '/permissions',
		templateUrl : 'app/manage/permission/permission.html',
		controller : 'PermissionCtrl',
		access : {
			requireRoles : ['admin']
		},
		resolve : {
			permissions : function(Restangular){
				return Restangular.all('permissions').getList();
			}
		}
	});
}])
.controller('PermissionCtrl',['$scope','Restangular', 'Notification','permissions', 
function($scope, Restangular, Notification,permissions){
	$scope.permissions = permissions;
	
}])