angular.module('hrmnext.recruitment.profile.phoneNumber', [])
	.controller('PhoneNumberCtrl', ['$scope', '$uibModalInstance', 'Restangular', 'user', 'phoneNumber', 'phoneNumberTypes', 'Notification', 'formValidationService', 'phoneNumbers',
		function ($scope, $uibModalInstance, Restangular, user, phoneNumber, phoneNumberTypes, Notification, formValidationService, phoneNumbers) {
			$scope.phoneNumber = {};
			$scope.phoneNumberValidation = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
			angular.copy(phoneNumber, $scope.phoneNumber);
			$scope.phoneNumberTypes = phoneNumberTypes;

			if (angular.isDefined($scope.phoneNumber.phone_number_type_id) == false) {
				$scope.phoneNumber.phone_number_type_id = phoneNumberTypes[0].id;
			}
			$scope.ok = function () {
				debugger;
				$scope.form.$setSubmitted();
				if ($scope.form.$valid) {
					if ($scope.phoneNumber.id === undefined || $scope.phoneNumber.id == 0) {
						$scope.saveNewNumber();
					} else {
						$scope.saveNumber();
					}
				}
			}
			$scope.saveNewNumber = function () {
				user.one('phone_numbers').customPOST($scope.phoneNumber).then(function (response) {
					if (response.primary == true) {
						for (var i = 0; i < phoneNumbers.length; i++) {
							phoneNumbers[i].primary = false;
						}
					}
					phoneNumbers.push(response);

					Notification.success('Your phone number was added successfully.');
					$uibModalInstance.close(response);
				}, function (response) {
					$scope.response = response.data;
					if (response.data.status_code != 422) {
						Notification.error('Failed to update phone number');
					}
				});
			}
			$scope.saveNumber = function () {
				user.one('phone_numbers', $scope.phoneNumber.id).customPUT($scope.phoneNumber).then(function (response) {
					if (response.primary == true) {
						for (var i = 0; i < phoneNumbers.length; i++) {
							phoneNumbers[i].primary = false;
						}
					}
					phoneNumbers[_.findLastIndex(phoneNumbers, { id: response.id })] = response;

					Notification.success('Your phone number was updated successfully.');
					$uibModalInstance.close(response);
				}, function (response) {
					$scope.response = response.data;
					if (response.data.status_code != 422) {
						Notification.error('Failed to update phone number');
					}
				});
			}
			$scope.cancel = function (event) {
				event.preventDefault();
				$uibModalInstance.dismiss();
			}
		}])