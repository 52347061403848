angular.module('hrmnext.recruitment.manage.position.stages.services.positionstage',[])
.service('positionStageService', [ 'Restangular', '$q',function(Restangular,$q){
	this.buildStageFromTemplate = function(stageTemplate){
		var stage = Restangular.stripRestangular(stageTemplate);
		stage.template_stage_id = stage.id;

		delete stage.id;
		delete stage.updated_at;

		if(angular.isDefined(stage.questions)){
			stage.questions.forEach(function(question, index){
				delete question.id;
			});			
		}
		stage = Restangular.restangularizeElement(Restangular.one('positions',position_id), stage,'stages');
		return stage;
	}

	this.saveStage = function(stage,promises){
		//determine if the save is PUT or a POST
		if(stage.fromServer){
			stage = Restangular.restangularizeElement(null, stage,'stages');
			stage.fromServer = true;
		}
		if(stage.deleted==true){
			return stage.remove();
		}else{
			return stage.save().then(function(response){
				//save stage questions
				if(angular.isDefined(stage.questions)){
					stage.questions.forEach(function(question){
						question = Restangular.restangularizeElement(Restangular.one('stages',response.id),question,'questions');
						promises[promises.length] = this.saveStageQuestion(question,promises);
					}.bind(this));					
				}

			}.bind(this));
		}
	}

	this.saveStageQuestion = function(question,promises){
		if(question.deleted){
			return question.remove();
		}else{
			return question.save();
		}
	}
}]);