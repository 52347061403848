angular.module('hrmnext.recruitment.manage.position.edit', [
		'rootsfolio.manage.position.reopen'
	])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.showPosition.edit', {
			url: '/edit',
			templateUrl: 'app/manage/position/edit/edit.html',
			controller: 'EditPositionCtrl',
			access: {
				requirePermissions: ['update_position']
			},
			resolve: {
				companies: ['Restangular', '$rootScope', function (Restangular, $rootScope) {
					return Restangular.all('companies').getList({
						manage: true
					});
				}],
				countries: ['Restangular', function (Restangular) {
					return Restangular.all('countries').getList();
				}],
				branches: function (Restangular, position) {
					return Restangular.one('companies', position.company_id).all('branches').getList();
				},
				departments: function (Restangular, position) {
					return Restangular.one('companies', position.company_id).all('departments').getList({
						branch_id: position.branch_id
					});
				},
				edcuationPriorities: function (Restangular) {
					return Restangular.all('education_priorities').getList();
				}
			}
		});
	}])
	.controller('EditPositionCtrl', ['$scope', 'companies', 'countries', '$state', 'positionService', 'Restangular', 'Notification', '$q', 'DialogService', 'branches', 'departments', 'edcuationPriorities', '$uibModal',
		function ($scope, companies, countries, $state, positionService, Restangular, Notification, $q, DialogService, branches, departments, edcuationPriorities, $uibModal) {
			$scope.companies = companies;
			$scope.countries = countries;
			$scope.sliderOptions = {
				range: "min"
			};
			$scope.positionService = positionService;
			$scope.awards = [];
			$scope.addressIsOpen = true;
			$scope.branches = branches;
			$scope.departments = departments;
			$scope.edcuationPriorities = edcuationPriorities;

			$scope.position.date_open = moment($scope.position.date_open).toDate();
			$scope.position.date_close = moment($scope.position.date_close).toDate();
			$scope.tinymceOptions = {
				onChange: function (e) {
					// put logic here for keypress and cut/paste changes
				},
				theme: 'modern',
				plugins: [
					'advlist autolink lists link image charmap print preview hr anchor pagebreak',
					'searchreplace wordcount visualblocks visualchars code fullscreen',
					'insertdatetime media nonbreaking save table contextmenu directionality',
					'emoticons template paste textcolor colorpicker textpattern imagetools',
					'codesample',
				],
				toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
				toolbar2: 'print preview media | forecolor backcolor emoticons codesample',
				image_advtab: true,
			};

			$scope.parseDate = function (dateString) {
				return new Date(dateString);
			}
			$scope.deletePosition = function () {
				var modalInstance = DialogService.confirm("Are you sure you want to remove this position?", "Delete Position");
				modalInstance.then(function () {
					//if the user selected yes 
					$scope.position.remove()
						.then(function (response) {
							Notification.success('The position was successfully deleted');
							$state.go('manage.position');
						}, function (response) {
							Notification.error('Failed to remove position');
						});
				});
			}
			$scope.search = function (resourceName, searchQuery) {
				return Restangular.all(resourceName).getList({
						'name': searchQuery
					})
					.then(function (response) {
						return response.map(function (response) {
							return response;
						});
					});
			}
			$scope.refreshAwards = function (query) {
				$scope.awards = [];
				return $scope.search('awards', query).then(function (awards) {
					awards.push({
						name: query,
						id: null,
						description: ''
					});
					$scope.awards = awards.map(function (award) {
						return {
							name: award.name,
							id: award.id,
							description: award.description
						};
					});
				}, function () {
					$scope.awards = [{
						name: query,
						id: null,
						description: ''
					}];
				});
			}
			$scope.removeSkill = function (skill) {
				skill.deleted = true;
			}
			$scope.addSkill = function () {
				$scope.position.skills.data.push({
					name: '',
					proficiency_level: 0,
					years_of_experience: 0,
					proficiency_level: 0,
					description: 0,
				});
			}
			$scope.addExperience = function () {
				$scope.position.experiences.data.push({
					name: '',
					description: '',
					years_of_service: 0,
				});
			}
			$scope.removeExperience = function (experience) {
				experience.deleted = true;
			}
			$scope.addEducation = function () {
				$scope.position.educations.data.push({
					name: '',
					awards: [],
					description: '',
				});
			}
			$scope.removeEducation = function (education) {
				education.deleted = true;
			}

			$scope.updateBranches = function () {
				Restangular.one('companies', $scope.position.company_id).all('branches').getList().then(function (branches) {
					$scope.branches = branches;
					// $scope.position.branch_id = branches[0].id;
				});
			}
			$scope.updateDepartment = function () {
				Restangular.one('companies', $scope.position.company_id).all('departments').getList({
					branch_id: $scope.position.branch_id
				}).then(function (departments) {
					$scope.departments = departments;
					// $scope.position.department_id = departments[0].id; 
				});
			}
			$scope.updateSendAcceptanceEmail = function () {
				var selectedCompany = $scope.companies.find(function (company) {
					return company.id == $scope.position.company_id;
				});
				$scope.position.send_acceptance_email = selectedCompany.send_acceptance_email;
			}
			$scope.updateSendRejectionEmail = function () {
				var selectedCompany = $scope.companies.find(function (company) {
					return company.id == $scope.position.company_id;
				});
				$scope.position.send_rejection_email = selectedCompany.send_rejection_email;

			}
			$scope.$watch('position.branch_id', function (branch_id) {
				$scope.updateDepartment();
			});
			$scope.$watch('position.company_id', function (company_id, previous_company_id) {
				if (company_id == previous_company_id) {
					$scope.updateSendAcceptanceEmail();
					$scope.updateSendRejectionEmail();
					$scope.updateBranches();
					$scope.updatePosition();
				}
			});

			$scope.getPriority = function (id) {
				return $scope.edcuationPriorities.find(function (priority) {
					return priority.id = id;
				});
			}
			$scope.updatePosition = function () {
				Restangular.one('positions', $scope.position.id).customPUT($scope.position, '', {
					include: 'company,address,skills,address,educations,experiences'
				}).then(function (response) {
					$scope.position = response;
					Notification.success('Successfully updated position');
					$scope.position.date_open = moment($scope.position.date_open).toDate();
					$scope.position.date_close = moment($scope.position.date_close).toDate();

				}, function (response) {
					Notification.error('Failed to update position');
					$scope.response = response;
				});
			}
			$scope.closePosition = function () {
				var modalInstance = DialogService.confirm("<p>Are you sure you want to close this position?</p>", "Close Position");
				modalInstance.then(function () {
					//if the user selected yes 
					$scope.position.is_open = false;
					Restangular.one('positions', $scope.position.id).customPUT($scope.position, '', {
						include: 'company,address,skills,address,educations,experiences'
					}).then(function (response) {
						$scope.position = response;
						Notification.success('Position closed successfully');
						$scope.position.date_open = moment($scope.position.date_open).toDate();
						$scope.position.date_close = moment($scope.position.date_close).toDate();

					}, function (response) {
						Notification.error('Failed to close position');
						$scope.response = response;
					});
				});
			}
			$scope.reOpenPosition = function () {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/manage/position/components/reopenPosition/reopenPosition.html',
					controller: 'ReopenPositionCtrl',
					size: 'md',
					resolve: {
						position: function () {
							return $scope.position;
						},
					}
				}).result.then(function (response) {
					$scope.position = response;
				})
			}
			$scope.archivePosition = function () {

			}

			$scope.canEdit = function () {
				return $scope.position.can.update && $scope.position.is_open;
			}
		}
	]);