angular.module('hrmnext.recruitment.manage.company.show', [
		'hrmnext.recruitment.manage.company.roles',
	])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.company.show', {
			url: '/:id',
			templateUrl: 'app/manage/company/show/show.html',
			controller: 'ShowCompanyCtrl',
			resolve: {
				company: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
					return Restangular.one('companies', $stateParams.id).get();
				}],
				countries: ['Restangular', function (Restangular) {
					return Restangular.all('countries').getList();
				}],
				members: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
					return Restangular.one('companies', $stateParams.id).all('members').getList({
						include: 'invitation'
					});
				}],
				roles: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
					return Restangular.one('companies', $stateParams.id).all('roles').getList();
				}],
				branches: function (Restangular, $stateParams) {
					return Restangular.one('companies', $stateParams.id).all('branches').getList();
				},
				departments: function (Restangular, $stateParams) {
					return Restangular.one('companies', $stateParams.id).all('departments').getList();
				}
			}
		})
	}])
	.controller('ShowCompanyCtrl', ['$scope', 'company', 'countries', 'Notification', '$uibModal', 'Restangular', 'members', 'DialogService', 'roles', 'branches', 'departments',
		function ($scope, company, countries, Notification, $uibModal, Restangular, members, DialogService, roles, branches, departments) {
			$scope.company = company;
			$scope.countries = countries;
			$scope.members = members;
			$scope.roles = roles;
			$scope.hrmnextCompanies = [];
			$scope.branches = branches;
			if ($scope.branches.length > 0) {
				$scope.branches[0].isOpen = true;
			}
			$scope.departments = departments;

			var userIDs = [];
			$scope.users = {};
			$scope.members.forEach(function (member, index) {
				userIDs.push(member.user_id);
			});
			Restangular.all('users').getList({
				'ids[]': userIDs
			}).then(function (users) {
				users.forEach(function (user) {
					$scope.users[user.id] = user;
				});
			});
			$scope.hrmnext_connection = {
				company_id: $scope.company.id,
				ip: '',
				port: '',
				password: '',
				wsdl: '',
				username: '',
				hrmnext_company_id: $scope.company.hrmnext_id,
			};
			$scope.tinymceOptions = {
				onChange: function (e) {
					// put logic here for keypress and cut/paste changes
				},
				theme: 'modern',
				plugins: [
					'advlist autolink lists link image charmap print preview hr anchor pagebreak',
					'searchreplace wordcount visualblocks visualchars code fullscreen',
					'insertdatetime media nonbreaking save table contextmenu directionality',
					'emoticons template paste textcolor colorpicker textpattern imagetools',
					'codesample',
				],
				toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
				toolbar2: 'print preview media | forecolor backcolor emoticons codesample',
				image_advtab: true,
			};

			if ($scope.company.hrmnext == true) {
				Restangular.one('hrmnext-connections', $scope.company.hrmnext_connection_id).get().then(function (hrmnext_connection) {
					$scope.hrmnext_connection = hrmnext_connection;
				});
			}

			//watch the company is a hrmnext company and if the connection_id changes
			$scope.$watch('[company.hmrnext,hrmnext_connection.updated_at]', _.debounce(function (newValue, oldValue, scope) {
				if ($scope.company.hrmnext == true && $scope.company.hrmnext_connection_id != null) {
					Restangular.one('hrmnext-connections', $scope.company.hrmnext_connection_id).all('companies').getList().then(function (companies) {
						$scope.hrmnextCompanies = companies;
					}, function (response) {
						Notification.error('An error has occurred while trying to obtain connection companies');
					});
				}
			}, 1000));

			$scope.saveChanges = function () {
				$scope.company.save().then(function (data) {
					Notification.success('Company update was successful');
				}, function (response) {
					Notification.error('Failed to update company');
				});
			}
			$scope.departmentTransform = function (name) {
				return {
					name: name,
					description: '',
				};
			}
			$scope.addBranch = function () {
				$scope.branches.forEach(function (branch) {
					branch.isOpen = false;
				});
				$scope.branches.push({
					name: '',
					description: '',
					head_office: false,
					departments: [],
					isOpen: true,
				});
			}
			$scope.saveBranches = function () {
				$scope.company.all('branches').customPUT({
					branches: $scope.branches
				}).then(function (branches) {
					$scope.branches = branches;
					Notification.success('Successfully updated company branches ');
				}, function (response) {
					Notification.error('Failed to update company branches');
				});
			}

			$scope.addMember = function () {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/manage/company/member/member.html',
					controller: 'CompanyMemberCtrl',
					size: 'md',
					resolve: {
						company: function () {
							return company;
						},
						roles: function () {
							return roles;
						}
					},
				});
				modalInstance.result.then(function (member) {
					$scope.members.push(member);
					Restangular.one('users', member.user_id).get().then(function (user) {
						$scope.users[user.id] = user;
					});
				});
			}
			/**
			 * Pops up modal to edit a member's role
			 * @param /App/CompanyMember member - Accepts a member to populate the modal
			 */
			$scope.editMember = function (member) {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/manage/company/member/editMember.html',
					controller: 'CompanyEditMemberCtrl',
					size: 'md',
					resolve: {
						member: function () {
							return member;
						},
						roles: function () {
							return roles;
						},
						company: function () {
							return company;
						}
					},

				});
			}

			$scope.refreshDepartments = function (query) {
				$scope.company.all('departments').getList({
					'q': query
				}).then(function (data) {
					$scope.departments = data;
				});
			}
			$scope.removeBranch = function (branch) {
				var modalInstance = DialogService.confirm('Are you sure you want to remove this branch?', 'Remove Branch');
				modalInstance.then(function () {
					branch.deleted = true;
				});
			}

			$scope.removeMember = function (member) {
				var modalInstance = DialogService.confirm('Are you sure you want to remove this member?', 'Remove Member');
				modalInstance.then(function () {
					Restangular.one('companies', $scope.company.id)
						.one('members', member.id)
						.customDELETE()
						.then(function (response) {
							$scope.members.splice($scope.members.indexOf(member), 1);
							Notification.success('Successfully removed company member');
						}, function (response) {
							Notification.error('Failed to remove company member');
						});
				});
			}
			$scope.saveConnection = function () {
				if ($scope.hrmnext_connection.id == undefined) {
					Restangular.all('hrmnext-connections').customPOST($scope.hrmnext_connection).then(function (hrmnext_connection) {
						$scope.hrmnext_connection = hrmnext_connection;
						Notification.success('The connection was successfully updated');
					}, function (response) {
						Notification.error('Failed to update connection');
					});
				} else {
					$scope.hrmnext_connection.save().then(function (hrmnext_connection) {
						$scope.hrmnext_connection = hrmnext_connection;
						Notification.success('The connection was successfully updated');
					}, function (response) {
						Notification.error('Failed to update connection');
					});
				}
			}
			$scope.addRole = function () {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/manage/company/roles/roles.html',
					controller: 'CompanyRoleCtrl',
					size: 'lg',
					resolve: {
						company: function () {
							return $scope.company;
						},
						permissions: function (Restangular) {
							return Restangular.all('permissions').getList();
						},
					},
				});

				modalInstance.result.then(function (response) {
					$scope.roles = response;
				});
			}
			$scope.resendInvitation = function (member) {
				console.log(Restangular.stripRestangular(member));
				Restangular.one('users', member.user_id).one('invitations', member.invitation.data.id).customPOST(null, 'resend')
					.then(function (invitation) {
						$scope.members[$scope.members.indexOf(member)].invitation.data = invitation;
						Notification.success('Successfully sent invitation');
					}, function (response) {
						Notification.error('Failed to resend invitation');
					})
			}
		}
	])