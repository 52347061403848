angular.module('hrmnext.recruitment.manage.position.stage', [
	'hrmnext.recruitment.manage.position.stages.templates',
	'hrmnext.recruitment.managae.stages.interview',
	'hrmnext.recruitment.user.services.user',
	'hrmnext.recruitment.manage.position.stages.services.positionstage',
	'hrmnext.recruitment.manage.position.stages.interviewer',
	'hrmnext.recruitment.manage.position.stages.candidate-interviews',
	'hrmnext.recruitment.manage.position.stage.interviewerFilter',
	'hrmnext.recruitment.manage.position.stages.candidate',
	'hrmnext.recruitment.managae.stages.interview.manager',
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.showPosition.stage', {
			url: '/stages',
			templateUrl: 'app/manage/position/stage/stage.html',
			controller: 'PostionStageCtrl',
			access: {
				requirePermissions: ['view_position_stage']
			},
			resolve: {
				positionStage: function (Restangular, position) {
					return Restangular.one('position-stages', position.id).get({
						include: 'stages.candidates.interviews.participants,stages.candidates.user.phoneNumbers,stages.questions,stages.interviews.candidate.user.phoneNumbers,stages.interviews.candidate.user.addresses,stages.interviews.participants.user'
					});
				},
				stageStatus: function (Restangular) {
					return Restangular.all('stage_status').getList();
				},
				interviewers: function (Restangular, position) {
					return Restangular.one('companies', position.company_id).all('members').getList({
						'interviewers': true,
						include: 'invitation'
					});
				},
				companyEvents: function (Restangular, position) {
					return Restangular.one('companies', position.company_id).all('events').getList();
				},
				candidateStatus: function (Restangular) {
					return Restangular.all('candidateStatus').getList();
				},
				interviews: function (Restangular, auth) {
					return Restangular.all('interviews').getList();
				},
				company: function (Restangular, position) {
					return Restangular.one('companies', position.company_id).get({
						include: 'address'
					});
				}
			}
		});
	}])
	.controller('PostionStageCtrl', function ($scope, positionStage, $uibModal, DialogService, Notification, $rootScope, userService,
		$q, positionStageService, Restangular, interviewers, $timeout, uiCalendarConfig, $log, companyEvents, candidateStatus, $compile, interviews, company) {
		$scope.positionStage = positionStage;
		$scope.userService = userService;
		$scope.interviewers = interviewers;
		$scope.calendars = [];
		$scope.calendarEvents = [];
		$scope.stageInterview = [];
		$scope.users = {};
		$scope.interviewChangesWarning = true;
		$scope.interviews = interviews;
		/**
		 * onCalendarEvent Clicked
		 */
		$scope.onEventClick = function (date, jsEvent, view) {
			for (var i = 0; i < $scope.positionStage.stages.data.length; i++) {
				var interview = $scope.positionStage.stages.data[i].interviews.find(function (interview) {
					return interview == date.interview;
				});
				if (interview) {
					$scope.updateInterview(interview, $scope.positionStage.stages.data[i]);
				}
			}
		};


		/* alert on eventClick */
		$scope.alertOnEventClick = function (event, jsEvent, view) {
			if (event.type == "interview") {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/manage/position/stage/interview/interview.html',
					controller: 'StageInterviewCtrl',
					backdrop: 'static',
					size: 'md',
					resolve: {
						candidate: function () {
							return JSON.parse(JSON.stringify(event.candidate));
						},
						interview: function () {
							return event.interview;
						},
						interviewers: function () {
							return JSON.parse(JSON.stringify($scope.interviewers));
						}
					}
				});

				modalInstance.result.then(function (result) {
					event.interview = result;
					var index = $scope.positionStage.stages.data.findIndex(function (stage) {
						return stage.isOpen == true;
					})
					$scope.updateStageEvents($scope.positionStage.stages.data[index]);
				});

			}

			// var modalInstance = $uibModal.open({
			//     templateUrl : 'app/home/interview.html',
			//     controller  : 'InterviewPreviewCtrl',
			//     resolve     : {
			//         interview : function(){
			//             return event.interview;
			//         }
			//     }
			// });
		};
		/* alert on Drop */
		$scope.alertOnDrop = function (event, delta, revertFunc, jsEvent, ui, view) {
			$scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
		};
		/* alert on Resize */
		$scope.alertOnResize = function (event, delta, revertFunc, jsEvent, ui, view) {
			$scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
		};

		/* remove event */
		$scope.remove = function (index) {
			$scope.events.splice(index, 1);
		};

		/* Change View */
		$scope.changeView = function (view, calendar) {
			uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
		};

		/* Change View */
		$scope.renderCalender = function (calendar) {
			if (uiCalendarConfig.calendars[calendar]) {
				uiCalendarConfig.calendars[calendar].fullCalendar('render');
			}
		};

		/* Render Tooltip */
		$scope.eventRender = function (event, element, view) {
			element.attr({
				'uib-tooltip': event.title,
				'tooltip-append-to-body': true
			});
			$compile(element)($scope);
		};

		var calendarOptions = {
			editable: true,
			header: {
				left: 'month,agendaWeek,agendaDay',
				center: 'title',
				right: 'today prev,next'
			},
			height: 515,
			defaultView: 'month',
			ignoreTimezone: false,
			eventClick: $scope.alertOnEventClick,
			eventDrop: $scope.alertOnDrop,
			eventResize: $scope.alertOnResize,
			eventRender: $scope.eventRender
		};

		$scope.initCompanyEvents = function () {
			$scope.calendarEvents.splice(0);
			companyEvents.forEach(function (event) {
				if (event.type == 'holiday') {

					$scope.calendarEvents.push({
						type: 'holiday',
						event: event,
						title: event.name,
						start: event.start_at,
						end: event.end_at,
						allDay: event.all_day,
						color: '#faa35c'
					});
				}
			});
		}
		//initialize the sages
		$scope.initStage = function () {
			$scope.calendars.splice(0);

			if ($scope.positionStage.stages.data.length > 0) {
				$scope.positionStage.stages.data[0].isOpen = true;
			}

			$scope.positionStage.stages.data.forEach(function (stage, index) {
				stage.events = [];
				$scope.calendars.push({
					stage_id: stage.id,
					sources: [
						$scope.calendarEvents,
						stage.events
					],
					options: calendarOptions,
				});
				$scope.updateStageEvents(stage)
				$scope.render('calendar' + index);
			});
		}
		$scope.updateInterview = function (interview, stage) {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/position/stage/interview/interview.html',
				controller: 'StageInterviewCtrl',
				backdrop: 'static',
				size: 'md',
				resolve: {
					candidate: function () {
						return $scope.interview.data.candidate;
					},
					interview: function () {
						return interview;
					},
					interviewers: function () {
						return JSON.parse(JSON.stringify($scope.interviewers));
					}
				}
			});
			modalInstance.result.then(function (response) {
				interview = response;
				$scope.updateStageEvents(stage);
			});
		}
		$scope.addInterview = function (candidate, stage) {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/position/stage/interview/interview.html',
				controller: 'StageInterviewCtrl',
				backdrop: 'static',
				size: 'md',
				resolve: {
					candidate: function () {
						return JSON.parse(JSON.stringify(candidate));
					},
					interview: function () {
						var address = "";
						if (company.address) {
							address = company.address.line_1 + (company.address.line_1 != '' ? ', ' : '');
							address += company.address.line_2 + (company.address.line_2 != '' ? ', ' : '');
							address += company.address.line_3 + (company.address.line_3 != '' ? ', ' : '');
							address += company.address.line_4 + (company.address.line_4 != '' ? ', ' : '');
							address += company.address.country;
						}

						return {
							candidate_id: candidate.id,
							name: candidate.user.data.first_name + ' ' + candidate.user.data.last_name + ' ' + stage.name + ' Interview',
							is_all_day: false,
							location: address, //get the location of the company
							start_at: moment().format(),
							end_at: moment().add('1', 'hour').format(),
							objectives: '',
							participants: {
								data: []
							},
							type: 'interview',
							candidate: {
								data: JSON.parse(JSON.stringify(candidate))
							}, //prevent circular dependecies
						};
					},
					interviewers: function () {
						return JSON.parse(JSON.stringify($scope.interviewers));
					}
				}
			});

			modalInstance.result.then(function (interview) {
				candidate.interviews.data.push(interview)
				$scope.updateStageEvents(stage);
			});
		}

		$scope.updateStageEvents = function (stage) {
			stage.events.splice(0);
			stage.candidates.data.forEach(function (candidate) {
				if (!candidate.deleted) {
					candidate.interviews.data.map(function (interview) {
						if (!interview.deleted) {
							stage.events.push({
								type: 'interview',
								interview: interview,
								candidate: candidate,
								title: interview.name,
								start: moment(interview.start_at).format(),
								end: moment(interview.end_at).format(),
								allDay: interview.is_all_day,
							});
						}
					});
				}
			});

			//get all the interviews not in the stage
			//add all interviews that you should see
			$scope.interviews.filter(function (interview) {
				var stageInterviews = [];

				for (var i = 0; i < stage.candidates.data.length; i++) {
					if (!stage.candidates.data[i].deleted) {
						for (var j = 0; j < stage.candidates.data[i].interviews.data.length; j++) {
							if (interview.id == stage.candidates.data[i].interviews.data[j].id && !stage.candidates.data[i].interviews.data[j].deleted) {
								return false;
							}
						}
					}
				}
				return true;
			}).forEach(function (interview) {
				stage.events.push({
					type: 'other-interviews',
					interview: interview,
					title: interview.name,
					start: interview.start_at,
					end: interview.end_at,
					allDay: interview.is_all_day,
					color: '#43BC94',
				});
			});
		}

		$scope.importStages = function () {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/position/stage/import-template/import-template.html',
				controller: 'ImportStageTemplateCtrl',
				backdrop: 'static',
				size: 'lg',
				resolve: {
					stageTemplates: function (Restangular) {
						return Restangular.all('stage-templates').getList({
							include: 'stages.questions,company'
						});
					}
				},
			});
			modalInstance.result.then(function (stages) {
				$scope.positionStage.stages.data = $scope.positionStage.stages.data.concat(stages);
				$scope.initStage();
				$scope.initCompanyEvents();
			});
		}
		$scope.addInterviewer = function () {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/position/stage/interviewer/interviewer.html',
				controller: 'InterviewerCtrl',
				backdrop: 'static',
				size: 'md',
				resolve: {
					company: function (Restangular) {
						return Restangular.one('companies', $scope.position.company_id).get();
					}
				}
			});

			modalInstance.result.then(function (interviewer) {
				$scope.interviewers.push(interviewer);
			});
		}
		$scope.removeStage = function (stage) {
			var modalInstance = DialogService.confirm('Are you sure you want to delete this stage?', 'Delete Stage');

			modalInstance.then(function () {
				stage.deleted = true;
			});
		}
		$scope.removeQuestion = function (question) {
			var modalInstance = DialogService.confirm('Are you sure you want to delete this question?', 'Delete Stage Question');

			modalInstance.then(function () {
				question.deleted = true;
			});
		}
		$scope.addQuestion = function (stage) {
			stage.questions.data.push({
				name: '',
				order: stage.questions.data.length,
				description: '',
				weight: 0,
			})
		}

		$scope.findCandidate = function (stage, candidate_id) {
			return stage.candidates.data.find(function (candidate) {
				return candidate.id == candidate_id;
			});
		}


		$scope.addStage = function () {
			$scope.positionStage.stages.data.filter(function (stage) {
				return stage.isOpen == true;
			}).forEach(function (stage) {
				stage.isOpen = false;
			});
			$scope.positionStage.stages.data.push({
				name: '',
				description: '',
				order: $scope.positionStage.stages.data.length + 1,
				template_stage_id: null,
				questions: {
					data: []
				},
				participants: {
					data: []
				},
				candidates: {
					data: []
				},
				events: [],
				isOpen: true,
				interviews: {
					data: []
				},
			});
			$scope.initStage();
		}

		$scope.saveChanges = function () {
			$scope.positionStage.save({
				include: 'stages.candidates.interviews.participants,stages.candidates.user.phoneNumbers,stages.questions,stages.interviews.candidate.user.phoneNumbers,stages.interviews.candidate.user.addresses,stages.interviews.participants.user'
			}).then(function (positionStage) {

				$scope.positionStage = positionStage;
				$scope.positionStage.stages.data[0].isOpen = true;
				$scope.initStage();
				$scope.initCompanyEvents();

				Restangular.all('interviews').getList().then(function (interviews) {
					$scope.interviews = interviews;
					$scope.positionStage.stages.data.forEach(function (stage) {
						$scope.updateStageEvents(stage)

					});
				}, function (response) {
					Notification.error('Failed to get interview list');
				});
				Notification.success('Stage was successfully updated');
			}, function (response) {
				Notification.error('Failed to update position stages');
			});
		}
		$scope.render = function (calendarId) {
			$timeout(function () {
				$(uiCalendarConfig.calendars[calendarId]).fullCalendar('render');
			});
		};
		$scope.viewCandidateInterviews = function (candidate) {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/position/stage/candidate-interviews/candidate-interviews.html',
				controller: 'CandidateInterviewsCtrl',
				// backdrop: 'static',
				size: 'lg',
				resolve: {
					interviews: function () {
						var interviews = [];
						$scope.stages.forEach(function (stage, index) {
							interviews = stage.interviews.map(function (interview) {
								if (interview.candidate_id == candidate.id) {
									return interview;
								}
							}).concat(interviews);
						});

						return interviews;
					},
					interviewers: function () {
						return $scope.interviewers;
					},
					candidate: function () {
						return candidate;
					}
				},
			});
			modalInstance.result.then(function () { });
		};

		$scope.candidateDetails = function (candidate) {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/position/stage/candidate/details/details.html',
				controller: 'CandidateDetailsCtrl',
				size: 'lg',
				resolve: {
					candidate: function () {
						return candidate;
					},
					candidateStatus: function () {
						return candidateStatus;
					},
					interviewers: function () {
						return $scope.interviewers;
					},
					company: function () {
						return company;
					}
				},
			});
			modalInstance.result.then(function (result) {
				angular.merge(candidate, result);
				var stage = $scope.positionStage.stages.data.find(function (stage) {
					return stage.candidates.data.find(function (c) {
						return c.id == candidate.id;
					});
				});
				$scope.updateStageEvents(stage);
			});
		};
		$scope.nextStep = function (candidate) {

			//get the stage
			var candidateStage = undefined;
			var nextStage = undefined;
			for (var i = 0; i < $scope.positionStage.stages.data.length; i++) {
				candidateStage = $scope.positionStage.stages.data[i].candidates.data.find(function (c) {
					return c.id == candidate.id;
				});
				if (candidateStage != undefined) {
					nextStage = $scope.positionStage.stages.data.find(function (stage) {
						return stage.order == $scope.positionStage.stages.data[i].order + 1;
					});
					break;
				}
			}

			if (nextStage == undefined) {
				DialogService.notify('The candidate is currently at the final stage', 'Next Stage Not Found');
			} else {
				//check if the user is already a candidate
				var nextStageCandidate = nextStage.candidates.data.find(function (cnd) {
					return cnd.user.id = candidate.user.id;
				});
				if (nextStageCandidate != undefined) {
					DialogService.notify('The user is already a candidate for the next stage.', 'The User Already A Candidate');
				} else {
					var modalInstance = DialogService.confirm('Are you sure you want to move the candidate to <b>' + nextStage.name + '</b> stage?', 'Move to Next Stage');

					modalInstance.then(function () {
						//add the user as a candidate for the next stage
						var newCandidate = angular.copy(candidate);
						newCandidate.status = 'Waiting';
						newCandidate.interviews.data.splice(0);
						delete newCandidate.id;

						nextStage.candidates.data.push(newCandidate);
					});
				}
			}
		}

		$scope.removeCandidate = function (candidate) {
			var modalInstance = DialogService.confirm('Are you sure you want to remove the candidate from this stage?', 'Confirm Removing Candidate');

			modalInstance.then(function (response) {
				candidate.deleted = true;
			});
		}
		$scope.viewInterview = function (interview) {
			if (interview.id == undefined) {
				DialogService.notify('You need to save this interview first before you can view the interview details', 'Interview Not Saved');
				return false;
			}

			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/position/stage/interview/interview.manager.html',
				controller: 'InterviewManagerCtrl',
				backdrop: 'static',
				size: 'lg',
				resolve: {
					interview: function (Restangular) {
						return Restangular.one('interviews', interview.id).get({
							include: 'participants.user.phoneNumbers,participants.questions.response.comments.user,participants.notes,position.company,candidate.user.phoneNumbers'
						});
					}
				},
			});
			modalInstance.result.then(function () { });
		}

		$scope.getUsers = function (userIds) {
			var missingUsers = [];
			userIds.forEach(function (userId, index) {
				if ($scope.users[userId] == undefined) {
					missingUsers.push(userId);
				}
			});
			if (missingUsers.length > 0) {
				Restangular.all('users').getList({
					'ids[]': missingUsers
				}).then(function (users) {
					users.forEach(function (user, index) {
						$scope.users[user.id] = user;
					});
				});
			}

		}
		$scope.getPhoneNumber = function (number) {
			return number.number + '' + (number.extension != '' ? ':' + number.extension : '');
		}

		$scope.initStage();
		$scope.initCompanyEvents();
	})