angular.module('hrmnext.recruitment.profile.messages', [])
.config(['$stateProvider',function($stateProvider){
	$stateProvider.state('profile.messages',{
		url : '/messages',
		templateUrl : 'app/profile/messages/messages.html',
		controller : 'ProfileMessageCtrl',
	})
}])
.controller('ProfileMessageCtrl', ['$scope', function($scope){
	
}])