angular.module('hrmnext.recruitment.manage.company.roles', [])
.controller('CompanyRoleCtrl',function($scope, company,permissions, Restangular, Notification){
	$scope.permissions = permissions;
	$scope.company = company;

	$scope.checkAll = function(){
		$scope.permissions.forEach( function(permission, index) {
			permission.selected = $scope.selectAll;
		});
	}
});