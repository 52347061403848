angular.module('hrmnext.recruitment.profile.invitations', [])
    .config(function ($stateProvider) {
        $stateProvider.state('profile.invitations', {
            url: '/invitations',
            controller: 'ProfileInvitationCtrl',
            // templateUrl: 'app/profile/settings/settings.html',
            onEnter: function (authorization, constants, $rootScope, $state) {
                var authorized = authorization.authorize({
                    requiresLogin: true,
                    requirePermissions: ['view_invitations']
                });
                // if the user has permission to view invitation redirect to the dashboard invitations
                if (authorized == constants.AUTHORIZED) {
                    return $state.target('manage.invitations.index')
                } else {
                    return $state.target('home');
                }
            },
            access: {
                requiresLogin: true,
            },
        })
    })
    .controller('ProfileInvitationCtrl', ['$scope', 'Restangular', 'Notification', function ($scope, Restangular, Notification) {

    }]);