angular.module('hrmnext.recruitment.manage.position.applications', [
	'hrmnext.recruitment.manage.position.applications.details',
	'hrmnext.recruitment.manage.position.applications.status',
	'hrmnext.recruitment.manage.position.applications.request',
	'hrmnext.recruitment.user.services.user',
	'hrmnext.recruitment.manage.position.applications.favorite',
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.showPosition.applications', {
			url: '/applications',
			templateUrl: 'app/manage/position/applications/applications.html',
			controller: 'PositionApplicationsCtrl',
			access: {
				requirePermissions: ['update_position', 'view_applications', 'reject_application'],
				permissionType: 'combinationRequired',
			},
			resolve: {
				applications: function (Restangular, $rootScope, position_id) {
					return Restangular.all('applications').getList({
						include: 'user.addresses,user.phoneNumbers,user.applications.position',
						position_id: position_id,
						status: 'Processing'
					});
				},
				application_status: function (Restangular) {
					return Restangular.all('application_status').getList();
				},
			}
		});
	}])
	.controller('PositionApplicationsCtrl', [
		'$scope',
		'applications',
		'$stateParams',
		'Restangular',
		'$uibModal',
		'Notification',
		'application_status',
		'DialogService',
		'$q',
		'userService',
		'position_id',
		function ($scope, applications, $stateParams, Restangular, $uibModal, Notification, application_status, DialogService, $q, userService, position_id) {
			$scope.applications = applications;
			$scope.company_id = $stateParams.company_id;
			$scope.position_id = $stateParams.id;

			//add status
			$scope.application_status = application_status;
			// $scope.application_status.push({name : 'Active'});

			//Filter
			$scope.filters = {
				status: 'Processing',
				position_id: position_id,
				include: 'user.addresses,user.phoneNumbers,user.applications.position',
			};


			$scope.previewUser = false;
			$scope.userService = userService;
			$scope.getUserData = function (application) {
				$scope.previewUser = false;
				Restangular.one('users', application.user_id).get().then(function (user) {
					$scope.user = user;
					$scope.previewUser = true;
				});
			}
			$scope.getApplications = function () {
				$scope.filters.page = $scope.applications.meta.pagination.current_page;
				$scope.applications.getList($scope.filters).then(function (response) {
					$scope.applications = response;
				}, function (response) {
					Notification.error('Failed to get application listing');
				});
			}

			$scope.openApplicationDetails = function (application) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/manage/position/applications/details/details.html',
					controller: 'ApplicationDetailCtrl',
					size: 'lg',
					resolve: {
						application: function () {
							return application;
						},
						applicationStatus: function () {
							return application_status.filter(function (status) {
								return status.name != 'All';
							});
						}
					}
				});

				modalInstance.result.then(function (response) {
					// application = response;
				});
			}

			$scope.updateStatusToRejected = function (application) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/manage/position/applications/status/status.html',
					controller: 'ApplicationStatusCtrl',
					size: 'md',
					resolve: {
						application: function () {
							return application;
						},
						status: function () {
							return 'Rejected';
						}
					}
				});
				modalInstance.result.then(function (response) {
					response.route = 'applications';
					$scope.applications[$scope.applications.indexOf(application)] = response;
					Notification.success('The application was successfully updated.');
				});
			}
			$scope.getMatchScoreProgressBarType = function (matchScore) {
				if (matchScore < 0.3) {
					return "danger";
				} else if (matchScore >= 0.3 && matchScore < 0.6) {
					return "warning";
				} else {
					return "success";
				}

			}
			$scope.updateStatusToProcessing = function (application) {
				var modalInstance = DialogService.confirm('Are you sure you want to change the application status to <strong><u>Processing</u></strong>?', 'Update Application Status');
				modalInstance.then(function () {
					application.customPOST({
						'status': 'Processing'
					}, 'status').then(function (response) {
						response.route = 'applications';
						$scope.applications[$scope.applications.indexOf(application)] = response;

						Notification.success('The application was successfully updated.');
					}, function (response) {
						Notification.error('Failed when trying to update application.');
					});
				});
			}
			$scope.updateApplicationAcceptance = function (application) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/manage/position/applications/status/status.html',
					controller: 'ApplicationStatusCtrl',
					size: 'md',
					resolve: {
						application: function () {
							return application;
						},
						status: function () {
							return 'Accepted';
						},
					}
				});
				modalInstance.result.then(function (response) {
					response.route = 'applications';
					application = response;
				});
			}

			$scope.updateFavoriteUser = function (user_id, company_ids, status) {
				switch (status) {
					case 'add':
						$scope.favoriteUser(user_id, company_ids);
						break;
					case 'remove':
						$scope.unFavoriteUser(user_id, company_ids);
						break;
				}
			}
			$scope.favoriteUser = function (user_id, company_ids) {
				var modalInstance = DialogService.confirm('Are you sure you want to <b><u>add</u></b> this user to your favourites list?', 'Favourite User');
				modalInstance.then(function () {
					Restangular.all('favorite_users').post({
						'user_id': user_id,
						'company_ids': company_ids,
					}).then(function (response) {
						var promises = {
							applications: $scope.applications.getList($scope.filters),
						};

						$q.all(promises).then(function (response) {
							$scope.applications = response.applications;
							Notification.success('The user was successfully favourited.');
						});
					}, function (response) {
						Notification.error('Failed to favourite the selected user.');
					});
				});
			}
			$scope.unFavoriteUser = function (user_id, company_ids) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/manage/position/applications/unfavorite/unfavorite.html',
					controller: 'UnfavoriteModalCtrl',
					size: 'md',
					resolve: {
						user: function (Restangular) {
							return Restangular.one('users', user_id).get();
						},
						companies: function (Restangular) {
							return Restangular.all('companies').getList({
								'ids[]': company_ids
							});
						}
					}
				}).result.then(function (response) {
					var promises = {
						applications: $scope.applications.getList($scope.filters),
					};

					$q.all(promises).then(function (response) {
						$scope.applications = response.applications;
						Notification.success('The user was successfully unfavourited.');
					});
				});
			}

			$scope.addAsCandidate = function (user_id) {
				var modalInstance = DialogService.confirm('Are you sure you want to add this person as a candidate?', 'Add Position Candidate');

				modalInstance.then(function () {
					$scope.position.all('candidates').post({
						user_id: user_id
					}).then(function (response) {
						promises = {
							applications: $scope.applications.getList($scope.filters)
						}
						$q.all(promises).then(function (response) {
							console.log(response);
							$scope.applications = response.applications;
							Notification.success('The user was successfully added as a candidate.')
						});
					}, function (response) {
						Notification.error('Failed to add user as a candidate.' + response.data.message);
					});
				})
			}

			$scope.removeCandidate = function (user_id) {
				var modalInstance = DialogService.confirm('Are you <b><u>absolutely</u></b> sure you want to remove this person as a candidate?', 'Add Position Candidate');
				modalInstance.then(function () {
					$scope.position.one('candidates', user_id).remove().then(function (response) {
						promises = {
							applications: $scope.applications.getList($scope.filters)
						}
						$q.all(promises).then(function (response) {

							console.log(response);
							$scope.applications = response.applications;
							Notification.success('The user was successfully removed as a candidate.')
						});
					}, function (response) {
						Notification.error('Failed to update a candidate.' + response.data.message);
					});
				})
			}
			$scope.selectApplication = function (application) {
				$scope.selectedApplication = application;
			}
			$scope.updateApplication = function () {
				$scope.selectedApplication.save().then(function (data) {
					$scope.selectedApplication = data;
					Notification.success('Successfully updated application');
				}, function (response) {
					Notification.error('Failed to updated application');
				});
			}
			$scope.updateCandidate = function (user_id, operation) {
				if (operation == 'add') {
					$scope.addAsCandidate(user_id);
				} else {
					$scope.removeCandidate(user_id);
				}
			}
			$scope.getPositionSkillRequirement = function (skillId) {
				return $scope.position.skills.find(function (skill) {
					return skill.id = skillId;
				});
			}
			$scope.updateApplicationStatus = function (application, operation) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/manage/position/applications/status/status.html',
					controller: 'ApplicationStatusCtrl',
					size: 'md',
					resolve: {
						application: function () {
							return application;
						},
						status: function () {
							return operation;
						},
					}
				});
				modalInstance.result.then(function (response) {
					response.route = 'applications';
					// application = response;
					$scope.applications[$scope.applications.indexOf(application)] = response;
				});
			}

			$scope.getOtherApplications = function (application) {
				//find all the applications the user applied for
				return application.user.data.applications.data.filter(function (userApplication) {
					return userApplication.id != application.id
				});
			}

			$scope.getSortByClass = function (fieldName) {
				if ($scope.filters.sortBy == fieldName) {
					return 'fa-sort-' + $scope.filters.sort;
				}
				return 'fa-sort text-muted';
			}
			$scope.sortBy = function (fieldName) {
				$scope.filters.sort = $scope.filters.sortBy != fieldName || $scope.filters.sort == 'desc' ? 'asc' : 'desc';
				$scope.filters.sortBy = fieldName;
				$scope.getApplications();
			}
		}
	]);