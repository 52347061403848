angular.module('hrmnext.recruitment.manage.position.applications.request', [])
.controller('RequestApplicationCtrl',function($scope, Restangular,$uibModalInstance){
    $scope.request = {
        comment : ''
    }
    $scope.user = {
        name : 'Warren'
    }
    $scope.cancel = function(){
        $uibModalInstance.dismiss();
    }
    $scope.ok = function(){
        $uibModalInstance.close();   
    }
});