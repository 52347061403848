angular.module('hrmnext.recruitment.manage.favorite-users.addUser', [])
.controller('AddFavoriteUserCtrl',function($uibModalInstance, $scope, Restangular,companies,Notification){
	$scope.companies = companies;
	$scope.selectedUser = {};
	$scope.favoriteUser = {
		company_id: companies[0].id
	}
	$scope.ok = function(){
		Restangular.all('favorite_users').customPOST($scope.favoriteUser).then(function(favoriteUser){
			$uibModalInstance.close(favoriteUser);
			Notification.success("Successfully added users");
		})
	} 
	$scope.users = [];
	$scope.selectedUsers = [];

	$scope.getUsersAsync = function(search){
		return Restangular.all('users').getList({'query': search }).then(function(users){
			$scope.users = users;
		});
	}

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}
});