/**
*  HRMNext Recruitment Manage Module
* Manages all the application
*/
angular.module('hrmnext.recruitment.manage', [
	'hrmnext.recruitment.manage.position',
	'hrmnext.recruitment.manage.stages',
	'hrmnext.recruitment.manage.permission',
	'hrmnext.recruitment.manage.dashboard',
	'hrmnext.recruitment.manage.user',
	'hrmnext.recruitment.manage.company',
	'hrmnext.recruitment.manage.interviews',
	'hrmnext.recruitment.manage.feedbacks',
	'hrmnext.recruitment.manage.hrmnext',
	'hrmnext.recruitment.manage.favorite-users',
	'hrmnext.recruitment.manage.audit',
	'hrmnext.recruitment.manage.notifications',
	'hrmnext.recruitment.manage.invitations'
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage', {
			abstract: true,
			url: '/manage',
			templateUrl: 'app/manage/manage.html',
			controller: 'ManageCtrl',
			resolve: {
			}
		});
	}])
	.controller('ManageCtrl', ['$scope', '$state', 'Restangular', function ($scope, $state, Restangular) {
		$scope.$state = $state;

		$scope.positionStates = [
			'manage.position',
			'manage.showPosition.edit',
			'manage.showPosition',
			'manage.positionApplication',
			'manage.showPosition.applications'
		];
		$scope.stagesStates = [
			'manage.stages',
			'manage.showStage',
			'manage.createStage',
		];

		Restangular.all('notifications').getList({ include: 'user', only: 10 }).then(function (notifications) {
			$scope.notifications = notifications;
		});


		$scope.notificationViewed = function (notification) {
			if (notification.new == true) {
				notification.new = false;
				Restangular.one('notifications', notification.id).customPOST(null, 'viewed').then(function (response) {
					Restangular.all('notifications').getList({ include: 'user', only: 10 }).then(function (notifications) {
						$scope.notifications = notifications;
					});
				});
			}
		}

	}])

