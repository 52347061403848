angular.module('hrmnext.recruitment.positions.list', [])
.config(['$stateProvider',function($stateProvider){
	$stateProvider
		.state('positions',{
			url : '/positions',
			templateUrl: 'app/position/list/list.html',
			controller: 'PositionListCtrl',
			resolve : {
				positions : ['Restangular',function(Restangular){
					return  Restangular.all('positions').getList({ include : 'company,address'});
				}],				
				jobTypes : ['Restangular', function(Restangular){
					return Restangular.all('job_types').getList();
				}]
			},
			access : {
				completedProfile: true,
			}
		})
}])
.controller('PositionListCtrl', ['$scope','positions','jobTypes','Restangular','Notification','flash','$uibModal','DialogService','$rootScope','$state',
function($scope,positions,jobTypes,Restangular,Notification,flash,$uibModal,DialogService,$rootScope,$state){
	var initializing = true;
	$scope.positions = positions;
	$scope.jobTypes = jobTypes;
	$scope.filters = {	
		include : 'company,address',
		sortBy : 'date_open',
		orderBy : 'date_open',
	};
	$scope.filterPositions = function(){
		$scope.filters.page = $scope.positions.meta.pagination.current_page;
  		Restangular.all('positions').getList($scope.filters).then(function(response){
  			$scope.positions = response;
  		},function(response){
  			Notification.error('Failed to update position listings');
  		});
  	}
  	$scope.$watch('filters', _.debounce(function(newValue, oldValue, scope) {
  		if(initializing != true){  			
  			Restangular.all('positions').getList($scope.filters).then(function(response){
  				$scope.positions = response;
  			});
  		}  	
  		initializing = false;
  	},200),true);

  	$scope.pageChanged = function(){
  		$scope.filterPositions();
  	}

  	$scope.resetSearch = function(){
  		$scope.filters = {	
			include : 'company,address',
			sortBy : 'date_open',
			orderBy : 'desc',
		};
  	}

  	$scope.submitApplication = function(position){

  		if(!$rootScope.authUser){
  			$state.go('login');
  		}
  		else{
  			position.one('prerequisites',$rootScope.authUser.id).get().then(function(prerequisite){
	  			if(prerequisite.metRequirements){
	  				//before opening the application form determine if the user is able to submit an application
			  		var modalInstance = $uibModal.open({
						animation: true,
						templateUrl: 'app/position/application/application.html',
						controller: 'JobApplicationCtrl',
						backdrop : 'static',
						size: 'lg',
						resolve: {
							position: function () {
								return position;
							}
						}
					});
					modalInstance.result.then(function (selectedItem) {
	  					position.application.submitted = true;
						Notification.success('Your applicaton was successfully submitted');
					});
	  			} else {
	  				var message = '<p>You have not met the prerequistes to apply for an applications.</p><br>';
	  				if(prerequisite.metEducationRequirements){
	  					message += '<p>Education : <span class="success"><i class="fa fa-check"></i></span></p>';
	  				}else{
	  					message += '<p>Education : <span class="danger"><i class="fa fa-times"></i></span></p>';
	  				}
	  				DialogService.notify(message, 'Prerequiste Failed');
	  			} 
	  				
	  		});
  		}
  	}
}]);