/**
* hrmnext.recruitment.interview.components.question Module
*
* Description
*/
angular.module('hrmnext.recruitment.manage.interview.components.question', [])
.controller('AddQuestionModalCtrl', ['$scope','question','$uibModalInstance','sync','Restangular','Notification', function($scope, question, $uibModalInstance,sync,Restangular,Notification){
	$scope.question = Object.assign({},question);
	$scope.ok= function(){
		if($scope.form.$valid){
			if(sync){
				Restangular.one('interviewer-questions').customPOST($scope.question,null,{include:'response.comments'}).then(function(question){					
					$uibModalInstance.close(question);
					Notification.success('Successfully created question');
				}, function(response){
					Notification.error('Fail to add question');
				})
			} else{
				$uibModalInstance.close(question);
			}	
		}
	}

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}

}])
.controller('EditQuestionModalCtrl', ['$scope','question','$uibModalInstance','sync','Restangular','Notification', function($scope, question, $uibModalInstance,sync,Restangular,Notification){
	$scope.question = question;
	$scope.ok= function(){
		if($scope.form.$valid){
			if(sync){
				Restangular.one('interviewer-questions',$scope.question.id).customPUT($scope.question,null,{include:'response.comments'}).then(function(question){					
					$uibModalInstance.close(question);
					Notification.success('Successfully updated question');
				}, function(response){
					Notification.error('Fail to update question');
				})
			} else{
				$uibModalInstance.close(question);
			}	
		}
	}

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}

}])