angular.module('hrmnext.recruitment.manage.favorite-users.index', [
	'hrmnext.recruitment.manage.favorite-users.unfavorite',
	'hrmnext.recruitment.user.services.user',
	'hrmnext.recruitment.manage.favorite-users.addUser'
])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.favorite_users.index',{
		url: '',
		templateUrl : 'app/manage/favorite-users/favorite-users.index.html',
		controller : 'FavoriteUserIndexCtrl',
		access : {

		},
		resolve : {
			favoriteUsers : function(Restangular){
				return Restangular.all('favorite_users').getList({sortBy:'user',sort:'asc', include:'user,company'});
			},
			companies : function(Restangular){
				return Restangular.all('companies').getList();
			}
		},
	})
}])
.controller('FavoriteUserIndexCtrl', ['$scope','favoriteUsers','Notification','Restangular','$uibModal','userService','companies', function($scope,favoriteUsers,Notification,Restangular,$uibModal,userService,companies){
	$scope.favoriteUsers = favoriteUsers;
	$scope.users = {};
	$scope.companies = {};
	$scope.filters = {
		sortBy : 'user',
		sort: 'asc',
		query: '',
	};

	var init = function(){
		$scope.users = {};
		$scope.companies = {};
		// var userIds = [];
		var companyIds = [];
		//get the user information
		// $scope.favoriteUsers.forEach(function(favoritedUser){
		// 	userIds.push(favoritedUser.user_id);
		// 	favoritedUser.favorited_by.forEach(function(favoritedBy){
		// 		companyIds.push(favoritedBy.company_id);
		// 	});
		// });

		// Restangular.all('users').getList({'ids[]': userIds }).then(function(users){
		// 	users.forEach(function(user){
		// 		$scope.users[user.id] = user;
		// 	});
		// });

		Restangular.all('companies').getList({'ids[]' : _.uniq(companyIds) }).then(function(companies){
			companies.forEach(function(company){
				$scope.companies[company.id] = company;
			});
		});		
	}

	$scope.favoriteUserPaginationUpdated = function(){
		$scope.filters.page = $scope.favoriteUsers.meta.pagination.current_page;
		$scope.updateFavoriteUserLists();
	}

	$scope.updateFavoriteUserLists = function(){
		$scope.favoriteUsers.getList($scope.filters).then(function(response){
			$scope.favoriteUsers = response;
		}, function(response){
			Notification.error('An error occurred while trying to get user list');
		});
	}

	$scope.getSortByClass = function(fieldName){
		if($scope.filters.sortBy==fieldName){
			return 'fa-sort-'+$scope.filters.sort;
		}
		return 'fa-sort text-muted';
	}

	$scope.sortBy = function(fieldName){
		$scope.filters.sort = $scope.filters.sortBy!=fieldName || $scope.filters.sort =='desc' ? 'asc' : 'desc';
		$scope.filters.sortBy = fieldName;
		$scope.updateFavoriteUserLists();
	}

	$scope.unfavorite = function(favoriteUser){
		var modalInstance = $uibModal.open({
			templateUrl : 'app/manage/favorite-users/components/unfavorite.html',
			controller : 'UnfavoriteCtrl',
			size : 'md',
			resolve : {
				user : function(){
					return $scope.users[favoriteUser.user_id];
				},
				companies : function(){
					var companies = [];
					favoriteUser.favorited_by.forEach(function(favoritedBy){
						companies.push($scope.companies[favoritedBy.company_id]);
					});		
					return companies;
				},
			}
		}).result.then(function(response){
			Restangular.all('favorite_users').getList().then(function(favoriteUsers){
				$scope.favoriteUsers = favoriteUsers;
				init();
			});			
		});
	}
	
	$scope.previewUser = function(favoriteUser){
		userService.preview(favoriteUser.user_id);
	}

	$scope.addUser = function(){
		var modalInstance = $uibModal.open({
			templateUrl: 'app/manage/favorite-users/components/favorite-users.addUser.html',
			controller: 'AddFavoriteUserCtrl',
			size: 'md',
			resolve: {
				companies : function(){
					return companies;
				}
			}
		}).result.then(function(response){
			Restangular.all('favorite_users').getList().then(function(favoriteUsers){
				$scope.favoriteUsers = favoriteUsers;
				init();
			});	
		});
	}

	init();
}])