/**
* rootsfolio.manage.position.reopen Module
*
* Description
*/
angular.module('rootsfolio.manage.position.reopen', [])
.controller('ReopenPositionCtrl', ['$scope','position','Restangular','Notification','$uibModalInstance', function($scope,position,Restangular,Notification,$uibModalInstance){
	$scope.position = Object.assign({},position);

	$scope.ok = function(){
		$scope.position.is_open = true;
		Restangular.one('positions',$scope.position.id).customPUT($scope.position,'',{include: 'company,address,skills,address,educations,experiences'}).then(function(response){
			$scope.position = response;
			Notification.success('Position closed successfully');
			$scope.position.date_open = moment($scope.position.date_open ).toDate();
			$scope.position.date_close = moment($scope.position.date_close ).toDate();
			$uibModalInstance.close(response);

		},function(response){
			Notification.error('Failed to close position');
			$scope.response = response;
		});
	}
	$scope.cancel = function(){
		$uibModalInstance.dissmiss();
	}
}])