angular.module('hrmnext.recruitment.manage.user-detail', ['hrmnext.manage.users.components.confrim-reset-password', 'hrmnext.manage.users.components.reset-password'])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.users.detail', {
			url: '/:id',
			controller: 'UserDetailCtrl',
			templateUrl: 'app/manage/user/user-detail.html',
			resolve: {
				user: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
					return Restangular.one('users', $stateParams.id).get({ include: 'addresses' });
				}],
				applications: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
					return Restangular.one('users', $stateParams.id).all('applications').getList();
				}]
			}
		})
	}])

	.controller('UserDetailCtrl', ['$scope', 'user', 'Restangular', 'applications', '$uibModal', 'Notification', function ($scope, user, Restangular, applications, $uibModal, Notification) {
		$scope.user = user;
		$scope.applications = applications;

		$scope.sendRestPassword = function () {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/user/components/confrim-reset-password/confrim-reset-password.html',
				controller: 'ConfirmSendResetPasswordCtrl',
				size: 'md'
			}).result.then(function (response) {
				Restangular.one('users', $scope.user.id).customPOST({}, 'sendResetPassword').then(function () {
					Notification.success('Password reset was sent successfully');


				}, function () {
					Notification.error('Failed to send reset password');
				});
			});
		}

		$scope.resetPassword = function () {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/manage/user/components/reset-password/reset-password.html',
				controller: 'ResetPasswordCtrl',
				size: 'md'
			}).result.then(function (password) {
				// console.log(password);
				Restangular.one('users', $scope.user.id).customPOST(password, 'resetPassword').then(function () {
					Notification.success('Password was reset succefully');
				}, function () {
					Notification.error('Failed to reset password');
				});
			});
		}
	}])
