angular.module('hrmnext.recruitment.app.services.dialogService')
.controller('NotifyDialogCtrl', ['$scope','$uibModalInstance','message','title',
 function($scope,$uibModalInstance,message,title){
	$scope.message = message;
	$scope.title  = title;
	$scope.ok = function(){

		$uibModalInstance.close();
	}

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}	
}]);