angular.module('hrmnext.recruitment.profile.resume.modal',[])
.controller('ResumeModalCtrl', ['$scope', '$uibModalInstance','Restangular','Notification','resume','user','FormDataService', function($scope,$uibModalInstance,Restangular,Notification,resume,user,FormDataService){
    $scope.resume = Object.assign({}, resume);

    $scope.ok = function(){
        var fd = FormDataService.toFormData(Restangular.stripRestangular($scope.resume));
        
        Restangular.one('users', user.id).all('resumes')
            .withHttpConfig({transformRequest: angular.identity,timeout: 0})
            .customPOST(fd,null,null,{ 'Content-Type' : undefined })
            .then(function(resume){
                Notification.success('Successfully uploaded resume');
                $uibModalInstance.close(resume);
            },function(response){
                Notification.error('Failed when trying to create resume');
            })
    }

    $scope.cancel = function(){
        $uibModalInstance.dismiss();
    }
}])