angular.module('hrmnext.recruitment.manage.position.stages.candidate.details', [])
	.controller('CandidateDetailsCtrl', ['$scope', 'Restangular', 'candidate', 'candidateStatus', '$uibModalInstance', '$uibModal', 'interviewers', 'company',
		function ($scope, Restangular, candidate, candidateStatus, $uibModalInstance, $uibModal, interviewers, company) {
			$scope.candidate = {};
			angular.copy(candidate, $scope.candidate);
			$scope.candidateStatus = candidateStatus;
			$scope.interviewers = interviewers;

			$scope.cancel = function () {
				$uibModalInstance.dismiss();
			}
			$scope.save = function () {
				$uibModalInstance.close($scope.candidate);
			}

			$scope.addInterview = function () {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/manage/position/stage/interview/interview.html',
					controller: 'StageInterviewCtrl',
					backdrop: 'static',
					size: 'md',
					resolve: {
						candidate: function () {
							return $scope.candidate;
						},
						interview: function () {
							var address = company.address.line_1 + (company.address.line_1 != '' ? ', ' : '');
							address += company.address.line_2 + (company.address.line_2 != '' ? ', ' : '');
							address += company.address.line_3 + (company.address.line_3 != '' ? ', ' : '');
							address += company.address.line_4 + (company.address.line_4 != '' ? ', ' : '');
							address += company.address.country;
							return {
								candidate_id: $scope.candidate.id,
								name: 'Stage Interview with ' + $scope.candidate.user.data.first_name + ' ' + $scope.candidate.user.data.last_name,
								is_all_day: false,
								location: address,//get the location of the company
								start_at: moment().format(),
								end_at: moment().add('1', 'hour').format(),
								objectives: '',
								participants: { data: [] },
							};
						},
						interviewers: function () {
							return $scope.interviewers;
						}
					}
				});

				modalInstance.result.then(function (interview) {
					$scope.candidate.interviews.data.push(interview)
				});
			}
			$scope.editInterview = function (interview) {
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/manage/position/stage/interview/interview.html',
					controller: 'StageInterviewCtrl',
					backdrop: 'static',
					size: 'md',
					resolve: {
						candidate: function () {
							return $scope.candidate;
						},
						interview: function () {
							return interview;
						},
						interviewers: function () {
							return $scope.interviewers;
						}
					}
				});

				modalInstance.result.then(function (result) {
					interview = result;
				});
			}

			$scope.removeInterview = function (interview) {
				interview.deleted = true;
			}
		}]);
