angular.module('hrmnext.recruitment.manage.company.create', [
])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.company.create',{
		url : '/create',
		templateUrl : 'app/manage/company/create/create.html',
		controller : 'CreateCompanyCtrl',
		resolve : {
			countries : function(Restangular){
				return Restangular.all('countries').getList();
			}
		},
		access :{

		}
	})
}])
.controller('CreateCompanyCtrl', function(Restangular, $scope, Notification,$state){
	$scope.company = {
		name : '',
		hrmnext_id : '',
		address : {},
		logo : ''
	}
	$scope.createCompany = function(){
		Restangular.one('companies').customPOST($scope.company).then(function(company){
			$state.go('manage.company.show',{id : company.id});
			Notification.success('Successfully create company');
		},function(response){
			Notification.error("Failed to create company");
		});
	}
})