angular.module('hrmnext.recruitment.managae.stages.interview.manager', [
		'hrmnext.recruitment.manage.interview.service'
	])
	.controller('InterviewManagerCtrl', function ($scope, Notification, Restangular, interview, auth, DialogService, $uibModalInstance, InterviewManagerService, auth) {
		$scope.interview = interview;
		$scope.syncMode = false;
		$scope.users = {};
		$scope.interviewService = InterviewManagerService;


		$scope.titleOption = {
			inline: true,
			toolbar: 'undo redo',
			menubar: false,
			theme: 'modern',
			plugins: ['placeholder']
		}
		$scope.descriptionOption = {
			inline: true,
			theme: 'modern',
			plugins: [
				'advlist autolink lists link image charmap print preview anchor',
				'searchreplace visualblocks code fullscreen',
				'insertdatetime media table contextmenu paste', 'placeholder'
			],
			toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image'
		};

		$scope.init = function () {
			//make the selected participant be the current auth user
			$scope.selectedParticipant = $scope.interview.participants.data.find(function (participant) {
				return auth.getUser().id == participant.user_id;
			});
			if (!$scope.selectedParticipant) {
				$scope.selectedParticipant = $scope.interview.participants.data[0];
			}

			if ($scope.selectedParticipant) {
				$scope.questions = $scope.selectedParticipant.questions.data;
				if ($scope.questions.length) {
					$scope.questions[0].isOpen = true;
				}
				$scope.notes = $scope.selectedParticipant.notes.data;
			}
		};
		$scope.init();

		$scope.saveChanges = function () {

			$scope.interview.save({
				include: 'participants.user,participants.questions,participants.notes,position,user'
			}).then(function (interview) {
				$scope.interview = interview;
				$scope.init();
				$uibModalInstance.close(interview);
				Notification.success('Successfully updated interview');
			}, function (response) {
				Notification.error('Failed to updated interview');
			});
		}

		$scope.close = function () {
			$uibModalInstance.dismiss();
		}

		$scope.selectedParticipantChanged = function (selectedParticipant) {
			selectedParticipant.questions.data[0].isOpen = true;
		}

		$scope.removeNote = function (note) {
			DialogService.confirm('Are you sure you want to remove this note?', 'Delete Comment').then(function () {
				note.deleted = true;
			});
		}

		$scope.addNote = function (participant) {
			participant.notes.data.push({
				title: '',
				notes: '',
				updated_at: new Date,
			});
		}
	})