angular.module('hrmnext.recruitment.manage.user', [
	'hrmnext.recruitment.manage.user-list',
	'hrmnext.recruitment.manage.user-detail'
])
.config(['$stateProvider',function($stateProvider){
	$stateProvider.state('manage.users',{
		abstract: true,
		url : '/users',
		template: '<ui-view/>'
	});
}]);