angular.module('hrmnext.recruitment.manage.company', [
		'hrmnext.recruitment.mamange.company.member',
		'hrmnext.recruitment.manage.company.index',
		'hrmnext.recruitment.manage.company.create',
		'hrmnext.recruitment.manage.company.show',
		'hrmnext.recruitment.manage.company.editMember',
	])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.company', {
			abstract: true,
			url: '/company',
			template: '<ui-view/>',
			access: {
				requiresLogin: true,
				requirePermissions: ['view_company'],
				permissionType: 'atLeastOne'
			},
		});
	}])