angular.module('hrmnext.recruitment.manage.hrmnext.show', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.hrmnext.show', {
			url: '/show',
			templateUrl: 'app/manage/hrmnext/show/show.html',
			controller: 'ShowHRMNextCtrl',
			access: {
				requiresLogin: true,
				requirePermissions: ['import_from_hrmnext'],
				permissionType: 'atLeastOne'
			},
			resolve: {

			}
		})
	}])
	.controller('ShowHRMNextCtrl', ['$scope', 'Restangular', 'Notification', function ($scope, Restangular, Notification) {

	}])