angular.module('hrmnext.recruitment.manage.position.applications.favorite', [])
.controller('UnfavoriteModalCtrl', function($scope, Notification, companies, user,Restangular, $uibModalInstance){
	$scope.companies = companies;
	$scope.user = user;
	$scope.selectedCompanies = {};

	$scope.companies.forEach(function(company){
		$scope.selectedCompanies[company.id] = true;
	});

	$scope.cancel = function(){
		$uibModalInstance.dismiss('cancel');
	}

	$scope.ok = function(){

		var companyIds = Object.keys($scope.selectedCompanies).filter(function(key){
			return $scope.selectedCompanies[key];
		});
		Restangular.all('favorite_users/unfavorite').customPOST({
			user_id : $scope.user.id,
			 company_ids : companyIds,
		}).then(function(response){
			$uibModalInstance.close();
		},function(response){
			Notification.error('Failed to unfavourite user');
		})
	}
})