angular.module('hrmnext.recruitment.services')
.factory('userResource', ['$resource', function($resource){
	var baseUrl =  'api/users/';

	return $resource(baseUrl+ ':id',{id:'@id'},{
		create : { 
			url 	: baseUrl+'create',
			method	:'GET'
		},
		activate : {
			url 	: baseUrl + 'activate/:activationCode',
			method 	: 'GET'
		},
		login :{
			method 	: 'POST',
			url 	: baseUrl + 'login',
		},
		update :{
			method 	: 'PATCH',
			url 	: baseUrl+':id'
		}
	});
}])