angular.module('hrmnext.recruitment.positions.detail', [])
.config(['$stateProvider',function($stateProvider){
	$stateProvider
		.state('positions/show',{
			url : '/positions/:id',
			templateUrl: 'app/position/detail/detail.html',
			controller: 'JobDetailCtrl',
			data : {
				requiresLogin : false,
			},
			resolve : {
				position : ['Restangular','$stateParams','$q',function(Restangular,$stateParams,$q){
					return Restangular.one('positions',$stateParams.id).get({ include : 'company,address'});
				}]
			},
			access : {
				completedProfile: true,
			}
		})
}])
.controller('JobDetailCtrl',['$scope','position', '$uibModal','Notification','$rootScope','DialogService', function($scope,position,$uibModal,Notification,$rootScope,DialogService){
	$scope.position = position;

	$scope.submitApplication = function(){
  		$scope.position.one('prerequisites',$rootScope.authUser.id).get().then(function(prerequisite){
  			if(prerequisite.metRequirements){
  				//before opening the application form determine if the user is able to submit an application
		  		var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/position/application/application.html',
					controller: 'JobApplicationCtrl',
					backdrop : 'static',
					size: 'lg',
					resolve: {
						position: function () {
							return position;
						}
					}
				});
				modalInstance.result.then(function (selectedItem) {
					$scope.position.application.submitted = true;
					Notification.success('Your applicaton was successfully submitted');
				});
  			} else {
  				var message = '<p>You have not met the prerequistes to apply for an applications.</p><br>';
  				if(prerequisite.metEducationRequirements){
  					message += '<p>Education : <span class="success"><i class="fa fa-check"></i></span></p>';
  				}else{
  					message += '<p>Education : <span class="danger"><i class="fa fa-times"></i></span></p>';
  				}
  				DialogService.notify(message, 'Prerequiste Failed');
  			} 
  				
  		});  		
  	}
}])
