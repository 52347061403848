angular.module('hrmnext.recruitment.manage.interviews.index', [])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.interviews.index',{
		url : '',
		templateUrl : 'app/manage/interviews/index/index.html',
		controller : 'InterviewListController',
		access : {
			requirePermissions : ['participate_in_interviews']
		},
		resolve : {
			interviews : function(Restangular, auth){
				return Restangular.all('interviews').getList({include : 'candidate.user',participant_user_id: auth.getUser().id,status_id:1});
			},
			interview_status : function(Restangular){
				return Restangular.all('stage_status').getList();
			},
		}
	})
}])
.controller('InterviewListController', function(Restangular, Notification, $rootScope,interviews,$scope,$timeout,uiCalendarConfig,interview_status){
	$scope.interviews = interviews;
	$scope.interview_status = interview_status;

	$scope.filters = {
		sortBy : 'start_at',
		sort: 'asc',
		query: '',
		status_id: 1,
	};
	$scope.calendar = {
		options : {
			editable: true,
			header:{
				left: 'month agendaWeek agendaDay',
				center: 'title',
				right: 'today prev,next'
			},
			height : 515,
			defaultView : 'month',
			ignoreTimezone: false,
		},
		sources : [
			$scope.interviews.map(function(interview){
				return {
					id : interview.id,
					title : interview.name,
					start : moment(interview.start_at).format(),
					end : moment(interview.end_at).format(),
					allDay : interview.is_all_day,
				};
			})
		],
	}
	$scope.sortBy = function(fieldName){
		$scope.filters.sort = $scope.filters.sortBy!=fieldName || $scope.filters.sort =='desc' ? 'asc' : 'desc';
		$scope.filters.sortBy = fieldName;
		$scope.updateInterviewLists();
	}
	$scope.updateInterviewLists = function(){
		Restangular.all('interviews').getList($scope.filters).then(function(interviews){
			$scope.interviews = interviews;
		},function(){
			Notification.error('Failed to get interviews');
		});
	}
	$scope.renderCalendar = function() {
		$timeout(function(){
           $(uiCalendarConfig.calendars['interviewCalendar']).fullCalendar('render');
        });
	};
	$scope.interviewPaginationUpdated = function(){
		$scope.filters.page = $scope.interviews.meta.pagination.current_page;
		$scope.updateInterviewLists();
	}
	$scope.getSortByClass = function(fieldName){
		if($scope.filters.sortBy==fieldName){
			return 'fa-sort-'+$scope.filters.sort;
		}
		return 'fa-sort text-muted';
	}
})
