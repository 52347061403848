angular.module('hrmnext.recruitment.manage.dashboard', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.dashboard', {
			url: '/dashboard',
			templateUrl: 'app/manage/dashboard/dashboard.html',
			controller: 'DashboardCtrl',
			access: {
				requiresLogin: true,
				requirePermissions: ['view_dashboard'],
				permissionType: 'atLeastOne'
			},
			resolve: {}
		})
	}])
	.controller('DashboardCtrl', ['$scope', 'Restangular', 'Notification', 'authorization', 'constants',
		function ($scope, Restangular, Notification, authorization, constants) {
			$scope.registeredUserChart = {};
			$scope.applicationProcessTimeChart = {};
			$scope.applicationsPerPositionChart = {};
			$scope.qualificationsPerPositionChart = {};
			$scope.feedbacksChart = {};

			if (authorization.authorize({ requirePermissions: ['view_feedback_analytics'] }) == constants.AUTHROIZED) {
				$scope.feedbacksChart = Restangular.all('analytics/feedbacks').getList().then(function (response) {
					var data = Restangular.stripRestangular(response);
					return {
						"type": "serial",
						"theme": "light",
						"marginRight": 40,
						"marginLeft": 40,
						"autoMarginOffset": 20,
						"dataDateFormat": "YYYY-MM-DD",
						"valueAxes": [{
							"id": "v1",
							"axisAlpha": 0,
							"position": "left",
							"ignoreAxisWidth": true
						}],
						"balloon": {
							"borderThickness": 1,
							"shadowAlpha": 0
						},
						"graphs": _.keysIn(data[0]).filter(function (key) {
							return key != 'submitted_on';
						}).map(function (key, index) {
							return {
								"id": "g" + index,
								"fillAlphas": 0.2,
								"bullet": "round",
								"bulletBorderAlpha": 1,
								"bulletColor": "#FFFFFF",
								"bulletSize": 5,
								"hideBulletsCount": 50,
								"lineThickness": 2,
								"title": "red line",
								"useLineColorForBulletBorder": true,
								"valueField": key,
								"balloonText": "<span style='font-size:11px;'>" + key + ": [[value]]</span>"
							};
						}),
						"chartCursor": {
							"valueLineEnabled": true,
							"valueLineBalloonEnabled": true,
							"cursorAlpha": 0,
							"zoomable": false,
							"valueZoomable": true,
							"valueLineAlpha": 0.5
						},
						"valueScrollbar": {
							"autoGridCount": true,
							"color": "#000000",
							"scrollbarHeight": 50
						},
						"categoryField": "submitted_on",
						"categoryAxis": {
							"parseDates": true,
							"dashLength": 1,
							"minorGridEnabled": true
						},
						"data": data
					};
				});
			}
			if (authorization.authorize({ requirePermissions: ['view_registered_user_analytics'] }) == constants.AUTHORIZED) {
				$scope.registeredUserChart = Restangular.all('analytics/registeredUsers').getList().then(function (data) {
					return {
						"type": "serial",
						"theme": "light",
						"marginRight": 40,
						"marginLeft": 40,
						"autoMarginOffset": 20,
						"dataDateFormat": "YYYY-MM-DD",
						"valueAxes": [{
							"id": "v1",
							"axisAlpha": 0,
							"position": "left",
							"ignoreAxisWidth": true
						}],
						"balloon": {
							"borderThickness": 1,
							"shadowAlpha": 0
						},
						"graphs": [{
							"id": "g1",
							"balloon": {
								"drop": true,
								"adjustBorderColor": false,
								"color": "#ffffff",
								"type": "smoothedLine"
							},
							"fillAlphas": 0.2,
							"bullet": "round",
							"bulletBorderAlpha": 1,
							"bulletColor": "#FFFFFF",
							"bulletSize": 5,
							"hideBulletsCount": 50,
							"lineThickness": 2,
							"title": "red line",
							"useLineColorForBulletBorder": true,
							"valueField": "registered_users",
							"balloonText": "<span style='font-size:18px;'>[[value]]</span>"
						}],

						"chartCursor": {
							"valueLineEnabled": true,
							"valueLineBalloonEnabled": true,
							"cursorAlpha": 0,
							"zoomable": false,
							"valueZoomable": true,
							"valueLineAlpha": 0.5
						},
						"valueScrollbar": {
							"autoGridCount": true,
							"color": "#000000",
							"scrollbarHeight": 50
						},
						"categoryField": "registered_on",
						"categoryAxis": {
							"parseDates": true,
							"dashLength": 1,
							"minorGridEnabled": true
						},
						"data": data
					};
				});
			}

			if (authorization.authorize({ requirePermissions: ['application_process_time_analytics'] }) == constants.AUTHORIZED) {
				$scope.applicationProcessTimeChart = Restangular.all('analytics/applicationProcessTime').getList().then(function (data) {
					return {
						"type": "serial",
						"theme": "light",
						"marginRight": 40,
						"marginLeft": 40,
						"autoMarginOffset": 20,
						"dataDateFormat": "YYYY-MM-DD",
						"valueAxes": [{
							"id": "v1",
							"axisAlpha": 0,
							"position": "left",
							"ignoreAxisWidth": true,
							"title": "Days",
						}],
						"balloon": {
							"borderThickness": 1,
							"shadowAlpha": 0
						},
						"graphs": [{
							"id": "g1",
							"type": "column",
							"bullet": "round",
							"bulletBorderAlpha": 1,
							"bulletColor": "#FFFFFF",
							"bulletSize": 5,
							"hideBulletsCount": 50,
							"lineThickness": 2,
							"title": "red line",
							"fillAlphas": 0.8,
							"lineAlpha": 0.2,
							"useLineColorForBulletBorder": true,
							"valueField": "daysToProcess",
							"balloonText": "<span style='font-size:18px;'>[[value]]</span>"
						}],
						"chartCursor": {
							"pan": true,
							"valueLineEnabled": true,
							"valueLineBalloonEnabled": true,
							"cursorAlpha": 1,
							"cursorColor": "#258cbb",
							"limitToGraph": "g1",
							"valueLineAlpha": 0.2
						},
						"valueScrollbar": {
							"oppositeAxis": false,
							"offset": 50,
							"scrollbarHeight": 10
						},
						"categoryField": "position",
						"categoryAxis": {
							"parseDates": false,
							"dashLength": 1,
							"minorGridEnabled": true,
							autoWrap: true,
						},
						"export": {
							"enabled": false,
							"menu": [{
								"class": "export-main",
								"label": "Export",
								"menu": [{
									"label": "Download as ...",
									"menu": ["PNG", "JPG", "SVG", {
										"format": "PDF",
										"content": ["Saved from:", window.location.href, {
											"image": "reference",
											"fit": [523.28, 769.89] // fit image to A4
										}]
									}]
								}, {
									"label": "Save data ...",
									"menu": ["CSV", "XLSX", "JSON"]
								}, {
									"label": "Annotate",
									"action": "draw"
								}, {
									"format": "PRINT",
									"label": "Print"
								}]
							}],
						},
						"data": data
					}
				});
			}

			if (authorization.authorize({ requirePermissions: ['application_per_position_analytics'] }) == constants.AUTHORIZED) {
				$scope.applicationsPerPositionChart = Restangular.all('analytics/applicationsPerPosition').getList().then(function (data) {
					return {
						"type": "serial",
						"theme": "light",
						"marginRight": 40,
						"marginLeft": 40,
						"autoMarginOffset": 20,
						"dataDateFormat": "YYYY-MM-DD",
						"valueAxes": [{
							"id": "v1",
							"axisAlpha": 0,
							"position": "left",
							"ignoreAxisWidth": true,
							"title": "Applications",
						}],
						"balloon": {
							"borderThickness": 1,
							"shadowAlpha": 0
						},
						"graphs": [{
							"id": "g1",
							"type": "column",
							"bullet": "round",
							"bulletBorderAlpha": 1,
							"bulletColor": "#FFFFFF",
							"bulletSize": 5,
							"hideBulletsCount": 50,
							"lineThickness": 2,
							"title": "red line",
							"fillAlphas": 0.8,
							"lineAlpha": 0.2,
							"useLineColorForBulletBorder": true,
							"valueField": "applications",
							"balloonText": "<span style='font-size:18px;'>[[value]]</span>"
						}],
						"chartCursor": {
							"pan": true,
							"valueLineEnabled": true,
							"valueLineBalloonEnabled": true,
							"cursorAlpha": 1,
							"cursorColor": "#258cbb",
							"limitToGraph": "g1",
							"valueLineAlpha": 0.2
						},
						"valueScrollbar": {
							"oppositeAxis": false,
							"offset": 50,
							"scrollbarHeight": 10
						},
						"categoryField": "position",
						"categoryAxis": {
							"parseDates": false,
							"dashLength": 1,
							"minorGridEnabled": true,
							autoWrap: true,
						},
						"export": {
							"enabled": false,
							"menu": [{
								"class": "export-main",
								"label": "Export",
								"menu": [{
									"label": "Download as ...",
									"menu": ["PNG", "JPG", "SVG", {
										"format": "PDF",
										"content": ["Saved from:", window.location.href, {
											"image": "reference",
											"fit": [523.28, 769.89] // fit image to A4
										}]
									}]
								}, {
									"label": "Save data ...",
									"menu": ["CSV", "XLSX", "JSON"]
								}, {
									"label": "Annotate",
									"action": "draw"
								}, {
									"format": "PRINT",
									"label": "Print"
								}]
							}],
						},
						"data": data
					};
				});
			}

			if (authorization.authorize({ requirePermissions: ['qualifications_per_position_analytics'] }) == constants.AUTHORIZED) {

				$scope.qualificationsPerPositionChart = Restangular.all('analytics/qualificationsPerPosition').getList().then(function (data) {
					return {
						"theme": "light",
						"type": "serial",
						"data": data,
						"startDuration": 1,
						"graphs": [{
							"id": "g1",
							"balloonText": "[[title]] Qualifications: <b>[[value]]</b>",
							"fillAlphas": 0.9,
							"lineAlpha": 0.2,
							"title": "Excellent",
							"type": "column",
							"valueField": "excellent",
							fillColors: "#3cc374"
						}, {
							"id": "g2",
							"balloonText": "[[title]] Qualifications: <b>[[value]]</b>",
							"fillAlphas": 0.9,
							"lineAlpha": 0.2,
							"title": "Satisfactory",
							"type": "column",
							"valueField": "satisfactory",
							fillColors: "#F7E021"
						}, {
							"id": "g3",
							"balloonText": "[[title]] Qualifications: <b>[[value]]</b>",
							"fillAlphas": 0.9,
							"lineAlpha": 0.2,
							"title": "Poor",
							"type": "column",
							"valueField": "poor",
							fillColors: "#cf4830"
						}],
						"plotAreaFillAlphas": 0.1,
						"categoryField": "position",
						"categoryAxis": {
							"gridPosition": "start",
							"parseDates": false,
							"dashLength": 1,
							"minorGridEnabled": true,
							autoWrap: true,
						},
						"export": {
							"enabled": false,
							"menu": [{
								"class": "export-main",
								"label": "Export",
								"menu": [{
									"label": "Download as ...",
									"menu": ["PNG", "JPG", "SVG", {
										"format": "PDF",
										"content": ["Saved from:", window.location.href, {
											"image": "reference",
											"fit": [523.28, 769.89] // fit image to A4
										}]
									}]
								}, {
									"label": "Save data ...",
									"menu": ["CSV", "XLSX", "JSON"]
								}, {
									"label": "Annotate",
									"action": "draw"
								}, {
									"format": "PRINT",
									"label": "Print"
								}]
							}],
						}
					};
				});
			}
		}
	])
