angular.module('hrmnext.recruitment.manage.company.editMember', [])
    .controller('CompanyEditMemberCtrl', ['$scope', 'Restangular', '$uibModalInstance', 'Notification', 'member', 'roles', 'company',
        function ($scope, Restangular, $uibModalInstance, Notification, member, roles, company) {
            $scope.member = member;
            $scope.roles = roles;

            $scope.updateMemberRole = function () {

                $scope.member.save().then(function (member) {
                    Notification.success('Successfully updated the member\'s role.');
                    $uibModalInstance.close(member);
                    console.log("returned member", member);
                }, function (response) {
                    if (response.status == 403) {
                        Notification.error('You do not have permission to update a member with a rank higher than yours.');
                    } else if (response.status == 422) {
                        Notification.error('Enter all required fields.');
                    } else {
                        Notification.error('Failed to create new member.');
                    }
                });
            }

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            }
        }

    ])