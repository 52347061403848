angular.module('hrmnext.recruitment.manage.favorite-users.details', [
		'hrmnext.recruitment.app.services.dialogService',
		'hrmnext.recruitment.manage.favorite-users.unfavorite',
		'hrmnext.recruitment.user.services.user',
		'hrmnext.recruitment.manage.favorite-users.addToCompany',
	])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('manage.favorite_users.details', {
			url: '/:user_id',
			templateUrl: 'app/manage/favorite-users/favorite-users.details.html',
			controller: 'FavoriteUserDetailCtrl',
			access: {

			},
			resolve: {
				favoriteUser: function (Restangular, $stateParams) {
					return Restangular.one('favorite_users', $stateParams.user_id).get({
						include: 'user.addresses,user.phoneNumbers'
					});
				},
			},
		})
	}])
	.controller('FavoriteUserDetailCtrl', ['$scope', 'Restangular', 'Notification', 'favoriteUser', '$state', 'DialogService', 'userService', '$uibModal', function ($scope, Restangular, Notification, favoriteUser, user, $state, DialogService, userService, $uibModal) {
		$scope.user = favoriteUser.user.data;
		$scope.favoriteUser = favoriteUser;
		$scope.companies = {};
		var companyIds = [];

		$scope.init = function () {
			$scope.companies = {};
			companyIds = [];

			$scope.favoriteUser.favorited_by.forEach(function (favoritedBy) {
				companyIds.push(favoritedBy.company_id);
			});
			Restangular.all('companies').getList({
				'ids[]': _.uniq(companyIds)
			}).then(function (companies) {
				companies.forEach(function (company) {
					$scope.companies[company.id] = company;
				});
			});
		}

		$scope.previewUser = function (favoriteUser) {
			userService.preview(favoriteUser.user_id);
		}

		$scope.unfavorite = function (favoriteUser) {
			var modalInstance = $uibModal.open({
				templateUrl: 'app/manage/favorite-users/components/unfavorite.html',
				controller: 'UnfavoriteCtrl',
				size: 'md',
				resolve: {
					user: function () {
						return $scope.user;
					},
					companies: function () {
						return companyIds.map(function (companyId) {
							return $scope.companies[companyId];
						});
					},
				}
			}).result.then(function (response) {
				Restangular.one('favorite_users', user.id).getList().then(function (favoriteUsers) {
					$scope.favoriteUser = favoriteUsers;
					$scope.init();
				}, function (response) {
					if (response.status == 404) {
						$state.go('manage.favorite_users.index');
					}
				});
			});
		}
		$scope.unfavoriteFromCompany = function (favoriteBy) {
			DialogService.confirm('Are you sure you want to remove this user from your company\'s favourite user list?', 'Unfavourite User').then(function () {
				var indexOf = favoriteUser.favorited_by.findIndex(function (favoritedBy) {
					return favoritedBy.id == favoriteBy.id;
				});

				Restangular.one('favorite_users', favoriteBy.id).remove().then(function () {

					if ($scope.favoriteUser.favorited_by.length == 1) {
						$state.go('manage.favorite_users.index');
					} else {
						$scope.favoriteUser.favorited_by.splice(indexOf, 1);
					}
					Notification.success('Successfully unfavourited the user');
				}, function (response) {
					Notification.error('Failed to unfavourite user');
				});
			});
		}

		$scope.addCompany = function () {
			$uibModal.open({
				templateUrl: 'app/manage/favorite-users/components/favorite-users.addToCompany.html',
				controller: 'AddFavoriteUserToCompanyCtrl',
				size: 'md',
				resolve: {
					user: function () {
						return $scope.user;
					},
					companies: function () {
						//get all the companies the user is a member of
						return Restangular.all('companies').getList();
					},
				}
			}).result.then(function (favoriteUser) {
				$scope.favoriteUser = favoriteUser;
				$scope.init();
			})
		}
		$scope.init();
	}])