angular.module('hrmnext.recruitment.manage.position').service('positionService', [
'Restangular', 
'$rootScope',
'$q',
'DialogService',
'Notification',
function(Restangular,$rootScope,$q,DialogService,Notification){
	this.update = function(position){
		var deferred = $q.defer;
		var promise = Restangular.one('positions',position.id).patch(position);
		
		promise.then(function(response){
			Notification.success('The position was successfully updated');
			deferred.resolve(response);
		},function(response){
			Notification.success('Failed to update position');
			deferred.reject(response);
		});
		
		return deferred.promise;
	}
	this.create = function(position){
		var deferred = $q.defer;
		var promise  = Restangular.all('positions').post(position);		
		promise.then(function(response){
			Notification.success('The position was successfully created');
			deferred.resolve(response);
		},function(response){
			Notification.success('Failed to create position');
			deferred.reject(response);
		});
		
		return deferred.promise;
	}
	this.delete = function(position){
		var deferred = $q.defer;
		
		var modalInstance = DialogService.confirm("Are you sure you want to remove this position?","Delete Position");
		modalInstance.then(function(){
			//if the user selected yes 
			Restangular.one('positions',position.id).remove()
			.then(function(response){
				Notification.success('The position was successfully deleted');
				deferred.resolve(response);
			},function(response){
				Notification.error('Failed to remove position');
				deferred.reject(response);
			});
		});
		return deferred.promise;
	}

	this.removeSkill = function(position, skill){
		if(skill.id>0){
			skill.deleted = true;
		}else{
			position.skills.splice(position.skills.indexOf(skill), 1);	
		}		
	}
		
	this.addSkill = function(position){
		position.skills.push({
			name : '',
			proficiency_level : 0,
			years_of_experience : 0,
			description : '',
		});
	}
	this.addExperience = function(position){
		position.experiences.push({
			name : '',
			description : '',
			id : null,
			years_of_service : 0,
		});
	}
	this.removeExperience = function(position, experience){
		if(experience.id>0){
			experience.deleted = true;
		} else{
			position.experiences.splice(position.experiences.indexOf(experience), 1);				
		}
	}
	this.addEducation = function(position){
		position.educations.push({
			name : '',
			awards : [],
			description: '',
		});
	}
	this.removeEducation = function(position, education){
		if(education.id>0){
			education.deleted = true;
		}else{
			position.educations.splice(position.educations.indexOf(education), 1);
		}
	}
}]);