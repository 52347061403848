angular.module('hrmnext.recruitment.profile')
.controller('ProfileApplicationDetailCtrl', [
'$scope',
'$uibModalInstance',
'application',
function($scope,$uibModalInstance,application){
	$scope.application = application;

	$scope.cancel = function(){
		$uibModalInstance.dismiss();
	}
}]);