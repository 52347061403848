angular.module('hrmnext.recruitment.services')
.service('auth',['$http','store','jwtHelper','$q','$state',function($http,store,jwtHelper,$q,$state){
	this.getToken = function(){
		return store.get('jwt');
	};
	this.setToken = function(jwt){
		if(angular.isDefined(jwt) && jwt!=''){
			store.set('jwt',jwt);
		}				
	};
	this.logout = function(){
		//TODO: send a request to the server to invalidate the token
		$http({'url': 'api/auth/logout'}).then(function(response){
			store.remove('jwt');
			$state.go('login',{},{ reload:true, notify:true});			
		},function(response){
			store.remove('jwt');
			$state.go('login',{},{ reload:true, notify:true});
		});
	};
	this.isSessionExpired = function(){
		if(!isTokenDefined()){
			return jwtHelper.isTokenExpired(this.getToken());
		}
		return true;
	};
	this.refreshToken = function(jwt){
		var deferred = $q.defer();	
		$http({
			url : 'api/auth/token',
			method: 'GET',
			headers : {
				Authorization : 'Bearer' + jwt
			}
		}).then(function(response){
			//refresh the token
			this.setToken(response.data.token);
			deferred.resolve(response);
		},function(response){
			deferred.reject(response);
		});
		return deferred.promise;
	};
	this.getUser = function(){
		var token = this.getToken();
		if(token){
			return jwtHelper.decodeToken(token);
		}
		return undefined;
	};
	this.isTokenDefined = function(){
		var token = this.getToken();
		return token!==undefined && token!='';
	}

	this.hasPermission = function(permission){
		var user = this.getUser();
		var permissions =user ? user.permissions : [];
		
		return _.contains(permissions, permission)
		
	}
}]);