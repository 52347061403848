angular.module('hrmnext.recruitment.manage.audit.show', [])
.config(function($stateProvider){
    $stateProvider.state('manage.showAudit',{
        url: '/audits/:id',
        templateUrl: 'app/manage/audit/show.audit.html',
        controller: 'ShowAuditController',
        access : {
			requireRoles : ['admin']
		},
        resolve : {
            audit : function(Restangular,$stateParams){
                return Restangular.one('audits',$stateParams.id).get({include : 'user'});
            }
        },
    })
})
.controller('ShowAuditController', function($scope, Restangular, audit){
    $scope.audit = audit;
});
