/**
* hrmnext.recruitment.manage.notifications Module
*
* Description
*/
angular.module('hrmnext.recruitment.manage.notifications', [
	'hrmnext.recruitment.manage.notifications.index',
	'hrmnext.recruitment.manage.notifications.show'
])
.config(['$stateProvider',function($stateProvider) {
	$stateProvider.state('manage.notifications',{
		url : '/notifications',
		template: '<ui-view></ui-view>',
		abstract: true,
	});
}]);