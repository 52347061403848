angular.module('hrmnext.recruitment.invitation', [])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('invitation', {
			url: '/invitations/:code?accept',
			templateUrl: 'app/invitation/invitation.html',
			controller: 'InvitationCtrl',
			resolve: {
				invitation: function (Restangular, $stateParams) {
					return Restangular.one('invitations', $stateParams.code).get();
				},
				invitationStatus: function (Restangular) {
					return Restangular.one('invitation_status').get()
				}
			}

		})
	}])
	.controller('InvitationCtrl', ['$scope', 'invitation', '$state', 'Notification', 'Restangular', '$stateParams', 'invitationStatus',
		function ($scope, invitation, $state, Notification, Restangular, $stateParams, invitationStatus) {
			$scope.invitation = invitation;
			$scope.userInvitation = false;
			$scope.processing = false;

			if ($scope.invitation.status != 'Waiting') {
				$scope.processing = true;
				Notification.error('This invitation was already used');
				$state.go('profile.invitations');
			}
			else if ($scope.invitation.status == 'Waiting' && ($scope.invitation.purpose != 'user' || JSON.parse($stateParams.accept) == false)) {
				$scope.processing = true;

				$scope.invitation.invitation_status_id = invitationStatus.find(function (status) {
					if (JSON.parse($stateParams.accept)) {
						return status.slug == 'accepted';
					}

					return status.slug == 'rejected';
				}).id;

				Restangular.one('invitations', $stateParams.code).customPUT($scope.invitation).then(function (response) {

					$state.go('profile.invitations');
					Notification.success('You have successfully ' + (JSON.parse($stateParams.accept) ? 'accepted' : 'rejected') + ' the invitation');
				}, function (response) {
					$state.go('profile.invitations');
					Notification.error('An error has occurred while trying to ' + (JSON.parse($stateParams.accept) ? 'accepted' : 'rejected') + ' the invitation');
				});
			} else if ($scope.invitation.status == 'Waiting' && $scope.invitation.purpose == 'user') {
				$scope.user = invitation.user;
				$scope.userInvitation = true;
			}


			$scope.submit = function () {
				$scope.invitation.invitation_status_id = invitationStatus.find(function (status) {
					if (JSON.parse($stateParams.accept)) {
						return status.slug == 'accepted';
					}

					return status.slug == 'rejected';
				}).id;

				Restangular.one('invitations', $stateParams.code).customPOST($scope.invitation).then(function (response) {
					$state.go('profile.invitations');
					Notification.success('You have successfully accept the invitation');
				}, function (response) {
					Notification.error('An error has occurred while trying to accept the invitation');
				});
			}
		}])
